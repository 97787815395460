import { act } from "react";
import { Constants } from "../constant/constants";

const initialState = {
  isViewerOpen: false,
  defaultView: {},
  defaultAppearanceProfile: [],
};
export function ViewerReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.HANDLE_VIEWER: {
      console.log("reducer", state.isViewerOpen);
      return { ...state, isViewerOpen: action.payload };
    }
    case Constants.SET_DEFAULTVIEW: {
      console.log("actionPayload", action.payload);
      return {
        ...state,
        defaultView: action.payload.bcf,
        defaultAppearanceProfile: action.payload.appearanceProfile,
      };
    }
    default:
      return state;
  }
}
