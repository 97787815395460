import { act } from "react";
import { Constants } from "../constant/constants";

const initialState = {
  isAppearanceProfileOpen: false,
  appearenceProfileList: [],
  appearanceProfileListStatus: false,
};
export function AppearenceProfileReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.HANDLE_APPEARANCEPROFILE: {
      console.log("????", action.payload);
      return {
        ...state,
        isAppearanceProfileOpen: action.payload,
      };
    }
    case Constants.SAVE_APPEARENCEPROFILE_LIST: {
      let flag;
      if (state.appearanceProfileListStatus) {
        flag = false;
      } else flag = true;
      console.log("reducer", action.payload);
      return {
        ...state,
        appearenceProfileList: action.payload,
        appearanceProfileListStatus: flag,
      };
    }
    default:
      return state;
  }
}
