// src/XeokitViewer.js

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { stringify, parse } from "flatted";
import testModel from "../../models/supermarket.xkt";
import attribute from "../../models/duplex.json";
import duplex from "../../models/duplex.xkt";
import glbTest from "../../models/simple-bim-house.xkt";
// import Revizto from "../../models/Revizto.xkt";
// import testModel from "../../models/test.xkt";
// import test2Model from "../models/model.xkt.manifest.json";
// import MetaData from "../../models/MSC.json";

import { Button } from "../../components/Button/Button";
import { CustomToast } from "../../utlity/toastify";
import SkyBox_IMG from "../../asset/cloudySkyBox.jpg";
import {
  XKTLoaderPlugin,
  Viewer,
  StoreyViewsPlugin,
  ContextMenu,
  Skybox,
  CameraMemento,
  math,
  FastNavPlugin,
  SectionPlanesPlugin,
  BCFViewpointsPlugin,
  AnnotationsPlugin,
  DistanceMeasurementsPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import SectionCut from "./SectionCut";
import SelectEntity from "./SelectEntity";
import Measure from "./Measure";

import "./XeokitViewer_style.css";

import getModelPropertyAction, {
  saveViewerAction,
  setClashDetectionTabStatusAction,
  handleAppearanceProfileAction,
  setAnnotationTabStatusAction,
  handleAppearanceTemplateAction,
  saveClashDetectionArrayAction,
  addToClashDetectionListFromView,
  seSelectEntityStatusAction,
  saveActionBufferAction,
  handlePropertyAction,
  setIssueTrackStatusAction,
  setTreeViewStatusAction,
  saveBCFViewPoint,
  saveAnnotationPlugin,
  saveDistanceMeasurementPluginAction,
  saveSectionPlanceCanvas,
  saveSectionPlanePluginAction,
  saveMetaData,
  setImagePlaneStatusAction,
  handleSearchAction,
  handleViewerAction,
  handleLoadingAction,
  handleMarkupUpdate,
  setSelectedEntities,
  setAnnotationsAction,
  searchAppearenceTemplateListAction,
} from "../../action/xeokitAction";

import {
  apiHeader,
  modelUrl,
  BaseURL,
  recordsLimit,
} from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const XeokitViewer = (props) => {
  const location = useLocation();
  const sectionPlaneCanvas = useRef(null);
  const [modelJSON, setModelJSON] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAnnotations = async (annotationPlugin, viewer) => {
    const url = BaseURL(
      `annotations/projects/${props.projectId}?page=${page}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response?.data) {
      props.setAnnotationsAction(response.data?.results);
      response?.data?.results?.map((annotation, i) => {
        annotationPlugin.createAnnotation({
          id: annotation.id,
          entity: viewer.scene.objects[annotation.entity],
          worldPos: [
            annotation.worldPos[0],
            annotation.worldPos[1],
            annotation.worldPos[2],
          ],
          values: annotation.values,
          occludable: true, // Optional, default is true
          markerShown: true, // Optional, default is true
          labelShown: false, // Optional, default is true
        });
      });
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   console.log("-------------------------------------------", props.metaData);
  //   getModels();
  // }, []);

  useEffect(() => {
    const viewer = new Viewer({
      canvasId: "xeokit_canvas",
      transparent: true,
      preserveDrawingBuffer: true,
      pbr: true, // Enable physically-based rendering for Viewer
      sao: true, // Enable ambient shadows for Viewer
      spinnerElementId: "mySpinnerElement",
    });

    // Object.assign(viewer, tempViewer);
    console.log("????viewer", viewer);
    const metrics = viewer.scene.metrics;
    metrics.units = "meters";
    metrics.scale = 0.3061224489;
    // viewer.scene.getAABB();

    // viewer.scene.render(true);

    // new Skybox(viewer.scene, {
    //   src: SkyBox_IMG,
    //   size: 1000,
    // });

    console.log("Entity", viewer.scene.entity);
    // const MetaData = props.metaData;
    console.log("MetaData", props.metaData);

    // viewer.camera.eye = MetaData.Camera.Position;
    // viewer.camera.look = MetaData.Camera.Target;
    // viewer.camera.up = MetaData.Camera.UpVector;
    viewer.camera.eye = [-2, 1, -6];
    viewer.camera.look = [8.4, 2.724, 20.899];
    viewer.camera.up = [1, 29, 4.039];
    // viewer.camera.perspective.fov = 60;

    viewer.cameraControl.followPointer = true;

    viewer.cameraControl.panRightClick = false; // Prevents right-click-drag panning interfering with ContextMenus

    viewer.scene.sao.enabled = true;
    viewer.scene.sao.numSamples = 60;
    viewer.scene.sao.kernelRadius = 170;

    // new FastNavPlugin(viewer, {
    //   hideEdges: true, // Don't show edges while we interact (default is true)
    //   hideSAO: true, // Don't show ambient shadows while we interact (default is true)
    //   hideColorTexture: true, // No color textures while we interact (default is true)
    //   hidePBR: true, // No physically-based rendering while we interact (default is true)
    //   hideTransparentObjects: true, // Hide transparent objects while we interact (default is false)
    //   // scaleCanvasResolution: true, // Scale canvas resolution while we interact (default is false)
    //   defaultScaleCanvasResolutionFactor: 1.0, // Factor by which we scale canvas resolution when we stop interacting (default is 1.0)
    //   scaleCanvasResolutionFactor: 0.5, // Factor by which we scale canvas resolution when we interact (default is 0.6)
    //   delayBeforeRestore: true, // When we stop interacting, delay before restoring normal render (default is true)
    //   delayBeforeRestoreSeconds: 0.5, // The delay duration, in seconds (default is 0.5)
    // });

    //------------------------------------------------------------------------------------------------------------------
    // Customize CameraControl
    //------------------------------------------------------------------------------------------------------------------

    const cameraControl = viewer.cameraControl;

    cameraControl.navMode = "orbit";
    cameraControl.followPointer = true;
    cameraControl.smartPivot = true;

    const pivotElement = document
      .createRange()
      .createContextualFragment(
        "<div class='xeokit-camera-pivot-marker'></div>"
      ).firstChild;
    document.body.appendChild(pivotElement);
    cameraControl.pivotElement = pivotElement;

    // cameraControl.on("picked", (e) => {
    //   // console.log("picked");
    // });

    // cameraControl.on("doublePicked", (e) => {
    //   // console.log("doublePicked");
    // });

    // const cameraMemento = new CameraMemento();
    // cameraMemento.saveCamera(viewer.scene);

    // const temp = [];
    // temp.push({
    //   actionName: "camera",
    //   actionValue: cameraMemento,
    // });
    // props.saveActionBufferAction(temp);

    const xktLoader = new XKTLoaderPlugin(viewer);

    // new NavCubePlugin(viewer, {
    //   canvasId: "myNavCubeCanvas",
    //   color: "lightblue",
    //   visible: true, // Initially visible (default)
    //   cameraFly: true, // Fly camera to each selected axis/diagonal
    //   cameraFitFOV: 45, // How much field-of-view the scene takes once camera has fitted it to view
    //   cameraFlyDuration: 0.5, // How long (in seconds) camera takes to fly to each new axis/diagonal
    // });

    // // Create a grid ground plane
    // const geometryArrays = buildGridGeometry({
    //   size: 150,
    //   divisions: 15,
    // });

    // new LineSet(viewer.scene, {
    //   positions: geometryArrays.positions,
    //   indices: geometryArrays.indices,

    //   clippable: false,
    //   color: [255, 0, 0],
    // });

    // const storeyViewsPlugin = new StoreyViewsPlugin(viewer, {
    //   fitStoreyMaps: true,
    // });

    // console.log("modelURL", glbTest);
    // const model = xktLoader.load({
    //   id: "myModel",
    //   src: testModel,
    //   edges: true,
    // });
    const model = xktLoader.load({
      id: "myModel",
      src: modelUrl(props.model),
      edges: true,
    });

    const sectionPlanes = new SectionPlanesPlugin(viewer, {
      overviewCanvasID: sectionPlaneCanvas.current,
      overviewVisible: true,
    });
    props.saveSectionPlanePluginAction(sectionPlanes);
    props.saveSectionPlanceCanvas(sectionPlaneCanvas.current);
    console.log("modelURL", props.modelUrl);
    // const model = xktLoader.load({
    //   id: "myModel",
    //   xkt: models,
    //   edges: true,
    //   sao: true, // Enable ambient shadows for this model
    //   pbr: true, // Enable physically-based rendering for this m
    // });
    // const model = xktLoader.load({
    //   id: "myModel",
    //   xkt: props.model,
    //   edges: true,
    //   sao: true, // Enable ambient shadows for this model
    //   pbr: true, // Enable physically-based rendering for this m
    // });
    console.log("model", model);
    console.log("MetaData", props.metaData);

    const bcfViewpoints = new BCFViewpointsPlugin(viewer);
    props.saveBCFViewPoint(bcfViewpoints);

    const annotations = new AnnotationsPlugin(viewer, {
      container: document.getElementById("annotationsContainer"),
      markerHTML:
        "<div class='annotation-marker' style='background-color: {{markerBGColor}}; color: {{fontColor}}; border-color:{{borderColor}}; border-radius:{{borderRadius}}; width: {{width}}; height: {{height}} '>{{glyph}}</div>",
      // Default HTML template for label
      // labelHTML:
      //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
      //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",

      values: {
        markerBGColor: "white",
        labelBGColor: "red",
        glyph: "X",
        fontColor: "red",
        borderColor: "red",
        borderRadius: "10px",
        width: "45px",
        height: "25px",
      },
    });

    props.saveAnnotationPlugin(annotations);

    getAnnotations(annotations, viewer);
    annotations.on("markerClicked", (annotation) => {
      annotation.setLabelShown(!annotation.getLabelShown());
    });

    const distanceMeasurementsPlugin = new DistanceMeasurementsPlugin(viewer);
    props.saveDistanceMeasurementPluginAction(distanceMeasurementsPlugin);
    // skyboxesPlugin.createSkybox({
    //   id: "keyLight",
    //   pos: [0.1, 0.1, 0.1],
    //   dir: [0.8, -0.6, -0.8],
    //   // color: [1.0, 0.3, 0.3],
    //   // intensity: 1.0,
    //   // space: "world",
    // });

    // skyboxesPlugin.createLight({
    //   id: "fillLight",
    //   dir: [-0.8, -0.4, -0.4],
    //   color: [0.3, 1.0, 0.3],
    //   intensity: 1.0,
    //   space: "world",
    // });

    // skyboxesPlugin.createDirLight({
    //   id: "rimLight",
    //   dir: [0.2, -0.8, 0.8],
    //   color: [0.6, 0.6, 0.6],
    //   intensity: 1.0,
    //   space: "world",
    // });
    model.on("loaded", async function () {
      console.log("defaultView", props.defaultView);
      console.log("defaultView", props.defaultAppearanceProfile);
      if (Object.keys(props.defaultView).length === 0) {
        viewer.cameraFlight.flyTo({
          aabb: viewer.scene.getAABB(),
        });
      } else {
        const defaultView = JSON.parse(props.defaultView.viewPoints);
        bcfViewpoints.setViewpoint(defaultView);
        props.searchAppearenceTemplateListAction(
          props.defaultAppearanceProfile
        );
      }
      props.handleViewerAction(false);
      updateCameraPivot();
      // viewer.cameraFlight.jumpTo(model);
      // // Make all doors transparent
      // viewer.scene.setObjectsOpacity(
      //   viewer.metaScene.getObjectIDsByType("IfcDoor"),
      //   0.3
      // );
      // buildStoreyMapsMenu();
    });
    function updateCameraPivot() {
      // Get the Axis-Aligned Bounding Box (AABB) of the model
      const aabb = model.aabb;

      // Step 2: Calculate the center of the model using its AABB
      const centerX = (aabb[0] + aabb[3]) / 2;
      const centerY = (aabb[1] + aabb[4]) / 2;
      const centerZ = (aabb[2] + aabb[5]) / 2;

      // Step 3: Update the camera's pivot point to the new center of the model
      cameraControl.pivotPos = [centerX, centerY, centerZ];

      // Optionally adjust the camera's position relative to the new pivot
      const distance = 10; // Adjust this to your scene's scale
      cameraControl.eye = [
        centerX + distance,
        centerY + distance,
        centerZ + distance,
      ];

      // Ensure the camera is looking at the pivot (model's center)
      cameraControl.look = [centerX, centerY, centerZ];
    }
    // function buildStoreyMapsMenu() {
    //   const cameraMemento = new CameraMemento(); // Saves 3D perspective camera to restore
    //   cameraMemento.saveCamera(viewer.scene);

    //   const storeyDiv = document.getElementById("storeys");
    //   const storeyIds = Object.keys(storeyViewsPlugin.storeys);
    //   console.log("HERE");

    //   console.log("StoryIds", storeyIds);
    //   for (var i = 0, len = storeyIds.length; i < len; i++) {
    //     const storeyId = storeyIds[i];

    //     const storeyMap = storeyViewsPlugin.createStoreyMap(storeyId, {
    //       format: "png",
    //       width: 400,
    //     });

    //     console.log("storeyMap", storeyMap);
    //     const img = document.createElement("img");
    //     img.src = storeyMap.imageData;
    //     img.style.border = "1px solid #000000";
    //     img.style.background = "lightblue";
    //     img.style.width = storeyMap.width + "px";
    //     img.style.height = storeyMap.height + "px";
    //     img.style.opacity = 0.8;

    //     storeyDiv.appendChild(img);

    //     console.log("storeyDiv, storeyDiv");
    //     img.onmouseenter = () => {
    //       img.style.cursor = "default";
    //     };

    //     img.onmousemove = (e) => {
    //       img.style.cursor = "default";
    //       const imagePos = [e.offsetX, e.offsetY];
    //       const pickResult = storeyViewsPlugin.pickStoreyMap(
    //         storeyMap,
    //         imagePos,
    //         {}
    //       );
    //       if (pickResult) {
    //         const entity = pickResult.entity;
    //         const metaObject = viewer.metaScene.metaObjects[entity.id];
    //         if (metaObject) {
    //           img.style.cursor = "pointer";
    //         }
    //       }
    //     };

    //     img.onmouseleave = (e) => {
    //       img.style.cursor = "default";
    //     };

    //     const worldPos = math.vec3();

    //     img.onclick = (e) => {
    //       const imagePos = [e.offsetX, e.offsetY];

    //       const pickResult = storeyViewsPlugin.pickStoreyMap(
    //         storeyMap,
    //         imagePos,
    //         {
    //           pickSurface: true,
    //         }
    //       );

    //       if (pickResult) {
    //         worldPos.set(pickResult.worldPos);

    //         // Set camera vertical position at the mid point of the storey's vertical
    //         // extents - note how this is adapts to whichever of the X, Y or Z axis is
    //         // designated the World's "up" axis

    //         const camera = viewer.scene.camera;
    //         const idx = camera.xUp ? 0 : camera.yUp ? 1 : 2; // Find the right axis for "up"
    //         const storey = storeyViewsPlugin.storeys[storeyMap.storeyId];
    //         worldPos[idx] = (storey.aabb[idx] + storey.aabb[3 + idx]) / 2;

    //         viewer.cameraFlight.flyTo(
    //           {
    //             eye: worldPos,
    //             up: viewer.camera.worldUp,
    //             look: math.addVec3(worldPos, viewer.camera.worldForward, []),
    //             projection: "perspective",
    //             duration: 1.5,
    //           },
    //           () => {
    //             viewer.cameraControl.navMode = "firstPerson";
    //           }
    //         );
    //       }
    //     };
    //   }
    // }
    const canvasContextMenu = new ContextMenu({
      enabled: true,
      context: {
        viewer: viewer,
      },
      items: [
        [
          {
            title: "Hide All",
            getEnabled: function (context) {
              return context.viewer.scene.numVisibleObjects > 0;
            },
            doAction: function (context) {
              context.viewer.scene.setObjectsVisible(
                context.viewer.scene.visibleObjectIds,
                false
              );
            },
          },
          {
            title: "Show All",
            getEnabled: function (context) {
              const scene = context.viewer.scene;
              return scene.numVisibleObjects < scene.numObjects;
            },
            doAction: function (context) {
              const scene = context.viewer.scene;
              scene.setObjectsVisible(scene.objectIds, true);
              scene.setObjectsXRayed(scene.xrayedObjectIds, false);
              scene.setObjectsSelected(scene.selectedObjectIds, false);
            },
          },
        ],
        [
          {
            title: "View Fit All",
            doAction: function (context) {
              context.viewer.cameraFlight.flyTo({
                aabb: context.viewer.scene.getAABB(),
              });
            },
          },
        ],
      ],
    });

    const objectContextMenu = new ContextMenu({
      items: [
        [
          {
            title: "Property",
            doAction: function (context) {
              // const nodeId = context.entity.id;

              // const MetaData = props.metaData;
              // console.log("metaData", props);
              // console.log("metaData", MetaData);
              // const properties = {};
              // const idMatch = nodeId.match(/\[([\d-]+)\]/);
              // const propertyId = idMatch ? idMatch[1] : null;

              // console.log("property", propertyId);
              // console.log("property", MetaData.Properties[propertyId]);
              // if (propertyId && MetaData.Properties[propertyId]) {
              //   const propertyData = MetaData.Properties[propertyId];

              //   // console.log("PropertYData", propertyData);
              //   Object.entries(propertyData).forEach(([key, value]) => {
              //     if (MetaData.Legend[key]) {
              //       const legendKey = MetaData.Legend[key].Name;
              //       properties[legendKey] = value;
              //     }
              //   });
              // }
              // console.log("Propeerty", properties);
              props.handlePropertyAction(!props.isPropertyOpen);
              // props.getModelPropertyAction(properties);
              // props.getModelPropertyAction({
              //   properties: properties,
              //   isCtrlPressed: false,
              // });
            },
          },
          {
            title: "Tree View",
            doAction: function (context) {
              props.setTreeViewStatusAction(true);
            },
          },
          {
            title: "Search Sets",
            doAction: function (context) {
              props.handleSearchAction(true);
            },
          },
          {
            title: "Clash Detection",
            doAction: function (context) {
              props.setClashDetectionTabStatusAction(true);
            },
          },
          {
            title: "Appearence Profile",
            doAction: function (context) {
              props.handleAppearanceProfileAction(true);
            },
          },
          {
            title: "Annotation",
            doAction: function (context) {
              props.setAnnotationTabStatusAction(true);
              props.seSelectEntityStatusAction(false);
            },
          },
          {
            title: "Appearence Template",
            doAction: function (context) {
              props.handleAppearanceTemplateAction(true);
            },
          },
          {
            title: "2D Overlay",
            doAction: function (context) {
              props.setImagePlaneStatusAction(true);
            },
          },
          {
            title: "Issue Tracker",
            doAction: function (context) {
              props.setIssueTrackStatusAction(true);
            },
          },
          {
            title: "Save Points",
            doAction: function (context) {
              props.handleViewerAction(true);
            },
          },
          {
            title: "View Fit",
            doAction: function (context) {
              const viewer = context.viewer;
              const scene = viewer.scene;
              const selectedIds = viewer.scene.selectedObjectIds;
              let minX = Infinity,
                minY = Infinity,
                minZ = Infinity;
              let maxX = -Infinity,
                maxY = -Infinity,
                maxZ = -Infinity;
              selectedIds.map((id) => {
                const entity = scene.objects[id];
                const aabb = entity.aabb;
                // Update the minimum values
                minX = Math.min(minX, aabb[0]);
                minY = Math.min(minY, aabb[1]);
                minZ = Math.min(minZ, aabb[2]);

                // Update the maximum values
                maxX = Math.max(maxX, aabb[3]);
                maxY = Math.max(maxY, aabb[4]);
                maxZ = Math.max(maxZ, aabb[5]);
              });
              viewer.cameraFlight.flyTo(
                {
                  aabb: [minX, minY, minZ, maxX, maxY, maxZ],
                  duration: 0.5,
                },
                () => {
                  setTimeout(function () {
                    scene.setObjectsHighlighted(
                      scene.highlightedObjectIds,
                      false
                    );
                  }, 500);
                }
              );
            },
          },
          {
            title: "View Fit All",
            doAction: function (context) {
              const scene = context.viewer.scene;
              context.viewer.cameraFlight.flyTo({
                projection: "perspective",
                aabb: scene.getAABB(),
                duration: 0.5,
              });
            },
          },
          // {
          //   title: "Show in Tree",
          //   doAction: function (context) {
          //     const objectId = context.entity.id;
          //     context.treeViewPlugin.showNode(objectId);
          //   },
          // },
        ],
        [
          {
            title: "Hide",
            getEnabled: function (context) {
              return context.entity.visible;
            },
            doAction: function (context) {
              context.entity.visible = false;
            },
          },
          {
            title: "Hide Others",
            doAction: function (context) {
              const viewer = context.viewer;
              const scene = viewer.scene;
              const entity = context.entity;
              const metaObject = viewer.metaScene.metaObjects[entity.id];
              if (!metaObject) {
                return;
              }
              scene.setObjectsVisible(scene.visibleObjectIds, false);
              scene.setObjectsXRayed(scene.xrayedObjectIds, false);
              scene.setObjectsSelected(scene.selectedObjectIds, false);
              scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
              metaObject.withMetaObjectsInSubtree((metaObject) => {
                const entity = scene.objects[metaObject.id];
                if (entity) {
                  entity.visible = true;
                }
              });
            },
          },
          {
            title: "Hide All",
            getEnabled: function (context) {
              return context.viewer.scene.numVisibleObjects > 0;
            },
            doAction: function (context) {
              context.viewer.scene.setObjectsVisible(
                context.viewer.scene.visibleObjectIds,
                false
              );
            },
          },
          {
            title: "Show All",
            getEnabled: function (context) {
              const scene = context.viewer.scene;
              return scene.numVisibleObjects < scene.numObjects;
            },
            doAction: function (context) {
              const scene = context.viewer.scene;
              scene.setObjectsVisible(scene.objectIds, true);
            },
          },
        ],
        [
          {
            title: "X-Ray",
            getEnabled: function (context) {
              return !context.entity.xrayed;
            },
            doAction: function (context) {
              const viewer = context.viewer;
              const scene = viewer.scene;
              const selectedIds = viewer.scene.selectedObjectIds;
              selectedIds.map((id) => {
                const entity = scene.objects[id];
                entity.xrayed = true;
                entity.selected = false;
              });
            },
          },
          {
            title: "Undo X-Ray",
            getEnabled: function (context) {
              return context.entity.xrayed;
            },
            doAction: function (context) {
              const viewer = context.viewer;
              const scene = viewer.scene;
              const selectedIds = viewer.scene.selectedObjectIds;
              selectedIds.map((id) => {
                const entity = scene.objects[id];
                entity.xrayed = false;
                entity.selected = false;
              });
            },
          },
          {
            title: "X-Ray Others",
            doAction: function (context) {
              const viewer = context.viewer;
              const scene = viewer.scene;
              const entity = context.entity;
              const metaObject = viewer.metaScene.metaObjects[entity.id];
              console.log("metaObject", metaObject);
              const selectedIds = viewer.scene.selectedObjectIds;
              console.log("objects", selectedIds);
              if (!metaObject) {
                return;
              }
              scene.setObjectsVisible(scene.objectIds, true);
              scene.setObjectsXRayed(scene.objectIds, true);
              scene.setObjectsSelected(scene.selectedObjectIds, false);
              scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
              selectedIds.map((id) => {
                const entity = scene.objects[id];
                entity.xrayed = false;
              });
              // metaObject.withMetaObjectsInSubtree((metaObject) => {
              //   console.log("metaObjectTree", metaObject);
              //   const entity = scene.objects[metaObject.id];
              //   if (entity) {
              //     entity.xrayed = false;
              //   }
              // });
            },
          },
          {
            title: "Reset X-Ray",
            getEnabled: function (context) {
              return context.viewer.scene.numXRayedObjects > 0;
            },
            doAction: function (context) {
              context.viewer.scene.setObjectsXRayed(
                context.viewer.scene.xrayedObjectIds,
                false
              );
            },
          },
        ],
        [
          {
            title: "Select",
            getEnabled: function (context) {
              return !context.entity.selected;
            },
            doAction: function (context) {
              props.addToClashDetectionListFromView(context.entity.id);
              // const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
              console.log("id", context.entity.id.match(/\[([\d-]+)\]/)?.[1]);
              props.setSelectedEntities({
                keyPath: context.entity.id.match(/\[([\d-]+)\]/)?.[1],
                isChecked: true,
              });
              context.entity.selected = true;
            },
          },
          {
            title: "Undo select",
            getEnabled: function (context) {
              return context.entity.selected;
            },
            doAction: function (context) {
              props.addToClashDetectionListFromView(context.entity.id);
              props.setSelectedEntities({
                keyPath: context.entity.id.match(/\[([\d-]+)\]/)?.[1],
                isChecked: false,
              });
              context.entity.selected = false;
            },
          },
          {
            title: "Clear Selection",
            getEnabled: function (context) {
              return context.viewer.scene.numSelectedObjects > 0;
            },
            doAction: function (context) {
              console.log("context", context);
              context.viewer.scene.setObjectsSelected(
                context.viewer.scene.selectedObjectIds,
                false
              );
            },
          },
        ],
      ],
      enabled: true,
    });

    const getCanvasPosFromEvent = function (event) {
      const canvasPos = [];
      if (!event) {
        event = window.event;
        canvasPos[0] = event.x;
        canvasPos[1] = event.y;
      } else {
        let element = event.target;
        let totalOffsetLeft = 0;
        let totalOffsetTop = 0;
        let totalScrollX = 0;
        let totalScrollY = 0;
        while (element.offsetParent) {
          totalOffsetLeft += element.offsetLeft;
          totalOffsetTop += element.offsetTop;
          totalScrollX += element.scrollLeft;
          totalScrollY += element.scrollTop;
          element = element.offsetParent;
        }
        canvasPos[0] = event.pageX + totalScrollX - totalOffsetLeft;
        canvasPos[1] = event.pageY + totalScrollY - totalOffsetTop;
      }
      return canvasPos;
    };

    viewer.scene.canvas.canvas.addEventListener("contextmenu", (event) => {
      const canvasPos = getCanvasPosFromEvent(event);
      const hit = viewer.scene.pick({
        canvasPos,
      });

      if (hit && hit.entity.isObject) {
        objectContextMenu.context = {
          // Must set context before showing menu
          viewer,
          entity: hit.entity,
        };
        objectContextMenu.show(event.pageX, event.pageY);
      } else {
        canvasContextMenu.context = {
          // Must set context before showing menu
          viewer,
        };
        canvasContextMenu.show(event.pageX, event.pageY);
      }
      event.preventDefault();
    });

    props.saveViewerAction(viewer);
    console.log("INPUT", viewer);

    props.seSelectEntityStatusAction(true);
  }, []);

  const getModelPropertiesFromJson = (compareValue1, compareValue2) => {
    if (!props.viewer || !props.metaData) return;

    const metaData = props.metaData;

    const keyNumber = Object.entries(metaData.Legend).find(
      ([, value]) => value.Name === compareValue1
    )?.[0];

    if (!keyNumber) return;

    const resultObject = Object.entries(metaData.Properties)
      .filter(([, items]) => items[keyNumber] === compareValue2)
      .map(([property]) => property);

    return resultObject;
  };

  useEffect(() => {
    if (!props.viewer) return;
    const { searchEntityObject } = props;
    console.log("searchEntityObject", searchEntityObject);
    props.viewer.scene.setObjectsSelected(
      props.viewer.scene.selectedObjectIds,
      false
    );
    searchEntityObject.map((item, index) => {
      console.log("item", item);
      const resultObject = getModelPropertiesFromJson(item.name, item.value);

      console.log("result", resultObject);
      if (!resultObject) return;
      const { objects } = props.viewer.scene;

      Object.entries(objects).forEach(([key, object]) => {
        const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
        if (resultObject.includes(idMatch)) {
          object.selected = true;
        }
      });
    });
  }, [props.isSearchEntityActive]);

  useEffect(() => {
    if (!props.viewer) return;
    const applyTemplate = (resultObject, item) => {
      const { objects } = props.viewer.scene;

      Object.entries(objects).forEach(([key, object]) => {
        const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
        if (resultObject.includes(idMatch)) {
          if (item.color) object.colorize = item.color;
          // else object.selected = true;

          if (item.transparency) object.opacity = item.transparency;
          if (item.hideShow) {
            if (item.hideShow === "Hide") object.visible = false;
            if (item.hideShow === "Show") object.visible = true;
          }
        }
      });
    };

    console.log("Viewer", props.viewer);
    props.viewer.scene.setObjectsVisible(props.viewer.scene.objectIds, true);
    props.viewer.scene.setObjectsXRayed(
      props.viewer.scene.xrayedObjectIds,
      false
    );
    props.viewer.scene.setObjectsSelected(
      props.viewer.scene.selectedObjectIds,
      false
    );
    props.viewer.scene.setObjectsColorized(
      props.viewer.scene.colorizedObjectIds,
      false
    );
    props.viewer.scene.setObjectsOpacity(
      props.viewer.scene.opacityObjectIds,
      true
    );
    // props.viewer._plugins.forEach((plugin) => {
    //   if (plugin.id === "SectionPlanes") {
    //     plugin.clear();
    //   } else if (plugin.id === "Annotations") {
    //     plugin.clear();
    //   } else if (plugin.id === "DistanceMeasurements") {
    //     plugin.clear();
    //   }
    // });

    props.selectedAppearenceTemplateList?.template?.forEach((item) => {
      const resultObject = getModelPropertiesFromJson(
        item.property,
        item.value
      );
      if (!resultObject) return;
      applyTemplate(resultObject, item);
    });
  }, [props.isSearchAppearenceTemplateList]);

  useEffect(() => {
    if (!props.viewer) return;
    console.log("props.appearanceProfile", props.appearenceProfileList);
    props.appearenceProfileList.forEach((item) => {
      const resultObject = getModelPropertiesFromJson(
        item.property,
        item.value
      );

      if (!resultObject) return;
      const { objects } = props.viewer.scene;
      Object.entries(objects).forEach(([key, object]) => {
        const idMatch = key.match(/\[([\d-]+)\]/)?.[1];

        if (resultObject.includes(idMatch)) {
          if (item.color) object.colorize = item.color;
          if (item.transparency) object.opacity = item.transparency;
          if (item.hideShow) {
            if (item.hideShow === "Hide") object.visible = false;
            if (item.hideShow === "Show") object.visible = true;
          }
        }
      });
    });
  }, [props.appearanceProfileListStatus]);

  const getProperties = (propertyName, propertyValue) => {
    const returnVal = [];

    const resultObject = getModelPropertiesFromJson(
      propertyName,
      propertyValue
    );

    if (!resultObject) return;
    const { objects } = props.viewer.scene;

    Object.entries(objects).forEach(([key, object]) => {
      const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
      if (resultObject.includes(idMatch)) {
        returnVal.push(object);
      }
    });

    return returnVal;
  };

  function intersect(aabb1, aabb2) {
    return (
      aabb1[0] <= aabb2[3] &&
      aabb1[3] >= aabb2[0] && // Check X axis
      aabb1[1] <= aabb2[4] &&
      aabb1[4] >= aabb2[1] && // Check Y axis
      aabb1[2] <= aabb2[5] &&
      aabb1[5] >= aabb2[2]
    );
  }

  useEffect(() => {
    if (!props.searchSet1 || !props.viewer) return;

    const clashEntities1 = [];
    const clashEntities2 = [];
    const tempXray = [];

    console.log("SearchSet1-------------", props.searchSet1);
    console.log("SearchSet2-------------", props.searchSet2);

    const value1 = getProperties(props.searchSet1.name, props.searchSet1.value);
    const value2 = getProperties(props.searchSet2.name, props.searchSet2.value);

    value1.forEach((element1) => {
      value2.forEach((element2) => {
        if (intersect(element1.aabb, element2.aabb)) {
          element1.colorize = [1.0, 0.0, 0.0];
          element2.colorize = [0.0, 0.0, 1.0];
          clashEntities1.push(element1);
          clashEntities2.push(element2);
          tempXray.push(element1, element2);
        }
      });
    });

    if (clashEntities1.length === 0 && clashEntities2.length === 0) {
      alert("There is no collision");
    }

    const { scene } = props.viewer;
    scene.setObjectsVisible(scene.objectIds, false);

    tempXray.forEach((entity) => {
      entity.visible = true;
    });

    props.saveClashDetectionArrayAction({
      array1: clashEntities1,
      array2: clashEntities2,
    });
  }, [props.clashDetectionFlag]);

  const onClickUpdateMarkup = async () => {
    const screenshot = await props.viewer.getSnapshotWithPlugins({
      format: "png",
      width: window.innerWidth * 3, // Upscale snapshot resolution by 3x
      height: window.innerHeight * 3,
    });
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    console.log("updateMarkup", screenshot);
    // Convert the base64 image to a Blob
    const base64Data = screenshot.replace(/^data:image\/png;base64,/, ""); // Remove base64 header
    const blob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      {
        type: "image/png",
      }
    );

    const camera = {
      eye: props.viewer.camera.eye,
      look: props.viewer.camera.look,
      up: props.viewer.camera.up,
    };
    console.log("camera", camera);
    props.handleLoadingAction(true);
    const url = BaseURL("annotations/markup");

    let formData = new FormData();

    formData.append("markup", blob, `issue-${formattedDate}.png`);
    formData.append("camera", JSON.stringify(camera));
    formData.append("annotation", props.markupAnnotationId);

    console.log("formData", formData);
    const response = await Post(url, formData, apiHeader(props.token, true));
    if (response !== undefined) {
      console.log("markup response >>>>>>>>>>>>>>>>>", response);

      CustomToast("Updated Succeessfully!", "affirmative");
    }
    props.handleLoadingAction(false);
    props.handleMarkupUpdate(false);
    props.setIssueTrackStatusAction(true);
  };

  return (
    <div className="xeokitViewer">
      <canvas
        id="xeokit_canvas"
        style={{
          width: "100%",
          height: "100%",
          background: "lightblue",
          backgroundimage: "linear-gradient(lightblue, white)",
        }}
      ></canvas>
      {/* <div id="storeys"></div>
      <canvas id="myNavCubeCanvas"></canvas> */}
      <div id="mySpinnerElement">
        <div className="sk-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      <canvas
        ref={sectionPlaneCanvas}
        style={{
          position: "absolute",
          width: "250px",
          height: "250px",
          bottom: "70px",
          right: "10px",
          zindex: "200000",
        }}
      ></canvas>
      {props.isMarkupUpdateOpen && (
        <div className="update-markup-btn">
          <Button
            buttonName={"Update MarkUp"}
            onClick={() => onClickUpdateMarkup()}
          />
        </div>
      )}
      {/* <div id="storeys"></div> */}
      {/* <SectionCut /> */}
      <SelectEntity />
      {/* <Measure /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    defaultView: state.ViewerReducer.defaultView,
    defaultAppearanceProfile: state.ViewerReducer.defaultAppearanceProfile,
    searchEntityObject: state.xeokitReducer.searchEntityObject,
    isMarkupUpdateOpen: state.xeokitReducer.isMarkupUpdateOpen,
    markupAnnotationId: state.xeokitReducer.markupAnnotationId,
    isPropertyOpen: state.PropertyReducer.isPropertyOpen,
    isSearchEntityActive: state.xeokitReducer.isSearchEntityActive,
    searchSet1: state.ClashDetectionReducer.searchSet1,
    searchSet2: state.ClashDetectionReducer.searchSet2,
    clashDetectionFlag: state.ClashDetectionReducer.clashDetectionFlag,
    isSearchAppearenceTemplateList:
      state.AppearenceTemplateReducer.isSearchAppearenceTemplateList,
    appearenceTemplateList:
      state.AppearenceTemplateReducer.appearenceTemplateList,
    selectedAppearenceTemplateList:
      state.AppearenceTemplateReducer.selectedAppearenceTemplateList,
    // model: state.xeokitReducer.model,
    metaData: state.ProjectReducer.metaData,
    appearanceProfileListStatus:
      state.AppearenceProfileReducer.appearanceProfileListStatus,
    appearenceProfileList: state.AppearenceProfileReducer.appearenceProfileList,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    model: state.ProjectReducer.model,
    json: state.ProjectReducer.json,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  getModelPropertyAction,
  saveViewerAction,
  setClashDetectionTabStatusAction,
  handleAppearanceProfileAction,
  setAnnotationTabStatusAction,
  handleAppearanceTemplateAction,
  saveClashDetectionArrayAction,
  addToClashDetectionListFromView,
  seSelectEntityStatusAction,
  saveActionBufferAction,
  handlePropertyAction,
  setIssueTrackStatusAction,
  setTreeViewStatusAction,
  saveBCFViewPoint,
  saveAnnotationPlugin,
  saveDistanceMeasurementPluginAction,
  saveSectionPlanceCanvas,
  saveSectionPlanePluginAction,
  saveMetaData,
  setImagePlaneStatusAction,
  handleSearchAction,
  handleViewerAction,
  handleLoadingAction,
  handleMarkupUpdate,
  setSelectedEntities,
  setAnnotationsAction,
  searchAppearenceTemplateListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(XeokitViewer);
