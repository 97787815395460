import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import Header from "../container/Header/Header";
import Toolbar from "../container/ToolBar/Toolbar";
import MenuBar from "../container/MenuBar/MenuBar";
import PropertyTabWrapper from "../container/PropertyTab/PropertyTabWrapper";
import SearchTab from "../container/SearchTab/SearchTab";
import SearchSetsTab from "../container/SearchSetsTab/SearchSetsTab";
import EditSearchTab from "../container/EditSearchTab/EditSearchTab";
import ClashDetectionTab from "../container/ClashDetectionTab/ClashDetectionTab";
import ClashTestTab from "../container/ClashTestTab/ClashTestTab";
import AppearenceProfileTab from "../container/AppearenceProfileTab/AppearenceProfileTab";
import AppearenceTemplateTab from "../container/AppearenceTemplateTab/AppearenceTemplateTab";
import AnnotationTab from "../container/AnnotationTab/AnnotationTab";
import XeokitViewer from "../container/XeokitViewer/XeokitViewer";
import Measure from "../container/XeokitViewer/Measure";
// import Thumbnail from "../container/Thumbnail/Thumbnail";
import IssueTrack from "../container/IssueTrack/IssueTrack";
import FileUpload from "../container/XeokitViewer/FileUpload";
import TreeViewTab from "../container/TreeViewTab/TreeViewTab";
import ImagePlaneTab from "../container/ImagePlane/ImagePlane";
import MySpinner from "../components/Spinner/Spinner";
import ViewerTabWrapper from "../container/ViewerTab/ViewerTabWrapper";

import "../styles/mainPage.css";
import TestComponent from "./desktopAppModalWrapper";

const MainPage = (props) => {
  const [isNewWindow, setIsNewWindow] = React.useState(false);

  return props.projectName ? (
    <div className="mainpage checkupdate">
      <Header />
      {/* <button onClick={() => setIsNewWindow(true)}>Open in new window</button>
      <button onClick={() => window.ipcRenderer.openDialog("openDialog")}>
        Open in confirm Dialog
      </button> */}
      <div className="model-viewer">
        <XeokitViewer />
        {/* <Toolbar /> */}
        <MenuBar />
        <PropertyTabWrapper />
        <SearchTab />
        <SearchSetsTab />
        <EditSearchTab />
        <ClashDetectionTab />
        <ClashTestTab />
        <AppearenceProfileTab />
        <AppearenceTemplateTab />
        <AnnotationTab />
        {/* <Measure /> */}
        {/* <Thumbnail /> */}
        <IssueTrack />
        <TreeViewTab />
        <ImagePlaneTab />
        <MySpinner />
        <ViewerTabWrapper />
        {isNewWindow && (
          <TestComponent onClose={() => setIsNewWindow(false)}>
            <h2>This will display in a new window</h2>
          </TestComponent>
        )}
      </div>
    </div>
  ) : (
    <Navigate replace to="/projects" />
  );
};

// export default mainPage;
const mapStateToProps = (state) => {
  return {
    setModelLoadFlage: state.xeokitReducer.setModelLoadFlage,
    projectName: state.ProjectReducer.projectName,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
