import React from "react";
import "./MenubarItem.css";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Rect from "../../asset/rect.svg";

const MenuBarItem = ({ listTitle, status, action }) => {
  const onChange = (checked) => {
    action && action(checked);
  };

  return (
    <div className="item">
      <div>{listTitle}</div>
      <div className="item-btn">
        <div>
          <ToggleSwitch
            label={listTitle}
            switchStatus={status}
            onChange={onChange}
          />
        </div>
        <div className="item-rect-btn">
          <img src={Rect} alt="centericon" />
        </div>
      </div>
    </div>
  );
};

export default MenuBarItem;
