import React from "react";
import "./Dropdown.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./Dropdown.css';
import { Constants } from "../../constant/constants";

const Dropdown = React.memo(
  ({ optionArray, onChange, onClick, value, defaultValue }) => {
    return (
      <>
        {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
        <select
          className="select"
          onChange={(e) => onChange(e)}
          onClick={(e) => onClick(e)}
          value={value}
        >
          {optionArray?.map((element, i) => (
            <option value={element} key={i}>
              {element}
            </option>
          ))}
        </select>
      </>
    );
  }
);

export default Dropdown;
