import React from "react";

import Edit from "../../asset/edit.svg";
import Add from "../../asset/add.svg";
import Search from "../../asset/search.svg";
import "./Button.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./Button.css';
import { Constants } from "../../constant/constants";

export const Button = ({ buttonName, onClick, ...rest }) => {
  return (
    <button className="mycustom-button" onClick={onClick} {...rest}>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      {buttonName}
    </button>
  );
};

export const MiniButton = ({ buttonName, onClick, ...rest }) => {
  return (
    <button className="mini-button" onClick={onClick} {...rest}>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      {buttonName}
    </button>
  );
};

export const IconButton = ({ buttonType, buttonName, onClick, ...rest }) => {
  switch (buttonType) {
    case "Save":
      return (
        <button className="mycustom-button" onClick={onClick} {...rest}>
          {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
          <img src={Edit} alt="edit" />
          {buttonName}
        </button>
      );
    case "Edit":
      return (
        <button className="mycustom-button" onClick={onClick} {...rest}>
          {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
          <img src={Edit} alt="edit" />
          {buttonName}
        </button>
      );
    case "Add":
      return (
        <button className="mycustom-button" onClick={onClick} {...rest}>
          {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
          <img src={Add} alt="add" />
          {buttonName}
        </button>
      );
    case "Search":
      return (
        <button className="mycustom-button" onClick={onClick} {...rest}>
          {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
          <img src={Search} alt="search" />
          {buttonName}
        </button>
      );
    default:
      break;
  }
};

export const CheckBox = ({ onChange, checked, ...rest }) => {
  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <label className="custom-checkbox">
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          {...rest}
        />
        <span className="checkmark"></span>
      </label>
    </>
  );
};

export const CustomDate = ({
  date,
  handleDateChange,
  style,
  disabled = false,
}) => {
  return (
    <input
      type="date"
      value={date}
      onChange={handleDateChange}
      required
      style={style}
      disabled={disabled}
    />
  );
};
