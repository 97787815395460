// Modal.js
import React, { forwardRef } from "react";

import "./Box.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./Box.css';
import { Constants } from "../../constant/constants";

const Box = forwardRef(({ isToggle, style, children }, ref) => {
  return (
    isToggle && (
      <>
        {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
        <div className="box-container" style={style} ref={ref}>
          {children}
        </div>
      </>
    )
  );
});

export default Box;
