import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NzA2OGJjMDFhNTg5ZDczNDQ2NjU5YTQiLCJpYXQiOjE3MzM1MDE1MzYsImV4cCI6MTc1MTUwMTUzNiwidHlwZSI6ImFjY2VzcyJ9.6fqOCrUUHYKvZyjQq7HrZ6Drn5K5matSAeJwQuqRAlc";

const headers = {
  Authorization: `Bearer ${ACCESS_TOKEN}`,
};

// Get all projects
export async function getProjects() {
  try {
    console.log("Fetching projects with token:", ACCESS_TOKEN);
    const response = await axios.get(
      `${API_BASE_URL}/api/v1/projects?page=1&limit=100&search=`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching projects:",
      error.response?.data || error.message
    );
    throw new Error("Failed to fetch projects: " + error.message);
  }
}

// Get models by project ID
export async function getModelsByProject(projectId) {
  try {
    console.log("Fetching models for project:", projectId);
    const response = await axios.get(
      `${API_BASE_URL}/api/v1/models/projects/${projectId}`,
      { headers }
    );

    // Group models by originalName
    const groupedModels = response.data?.results.reduce((acc, model) => {
      if (!acc[model.originalName]) {
        acc[model.originalName] = [];
      }
      acc[model.originalName].push(model);
      return acc;
    }, {});

    return groupedModels;
  } catch (error) {
    console.error(
      "Error fetching models:",
      error.response?.data || error.message
    );
    throw new Error("Failed to fetch models: " + error.message);
  }
}

// Get XKT file
export async function getXKTFile(fileName) {
  try {
    console.log("Fetching XKT file:", fileName);
    const response = await axios.get(
      `${API_BASE_URL}/uploads/models/${fileName}`,
      {
        headers,
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching XKT:", error.response?.data || error.message);
    throw new Error("Failed to fetch XKT file: " + error.message);
  }
}

// Get JSON data
export async function getJSONData(fileName) {
  try {
    console.log("Fetching JSON file:", fileName);
    const response = await axios.get(
      `${API_BASE_URL}/uploads/models/${fileName}`,
      { headers }
    );
    console.log("JSON response:", {
      status: response.status,
      contentType: response.headers["content-type"],
      dataType: typeof response.data,
      hasTreeView: response.data?.TreeView ? true : false,
      data: response.data,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching JSON:",
      error.response?.data || error.message
    );
    throw new Error("Failed to fetch JSON file: " + error.message);
  }
}

// Get XKT URL - returns a URL that can be used by the XKT loader
export function getXKTUrl(fileName) {
  // Ensure the URL is properly encoded and includes the token
  const encodedFileName = encodeURIComponent(fileName);
  return `${API_BASE_URL}/uploads/models/${encodedFileName}?token=${ACCESS_TOKEN}`;
}
