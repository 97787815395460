import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import TabPanel from "./TabPanel";
import { CheckBox } from "../../components/Button/Button";
import Dropdown from "../../components/DropDown/Dropdown";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get } from "../../axios/axios";
import Filter from "../../asset/filter.svg";
import {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setMarkupAnnotationId,
} from "../../action/xeokitAction";
import { Constants } from "../../constant/constants";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";
import "./IssueTrack.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./IssueTrack.css';

const IssueTrack = ({
  isIssueTrackOpen,
  imageData,
  IssuesArray,
  logsArray,
  handleIssueUpdate,
  updateScreenIssueId,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  projectId,
  token,
  viewer,
  setMarkupAnnotationId,
}) => {
  const [detailTabData, setDetailTabData] = useState({});
  const [logTabData, setLogTabData] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [clickHandler, setClickHandler] = useState(null);
  const [checkedTabData, setCheckedTabData] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    type: "",
    stamp: "",
    assignee: "",
  });
  const [activeFilter, setActiveFilter] = useState(null);

  const [columns, setColumns] = useState([
    { id: "checkbox", width: 50, component: <CheckBox /> },
    { id: "id", width: 80, label: "ID" },
    { id: "new", width: 80, label: "New" },
    { id: "issueTitle", width: 150, label: "Issue Title" },
    { id: "stamp", width: 120, label: "Stamp", hasFilter: true },
    { id: "status", width: 120, label: "Status", hasFilter: true },
    { id: "type", width: 120, label: "Type", hasFilter: true },
    { id: "assignedTo", width: 150, label: "Assigned to", hasFilter: true },
    { id: "dueDate", width: 120, label: "Due date" },
    { id: "startDate", width: 120, label: "Start date" },
    { id: "level", width: 100, label: "Level" },
    { id: "grid", width: 100, label: "Grid" },
  ]);

  const [resizing, setResizing] = useState(null);
  const [isHandleHovered, setIsHandleHovered] = useState(false);
  const tableRef = useRef(null);

  const handleMouseDown = (index, e) => {
    setResizing({
      index,
      startX: e.pageX,
      startWidth: columns[index].width,
    });
  };

  const handleMouseMove = (e) => {
    if (!resizing) return;

    console.log("?????????");
    const diff = e.pageX - resizing.startX;
    const newWidth = Math.max(50, resizing.startWidth + diff);

    setColumns(
      columns.map((col, index) =>
        index === resizing.index ? { ...col, width: newWidth } : col
      )
    );
  };

  const handleMouseUp = () => {
    setResizing(null);
  };

  useEffect(() => {
    if (detailTabData?.id) {
      setMarkupAnnotationId(detailTabData.id);
      viewer.camera.eye = [
        detailTabData.camera.eye[0],
        detailTabData.camera.eye[1],
        detailTabData.camera.eye[2],
      ];
      viewer.camera.look = [
        detailTabData.camera.look[0],
        detailTabData.camera.look[1],
        detailTabData.camera.look[2],
      ];
      viewer.camera.up = [
        detailTabData.camera.up[0],
        detailTabData.camera.up[1],
        detailTabData.camera.up[2],
      ];
    }
  }, [detailTabData]);

  useEffect(() => {
    if (resizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [resizing]);

  const renderHeaderContent = (column) => {
    if (column.component) {
      return column.component;
    }

    if (column.hasFilter) {
      return (
        <div className="filter-header">
          {column.label}
          <span className="filter-icon" onClick={() => toggleFilter(column.id)}>
            <img src={Filter} width={18} height={18} alt="filter" />
          </span>
          {activeFilter === column.id && <FilterDropdown field={column.id} />}
        </div>
      );
    }

    return column.label;
  };

  const getAllSearches = async (pg = page) => {
    if (!isIssueTrackOpen) return;
    const url = BaseURL(
      `annotations/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("issue-response", response);
      setIssueList(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isIssueTrackOpen) return;
    getAllSearches();
  }, [isIssueTrackOpen]);
  useEffect(() => {
    if (!isIssueTrackOpen) return;
    getAllSearches();
  }, [handleIssueUpdate]);

  useEffect(() => {
    setLogTabData(logsArray[updateScreenIssueId] || []);
  }, [logsArray]);

  const closeModal = React.useCallback(
    () => setIssueTrackStatusAction(false),
    [isIssueTrackOpen]
  );

  const handleRowClick = (rowData, index) => {
    setDetailTabData(rowData);
    setClickHandler(index);
    // setLogTabData(structuredClone(logsArray[index]));
  };

  const updateDetailTabData = (newData, type) => {
    if (type === "markUp")
      setDetailTabData({ ...detailTabData, markUp: newData });
    else setDetailTabData({ ...detailTabData, ...newData });
  };

  const saveUpdatedIssue = (objectId, objectKey, objectValue) => {
    const updatedIssues = structuredClone(IssuesArray);
    const issueIndex = updatedIssues.findIndex((item) => item.id === objectId);

    if (issueIndex !== -1) {
      updatedIssues[issueIndex][objectKey] = objectValue;
      setDetailTabData(updatedIssues[issueIndex]);

      const updatedLogs = structuredClone(logsArray);
      updatedLogs[issueIndex].push({
        [objectKey]: objectValue,
        createdDate: formatDate(),
      });

      setLogTabData(updatedLogs[issueIndex]);
      saveLogArrayAction(updatedLogs);
      saveIssueArrayAction(updatedIssues);
    }
  };

  // const formatDate = () => {
  //   const now = new Date();

  //   // Get the components of the date
  //   const day = String(now.getDate()).padStart(2, "0");
  //   const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const year = now.getFullYear();
  //   const hours = now.getHours();
  //   const minutes = String(now.getMinutes()).padStart(2, "0");

  //   // Determine AM or PM
  //   const ampm = hours >= 12 ? "pm" : "am";

  //   // Format the hours in 12-hour format
  //   const formattedHours = hours % 12 || 12;

  //   // Format the date and time as desired
  //   const formattedDate = `${day}/${month}/${year} at ${formattedHours}:${minutes} ${ampm}`;

  //   return formattedDate;
  // };

  const formatDate = (issueDate) => {
    const date = new Date(issueDate);

    // Get day, month, and year
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "short" }); // "Dec"
    const year = date.getUTCFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const onHandleCheckBox = (e, issue) => {
    if (e.target.checked) {
      setCheckedTabData([...checkedTabData, issue]);
    } else
      setCheckedTabData(checkedTabData.filter((item) => item.id !== issue.id));
  };

  // Function to get unique values for each column
  const getUniqueValues = (field) => {
    if (!issueList || !issueList.length) return [];
    if (field === "assignee") {
      // Special handling for assignee array
      const allAssignees = issueList.flatMap((issue) =>
        issue.assignee.map((a) => a.name)
      );
      const temp = [...new Set(allAssignees)];
      temp.unshift("All");
      return temp;
    }

    const temp = [...new Set(issueList.map((item) => item[field]))];
    temp.unshift("All");
    return temp;
  };

  // Function to filter issues
  const getFilteredIssues = () => {
    return issueList.filter((issue) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        if (key === "assignee") {
          return issue.assignee.some((a) => a.name === value);
        }
        return issue[key]?.toString() === value?.toString();
      });
    });
  };

  // Function to handle filter changes
  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value === "All" ? "" : value,
    }));
  };

  // Filter dropdown component
  const FilterDropdown = ({ field }) => (
    <div className="filter-dropdown">
      <Dropdown
        value={filters[field]}
        optionArray={getUniqueValues(field)}
        onChange={(e) => handleFilterChange(field, e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );

  // Function to toggle filter dropdown
  const toggleFilter = (field) => {
    setActiveFilter(activeFilter === field ? null : field);
  };

  const webContents = (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div
        className="issue-track"
        style={Constants.DESKTOP_APP ? { width: "100%", height: "100%" } : {}}
      >
        <div className="issue-track-header">
          <div
            className="issue-track-header-name"
            style={
              Constants.DESKTOP_APP ? { "-webkit-app-region": "drag" } : {}
            }
          >
            <span>Issue Tracker</span>
          </div>
          <div className="issue-track-cancel-btn" onClick={closeModal}>
            X
          </div>
        </div>
        <div className="seperate-line"></div>
        <div className="issue-track-body">
          <div className="issue-track-list-container">
            <div className="issue-track-list">
              <table
                className="custom-table issue-track-table-width"
                ref={tableRef}
              >
                <thead>
                  {/* <tr>
                    <th>
                      <CheckBox />
                    </th>
                    <th>ID</th>
                    <th>New</th>
                    <th>Issue Title</th>
                    <th>
                      <div className="filter-header">
                        Stamp
                        <span
                          className="filter-icon"
                          onClick={() => toggleFilter("stamp")}
                        >
                          <img
                            src={Filter}
                            width={18}
                            height={18}
                            alt="filter"
                          />
                        </span>
                        {activeFilter === "stamp" && (
                          <FilterDropdown field="stamp" />
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="filter-header">
                        Status
                        <span
                          className="filter-icon"
                          onClick={() => toggleFilter("status")}
                        >
                          <img
                            src={Filter}
                            width={18}
                            height={18}
                            alt="filter"
                          />
                        </span>
                        {activeFilter === "status" && (
                          <FilterDropdown field="status" />
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="filter-header">
                        Type
                        <span
                          className="filter-icon"
                          onClick={() => toggleFilter("type")}
                        >
                          <img
                            src={Filter}
                            width={18}
                            height={18}
                            alt="filter"
                          />
                        </span>
                        {activeFilter === "type" && (
                          <FilterDropdown field="type" />
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="filter-header">
                        Assigned to
                        <span
                          className="filter-icon"
                          onClick={() => toggleFilter("assignee")}
                        >
                          <img
                            src={Filter}
                            width={18}
                            height={18}
                            alt="filter"
                          />
                        </span>
                        {activeFilter === "assignee" && (
                          <FilterDropdown field="assignee" />
                        )}
                      </div>
                    </th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Level</th>
                    <th>Grid</th>
                  </tr> */}
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        key={column.id}
                        style={{
                          width: column.width,
                          position: "relative",
                        }}
                      >
                        {renderHeaderContent(column)}
                        <div
                          className={
                            isHandleHovered
                              ? "resizeHandle resizeHandleHover"
                              : "resizeHandle "
                          }
                          onMouseDown={(e) => handleMouseDown(index, e)}
                          onMouseEnter={() => setIsHandleHovered(true)}
                          onMouseLeave={() => setIsHandleHovered(false)}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {getFilteredIssues().map((issue, i) => (
                    <tr
                      className={
                        clickHandler === i
                          ? "issue-track-table-row table-row-clicked"
                          : "issue-track-table-row"
                      }
                      key={i}
                      onClick={() => handleRowClick(issue, i)}
                    >
                      <td className="table-col-checkbox">
                        <CheckBox
                          onChange={(e) => onHandleCheckBox(e, issue)}
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className={issue.new ? "new-issue" : null}></div>
                        </div>
                      </td>
                      <td>{issue.title}</td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className={`issue-track-stamp-${issue.stamp}`}>
                            {issue.stamp}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="issue-track-status-container">
                          <div
                            className={`issue-track-status-${issue.status}`}
                          ></div>
                          <div className="issue-track-status-content">
                            {issue.status}
                          </div>
                        </div>
                      </td>
                      <td>{issue.type}</td>
                      <td>
                        {issue.assignee.length > 1
                          ? `${issue.assignee.length} people`
                          : issue.assignee[0].name}
                      </td>
                      <td>{issue.dueDate}</td>
                      <td>{issue.startDate}</td>
                      <td>{issue.level}</td>
                      <td>{issue.grid}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="vertical-seperate-line"></div>
          <div className="issue-track-tab-container">
            <div className="issue-track-tab">
              <TabPanel
                imageData={imageData}
                detailTabData={detailTabData}
                checkedTabData={checkedTabData}
                saveUpdatedIssue={saveUpdatedIssue}
                logTabData={logTabData}
                updateDetailTabData={updateDetailTabData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    isIssueTrackOpen &&
    (Constants.DESKTOP_APP ? (
      <DesktopAppModalWrapper
        title="issue-tracker"
        children={webContents}
        state={isIssueTrackOpen}
        onClose={closeModal}
      />
    ) : (
      <div className="issue-track-container">{webContents}</div>
    ))
  );
};

const mapStateToProps = (state) => ({
  viewer: state.xeokitReducer.viewer,
  isIssueTrackOpen: state.IssueTrackReducer.isIssueTrackOpen,
  imageData: state.AnnotationReducer.imageData,
  IssuesArray: state.IssueTrackReducer.IssuesArray,
  logsArray: state.IssueTrackReducer.logsArray,
  handleIssueUpdate: state.IssueTrackReducer.handleIssueUpdate,
  updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
  projectId: state.ProjectReducer.projectId,
  token: state.AuthReducer.token,
});

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setMarkupAnnotationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueTrack);
