import { useState, useEffect } from "react";
import { getJSONData } from "../middleware/modelData";
import { JsonComparer } from "../pages/Compare/middleware/jsonComparer";

// Module-level store for model data
let modelDataStore = {
  data1: null,
  data2: null,
};

// Helper function to check if model data is loaded
const isModelDataLoaded = () => {
  return modelDataStore.data1 !== null && modelDataStore.data2 !== null;
};

// Helper function to clear model data store
const clearModelDataStore = () => {
  modelDataStore = {
    data1: null,
    data2: null,
  };
};

export function useModelComparison(
  version1,
  version2,
  isVersionsSwapped = false
) {
  const [treeData, setTreeData] = useState([]);
  const [addedElements, setAddedElements] = useState([]);
  const [removedElements, setRemovedElements] = useState([]);
  const [changedElements, setChangedElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checkedNodes, setCheckedNodes] = useState(new Set());

  // Helper functions moved from JsonComparer
  const excludedPropertyNames = ["Area", "23", "_id"];
  const elementIdToNameDicBase = new Map();
  const elementIdToNameDicCompare = new Map();

  const isObject = (value) => {
    return value !== null && typeof value === "object" && !Array.isArray(value);
  };

  const areValuesEqual = (value1, value2) => {
    return JSON.stringify(value1) === JSON.stringify(value2);
  };

  const extractFamilyAndType = (treeView, elementIdToNameDic) => {
    if (typeof treeView !== "object" || treeView === null) {
      return;
    }

    Object.entries(treeView).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        if (value["Family and Type"]) {
          elementIdToNameDic.set(key, value["Family and Type"]);
        }
        extractFamilyAndType(value, elementIdToNameDic);
      }
    });
  };

  const compareJsonObjects = (obj1, obj2) => {
    const baseJsonGuid = obj1.Guid || "";
    const compareJsonGuid = obj2.Guid || "";

    const report = {
      ComparePair: {
        BaseJsonGuid: baseJsonGuid,
        CompareJsonGuid: compareJsonGuid,
      },
      Added: {},
      Deleted: {},
      Changed: {},
    };

    compareElements(obj1, obj2, "", report);
    return report;
  };

  const compareElements = (obj1, obj2, path, report) => {
    if (!obj1 || !obj2) return;

    const obj1Properties = new Set(Object.keys(obj1));
    const obj2Properties = new Set(Object.keys(obj2));

    // Process properties in base JSON
    for (const key of obj1Properties) {
      let propertyName = key;

      // Use meaningful names from the dictionaries
      if (elementIdToNameDicBase.has(propertyName)) {
        propertyName = elementIdToNameDicBase.get(propertyName);
      }

      const newPath = path ? `${path}.${propertyName}` : propertyName;

      if (obj2.hasOwnProperty(key)) {
        const obj1Value = obj1[key];
        const obj2Value = obj2[key];

        // Handle nested objects
        if (isObject(obj1Value) && isObject(obj2Value)) {
          compareElements(obj1Value, obj2Value, newPath, report);
        } else if (!areValuesEqual(obj1Value, obj2Value)) {
          report.Changed[newPath] = {
            from: obj1Value,
            to: obj2Value,
          };
        }
      } else {
        report.Deleted[newPath] = obj1[key];
      }
    }

    // Find added elements
    for (const key of obj2Properties) {
      if (!obj1Properties.has(key)) {
        let propertyName = key;

        // Skip excluded properties
        if (excludedPropertyNames.includes(propertyName)) {
          continue;
        }

        // Use meaningful names from the dictionaries
        if (elementIdToNameDicCompare.has(propertyName)) {
          propertyName = elementIdToNameDicCompare.get(propertyName);
        }

        const newPath = path ? `${path}.${propertyName}` : propertyName;
        report.Added[newPath] = obj2[key];
      }
    }
  };

  const compareAndWriteReportStr = (baseJsonStr, compareJsonStr) => {
    try {
      // Parse JSON strings
      const baseJson = JSON.parse(baseJsonStr);
      const compareJson = JSON.parse(compareJsonStr);

      // Extract legend elements
      const legendElementBase = baseJson.Legend || null;
      const legendElementCompare = compareJson.Legend || null;

      // Extract TreeView elements and process family/type info
      if (baseJson.TreeView) {
        extractFamilyAndType(baseJson.TreeView, elementIdToNameDicBase);
      }
      if (compareJson.TreeView) {
        extractFamilyAndType(compareJson.TreeView, elementIdToNameDicCompare);
      }

      // Generate comparison report
      const report = compareJsonObjects(baseJson, compareJson);
      return JSON.stringify(report, null, 2);
    } catch (err) {
      console.error("Error in compareAndWriteReportStr:", err);
      return null;
    }
  };

  // Effect to run initial comparison when versions change
  useEffect(() => {
    console.log("useModelComparison effect triggered with versions:", {
      version1: version1?.fileName,
      version2: version2?.fileName,
      json1: version1?.json,
      json2: version2?.json,
      isVersionsSwapped,
    });

    async function compareModels() {
      if (!version1 || !version2) {
        console.log("Missing version data");
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Fetch model data
        const [data1, data2] = await Promise.all([
          getJSONData(version1.json || version1.fileName),
          getJSONData(version2.json || version2.fileName),
        ]);

        if (!data1 || !data2) {
          throw new Error("Failed to fetch model data");
        }

        // Update the global store
        modelDataStore.data1 = data1;
        modelDataStore.data2 = data2;

        // Process both versions and create merged tree
        const v1Tree = processVersionData(data1.TreeView, "v1");
        const v2Tree = processVersionData(data2.TreeView, "v2");
        const mergedTree = mergeTreeData(v1Tree, v2Tree);
        setTreeData(mergedTree);

        // Extract family and type information
        elementIdToNameDicBase.clear();
        elementIdToNameDicCompare.clear();
        extractFamilyAndType(data1.TreeView, elementIdToNameDicBase);
        extractFamilyAndType(data2.TreeView, elementIdToNameDicCompare);

        // Extract all elements and compare
        const elements1 = extractAllElements(data1.TreeView);
        const elements2 = extractAllElements(data2.TreeView);
        const comparisonResult = compareElementsDirectly(elements1, elements2);

        setAddedElements(
          Object.entries(comparisonResult.Added).map(([id, value]) => ({
            id,
            familyType:
              value && typeof value === "object"
                ? value["Family and Type"] || "Unknown Type"
                : String(value),
          }))
        );
        setRemovedElements(
          Object.entries(comparisonResult.Deleted).map(([id, value]) => ({
            id,
            familyType:
              value && typeof value === "object"
                ? value["Family and Type"] || "Unknown Type"
                : String(value),
          }))
        );
        setChangedElements(
          Object.entries(comparisonResult.Changed).map(
            ([id, { from, to }]) => ({
              id,
              familyType:
                to && typeof to === "object"
                  ? to["Family and Type"] || "Unknown Type"
                  : String(to),
              oldVersion: {
                familyType:
                  from && typeof from === "object"
                    ? from["Family and Type"] || "Unknown Type"
                    : String(from),
              },
              newVersion: {
                familyType:
                  to && typeof to === "object"
                    ? to["Family and Type"] || "Unknown Type"
                    : String(to),
              },
            })
          )
        );

        setLoading(false);
      } catch (err) {
        console.error("Error in compareModels:", err);
        setError(err.message);
        setLoading(false);
      }
    }

    compareModels();

    // Cleanup function to clear the store when unmounting or when versions change
    return () => {
      clearModelDataStore();
    };
  }, [version1, version2, isVersionsSwapped]);

  // Process model data into tree structure
  function processModelData(data) {
    if (!data.TreeView) {
      console.warn("No TreeView data available");
      return [];
    }
    console.log("Processing model data:", {
      hasTreeView: true,
      treeViewKeys: Object.keys(data.TreeView),
    });

    const tree = [];

    Object.entries(data.TreeView).forEach(([projectName, project]) => {
      // Skip non-object entries
      if (typeof project !== "object" || project === null) {
        return;
      }

      const projectNode = {
        id: `project_${projectName}`,
        name: projectName,
        type: "folder",
        children: [],
      };

      // Process Levels
      if (project.Levels) {
        const levelsNode = {
          id: `${projectNode.id}/levels`,
          name: "Levels",
          type: "folder",
          children: [],
        };

        // Process each level
        Object.entries(project.Levels).forEach(([levelName, level]) => {
          const levelNode = {
            id: `${levelsNode.id}/level_${levelName}`,
            name: levelName,
            type: "folder",
            children: [],
          };

          // Process categories within each level
          Object.entries(level).forEach(([categoryName, category]) => {
            if (typeof category === "object" && category !== null) {
              const categoryNode = {
                id: `${levelNode.id}/category_${categoryName}`,
                name: categoryName,
                type: "folder",
                children: [],
              };

              // Process elements within each category
              Object.entries(category).forEach(([elementId, element]) => {
                if (element && typeof element === "object") {
                  categoryNode.children.push({
                    id: elementId,
                    name: `${
                      element["Family and Type"] || element.name || "Unknown"
                    } + ${elementId}`,
                    type: "element",
                    fullPath: `${levelsNode.id}/level_${levelName}/category_${categoryName}/element_${elementId}`,
                    familyType:
                      element["Family and Type"] || element.name || "Unknown",
                  });
                }
              });

              if (categoryNode.children.length > 0) {
                levelNode.children.push(categoryNode);
              }
            }
          });

          if (levelNode.children.length > 0) {
            levelsNode.children.push(levelNode);
          }
        });

        if (levelsNode.children.length > 0) {
          projectNode.children.push(levelsNode);
        }
      }

      // Process Links - maintaining the same structure as Levels
      if (project.Links) {
        const linksNode = {
          id: `${projectNode.id}/links`,
          name: "Links",
          type: "folder",
          children: [],
        };

        // Process each link
        Object.entries(project.Links).forEach(([linkName, link]) => {
          if (typeof link === "object" && link !== null) {
            const linkNode = {
              id: `${linksNode.id}/link_${linkName}`,
              name: linkName,
              type: "folder",
              children: [],
            };

            // Process each level in the link
            Object.entries(link).forEach(([levelName, level]) => {
              if (typeof level === "object" && level !== null) {
                const levelNode = {
                  id: `${linkNode.id}/level_${levelName}`,
                  name: levelName,
                  type: "folder",
                  children: [],
                };

                // Process categories within each level
                Object.entries(level).forEach(([categoryName, category]) => {
                  // Skip RVT Links category
                  if (categoryName === "RVT Links") return;

                  if (typeof category === "object" && category !== null) {
                    const categoryNode = {
                      id: `${levelNode.id}/category_${categoryName}`,
                      name: categoryName,
                      type: "folder",
                      children: [],
                    };

                    // Process elements within each category
                    Object.entries(category).forEach(([elementId, element]) => {
                      if (element && typeof element === "object") {
                        categoryNode.children.push({
                          id: elementId,
                          name: `${
                            element["Family and Type"] ||
                            element.name ||
                            "Unknown"
                          } + ${elementId}`,
                          type: "element",
                          fullPath: `${linkNode.id}/level_${levelName}/category_${categoryName}/element_${elementId}`,
                          familyType:
                            element["Family and Type"] ||
                            element.name ||
                            "Unknown",
                        });
                      }
                    });

                    if (categoryNode.children.length > 0) {
                      levelNode.children.push(categoryNode);
                    }
                  }
                });

                if (levelNode.children.length > 0) {
                  linkNode.children.push(levelNode);
                }
              }
            });

            if (linkNode.children.length > 0) {
              linksNode.children.push(linkNode);
            }
          }
        });

        if (linksNode.children.length > 0) {
          projectNode.children.push(linksNode);
        }
      }

      if (projectNode.children.length > 0) {
        tree.push(projectNode);
      }
    });

    return tree;
  }

  // Handle element selection
  const handleElementSelect = (elementId) => {
    setSelectedElement(elementId);
    compareElementProperties(elementId);
  };

  // Handle clearing selection
  const handleClearSelection = () => {
    setSelectedElement(null);
    // Restore the previous comparison state based on checked nodes
    if (selectedElement) {
      if (checkedNodes.size > 0) {
        // If we have checked nodes, rerun their comparison
        compareNodeElements(checkedNodes);
      } else {
        // Otherwise, rerun the initial comparison
        const comparer = new JsonComparer();
        const comparisonResult = comparer.compareAndWriteReportStr(
          JSON.stringify(modelDataStore.data1),
          JSON.stringify(modelDataStore.data2)
        );

        if (comparisonResult) {
          const report = JSON.parse(comparisonResult);

          // Transform results
          const added = Object.entries(report.Added).map(([id, value]) => ({
            id,
            familyType:
              value && typeof value === "object"
                ? value["Family and Type"] || "Unknown Type"
                : String(value),
          }));

          const removed = Object.entries(report.Deleted).map(([id, value]) => ({
            id,
            familyType:
              value && typeof value === "object"
                ? value["Family and Type"] || "Unknown Type"
                : String(value),
          }));

          const changed = Object.entries(report.Changed).map(
            ([id, { from, to }]) => ({
              id,
              familyType:
                to && typeof to === "object"
                  ? to["Family and Type"] || "Unknown Type"
                  : String(to),
              oldVersion: {
                familyType:
                  from && typeof from === "object"
                    ? from["Family and Type"] || "Unknown Type"
                    : String(from),
              },
              newVersion: {
                familyType:
                  to && typeof to === "object"
                    ? to["Family and Type"] || "Unknown Type"
                    : String(to),
              },
            })
          );

          setAddedElements(isVersionsSwapped ? removed : added);
          setRemovedElements(isVersionsSwapped ? added : removed);
          setChangedElements(changed);
        }
      }
    }
  };

  // Compare elements for specific nodes
  const compareNodeElements = async (newCheckedNodes) => {
    if (!newCheckedNodes || !modelDataStore.data1 || !modelDataStore.data2) {
      console.log("Missing required data for node comparison");
      return;
    }

    try {
      setLoading(true);
      setCheckedNodes(new Set(newCheckedNodes));

      // Extract elements for checked nodes
      const elements1 = {};
      const elements2 = {};

      // Process checked nodes using nodeMap for efficient lookup
      for (const nodeId of newCheckedNodes) {
        // Find element in both versions using findElementPropertiesInData
        const element1 = findElementFamilyAndTypeInData(
          modelDataStore.data1,
          nodeId
        );
        const element2 = findElementFamilyAndTypeInData(
          modelDataStore.data2,
          nodeId
        );

        // Add to elements1 if found in version 1
        if (element1) {
          elements1[nodeId] = { ...element1 };
        }

        // Add to elements2 if found in version 2
        if (element2) {
          elements2[nodeId] = { ...element2 };
        }
      }

      // Compare using compareElementsDirectly
      const report = compareElementsDirectly(elements1, elements2);

      if (!report) {
        throw new Error("Node comparison failed");
      }

      // Transform results
      const added = Object.entries(report.Added).map(([id, value]) => ({
        id,
        familyType:
          value && typeof value === "object"
            ? value["Family and Type"] || "Unknown Type"
            : String(value),
      }));

      const removed = Object.entries(report.Deleted).map(([id, value]) => ({
        id,
        familyType:
          value && typeof value === "object"
            ? value["Family and Type"] || "Unknown Type"
            : String(value),
      }));

      const changed = Object.entries(report.Changed).map(
        ([id, { from, to }]) => ({
          id,
          familyType:
            to && typeof to === "object"
              ? to["Family and Type"] || "Unknown Type"
              : String(to),
          oldVersion: {
            familyType:
              from && typeof from === "object"
                ? from["Family and Type"] || "Unknown Type"
                : String(from),
          },
          newVersion: {
            familyType:
              to && typeof to === "object"
                ? to["Family and Type"] || "Unknown Type"
                : String(to),
          },
        })
      );

      setAddedElements(isVersionsSwapped ? removed : added);
      setRemovedElements(isVersionsSwapped ? added : removed);
      setChangedElements(changed);
    } catch (error) {
      console.error("Error comparing node elements:", error);
      setError("Failed to compare elements for the selected nodes");
    } finally {
      setLoading(false);
    }
  };

  // Helper function to find a node in the tree
  function findNode(nodes, targetId) {
    for (const node of nodes) {
      if (node.id === targetId) {
        return node;
      }
      if (node.children) {
        const found = findNode(node.children, targetId);
        if (found) return found;
      }
    }
    return null;
  }

  // Compare properties for a specific element
  const compareElementProperties = (elementId) => {
    if (!elementId) {
      console.log("No element ID provided for property comparison");
      return;
    }

    try {
      const report = compareElementPropertiesWithoutProcess(elementId);
      if (!report) {
        throw new Error("Property comparison failed");
      }

      // Process the comparison results and update UI state
      processComparisonResults(report, elementId);
    } catch (error) {
      console.error("Error comparing element properties:", error);
      setError("Failed to compare element properties");
    }
  };

  // Compare properties without UI state processing
  const compareElementPropertiesWithoutProcess = (elementId) => {
    if (!elementId) {
      console.log("No element ID provided for property comparison");
      return null;
    }

    if (!isModelDataLoaded()) {
      console.warn("Model data not loaded yet");
      return null;
    }

    try {
      // Find the element in both versions
      const element1 = findElementPropertiesInData(
        modelDataStore.data1,
        elementId
      );
      const element2 = findElementPropertiesInData(
        modelDataStore.data2,
        elementId
      );

      if (!element1 && !element2) {
        throw new Error("Element not found in either version");
      }

      // When versions are swapped, we need to swap which version is considered "old" vs "new"
      const baseElement = isVersionsSwapped ? element2 : element1;
      const compareElement = isVersionsSwapped ? element1 : element2;

      // Compare elements
      const comparisonResult = compareAndWriteReportStr(
        JSON.stringify(baseElement || {}),
        JSON.stringify(compareElement || {})
      );

      if (!comparisonResult) {
        throw new Error("Property comparison failed");
      }

      return JSON.parse(comparisonResult);
    } catch (error) {
      console.error("Error comparing element properties:", error);
      return null;
    }
  };

  // Process comparison results and update UI state
  const processComparisonResults = (report, elementId) => {
    if (!isModelDataLoaded()) {
      console.warn("Model data not loaded yet");
      return;
    }

    const hasChanges =
      Object.keys(report.Added).length > 0 ||
      Object.keys(report.Deleted).length > 0 ||
      Object.keys(report.Changed).length > 0;

    if (!hasChanges) {
      // If no changes, show all properties from the current version
      const currentElement = isVersionsSwapped
        ? findElementPropertiesInData(modelDataStore.data1, elementId)
        : findElementPropertiesInData(modelDataStore.data2, elementId);
      const currentData = isVersionsSwapped
        ? modelDataStore.data1
        : modelDataStore.data2;

      const allProperties = Object.entries(currentElement).map(
        ([prop, value]) => {
          const legendItem = currentData?.Legend?.[prop];
          const propertyName = legendItem
            ? legendItem.Rename || legendItem.Name
            : prop;
          return {
            id: `${elementId}.${prop}`,
            property: propertyName,
            familyType: value,
            isUnchanged: true,
          };
        }
      );

      setAddedElements([]);
      setRemovedElements([]);
      setChangedElements(allProperties);
      return;
    }

    // Process added elements (properties in version 2 but not in version 1)
    const added = Object.entries(report.Added).map(([prop, value]) => {
      const legendItem = modelDataStore.data2?.Legend?.[prop];
      const propertyName = legendItem
        ? legendItem.Rename || legendItem.Name
        : prop;
      return {
        id: `${elementId}.${prop}`,
        property: propertyName,
        value:
          typeof value === "object" ? JSON.stringify(value) : String(value),
      };
    });

    // Process removed elements (properties in version 1 but not in version 2)
    const removed = Object.entries(report.Deleted).map(([prop, value]) => {
      const legendItem = modelDataStore.data1?.Legend?.[prop];
      const propertyName = legendItem
        ? legendItem.Rename || legendItem.Name
        : prop;
      return {
        id: `${elementId}.${prop}`,
        property: propertyName,
        value:
          typeof value === "object" ? JSON.stringify(value) : String(value),
      };
    });

    // Process changed elements
    const changed = Object.entries(report.Changed).map(
      ([prop, { from, to }]) => {
        const legendItem =
          modelDataStore.data2?.Legend?.[prop] ||
          modelDataStore.data1?.Legend?.[prop];
        const propertyName = legendItem
          ? legendItem.Rename || legendItem.Name
          : prop;
        return {
          id: `${elementId}.${prop}`,
          property: propertyName,
          oldValue:
            typeof from === "object" ? JSON.stringify(from) : String(from),
          newValue: typeof to === "object" ? JSON.stringify(to) : String(to),
          isUnchanged: false,
        };
      }
    );

    setAddedElements(isVersionsSwapped ? removed : added);
    setRemovedElements(isVersionsSwapped ? added : removed);
    setChangedElements(changed);
  };

  // Helper function to find an element in model data
  function findElementPropertiesInData(data, elementId) {
    if (!data?.TreeView) return null;

    // First check if element exists in Properties
    if (data.Properties && data.Properties[elementId]) {
      return data.Properties[elementId];
    }

    return null;
  }

  function findElementFamilyAndTypeInData(data, elementId) {
    if (!data?.TreeView) return null;
    // Then check TreeView
    for (const [projectName, project] of Object.entries(data.TreeView)) {
      // Check in regular Levels first
      if (project.Levels) {
        for (const level of Object.values(project.Levels)) {
          for (const category of Object.values(level)) {
            if (typeof category === "object" && category !== null) {
              if (
                category[elementId] &&
                typeof category[elementId] === "object"
              ) {
                return category[elementId];
              }
            }
          }
        }
      }

      // Check in Links
      if (project.Links) {
        for (const [linkName, link] of Object.entries(project.Links)) {
          // Check each level in the link
          for (const [levelName, level] of Object.entries(link)) {
            if (typeof level === "object" && level !== null) {
              for (const category of Object.values(level)) {
                if (typeof category === "object" && category !== null) {
                  if (
                    category[elementId] &&
                    typeof category[elementId] === "object"
                  ) {
                    return category[elementId];
                  }
                }
              }
            }
          }
        }
      }
    }

    return null;
  }

  // Helper function to extract all elements including linked elements
  const extractAllElements = (treeView) => {
    const elements = {};

    if (!treeView) return elements;

    // Process each project
    Object.entries(treeView).forEach(([projectName, project]) => {
      // Extract elements from Levels
      if (project.Levels) {
        Object.values(project.Levels).forEach((level) => {
          Object.values(level).forEach((category) => {
            if (typeof category === "object" && category !== null) {
              Object.entries(category).forEach(([id, element]) => {
                if (element && typeof element === "object") {
                  elements[id] = element;
                }
              });
            }
          });
        });
      }

      // Extract elements from Links
      if (project.Links) {
        Object.entries(project.Links).forEach(([linkName, link]) => {
          Object.entries(link).forEach(([levelName, level]) => {
            if (typeof level === "object" && level !== null) {
              Object.values(level).forEach((category) => {
                if (typeof category === "object" && category !== null) {
                  Object.entries(category).forEach(([id, element]) => {
                    if (element && typeof element === "object") {
                      elements[id] = element;
                    }
                  });
                }
              });
            }
          });
        });
      }
    });

    return elements;
  };

  // Compare elements directly between two versions
  const compareElementsDirectly = (v1Elements, v2Elements) => {
    let addedElements = {};
    let removedElements = {};
    let changedElements = {};

    // Always treat v2 as "new" and v1 as "old" for comparison
    const oldElements = v1Elements;
    const newElements = v2Elements;

    // Find removed elements (in v1 but not in v2)
    Object.entries(oldElements).forEach(([id, element]) => {
      if (!(id in newElements)) {
        removedElements[id] = element;
      }
    });

    // Find added elements (in v2 but not in v1) and changed elements
    Object.entries(newElements).forEach(([id, element]) => {
      if (!(id in oldElements)) {
        addedElements[id] = element;
      } else {
        // Element exists in both, check if Family and Type or any properties are different
        const newType =
          (element && element["Family and Type"]) || "Unknown Type";
        const oldType =
          (oldElements[id] && oldElements[id]["Family and Type"]) ||
          "Unknown Type";

        // Get properties for both versions
        const v1Props = findElementPropertiesInData(modelDataStore.data1, id);
        const v2Props = findElementPropertiesInData(modelDataStore.data2, id);

        let hasPropertyChanges = false;

        // Check if any properties are different
        if (v1Props && v2Props) {
          // Check for changes in existing properties
          Object.keys(v1Props).forEach((propId) => {
            const v1Value = v1Props[propId];
            const v2Value = v2Props[propId];
            if (!areValuesEqual(v1Value, v2Value)) {
              hasPropertyChanges = true;
            }
          });

          // Check for added or removed properties
          Object.keys(v2Props).forEach((propId) => {
            if (!(propId in v1Props)) {
              hasPropertyChanges = true;
            }
          });
        }

        if (newType !== oldType || hasPropertyChanges) {
          changedElements[id] = {
            from: oldElements[id],
            to: element,
          };
        }
      }
    });

    // If versions are swapped, swap the added and removed elements
    if (isVersionsSwapped) {
      const tempAdded = { ...addedElements };
      addedElements = { ...removedElements };
      removedElements = tempAdded;
    }

    return {
      Added: addedElements,
      Deleted: removedElements,
      Changed: changedElements,
    };
  };

  // Helper function to find matching node by ID
  function findMatchingNode(tree, id) {
    for (const node of tree) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findMatchingNode(node.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  // Helper function to compare nodes for equality
  function areNodesEqual(node1, node2) {
    if (node1.type !== node2.type) return false;

    if (node1.type === "element") {
      // First check familyType as before
      if (node1.familyType !== node2.familyType) return false;

      // Only do property comparison for actual element IDs
      if (!node1.id.includes("/")) {
        if (!isModelDataLoaded()) {
          console.warn("Model data not loaded yet");
          return true; // Default to true if we can't compare
        }

        const element1 = findElementFamilyAndTypeInData(
          modelDataStore.data1,
          node1.id
        );
        const element2 = findElementFamilyAndTypeInData(
          modelDataStore.data2,
          node2.id
        );

        const comparison = compareElementPropertiesWithoutProcess(node1.id);
        if (comparison) {
          return (
            Object.keys(comparison.Added).length === 0 &&
            Object.keys(comparison.Deleted).length === 0 &&
            Object.keys(comparison.Changed).length === 0
          );
        }
      }

      return true;
    }

    return node1.name === node2.name;
  }

  // Helper function to update folder status based on children
  function updateFolderStatus(node) {
    // For element nodes, keep their original status
    if (
      node.type === "element" ||
      !node.children ||
      node.children.length === 0
    ) {
      return node.status;
    }

    // For folder nodes, check all children recursively
    const childStatuses = node.children.map((child) =>
      updateFolderStatus(child)
    );

    // Count occurrences of each status
    const statusCounts = {
      added: 0,
      removed: 0,
      changed: 0,
      unchanged: 0,
    };

    childStatuses.forEach((status) => {
      statusCounts[status]++;
    });

    const totalChildren = childStatuses.length;

    // Determine folder status based on children's statuses
    if (statusCounts.added === totalChildren) {
      // All children are added
      node.status = "added";
    } else if (statusCounts.removed === totalChildren) {
      // All children are removed
      node.status = "removed";
    } else if (statusCounts.unchanged === totalChildren) {
      // All children are unchanged
      node.status = "unchanged";
    } else {
      // Mixed statuses - any combination of:
      // - Some added, some unchanged
      // - Some removed, some unchanged
      // - Some changed, some unchanged
      // - Some added, some removed
      // - Some added, some changed
      // - Some removed, some changed
      // - Mix of added, removed, and changed
      // - Mix of all statuses
      node.status = "changed";
    }

    return node.status;
  }

  // Helper function to merge two version trees
  function mergeTreeData(v1Tree, v2Tree) {
    const mergedTree = [];
    const processedNodes = new Set();

    // Process v1 nodes first
    v1Tree.forEach((v1Node) => {
      const v2Node = findMatchingNode(v2Tree, v1Node.id);

      if (v2Node) {
        // Node exists in both versions
        const mergedNode = {
          ...v1Node,
          version: "both",
          // For elements, check equality. For folders, will be updated by updateFolderStatus
          status:
            v1Node.type === "element"
              ? areNodesEqual(v1Node, v2Node)
                ? "unchanged"
                : "changed"
              : "unchanged",
        };

        if (v1Node.children || v2Node.children) {
          mergedNode.children = mergeTreeData(
            v1Node.children || [],
            v2Node.children || []
          );
        }
        mergedTree.push(mergedNode);
        processedNodes.add(v1Node.id);
      } else {
        // Node only in v1
        const status =
          v1Node.type === "element"
            ? isVersionsSwapped
              ? "added"
              : "removed"
            : "unchanged"; // Folders start as unchanged

        mergedTree.push({
          ...v1Node,
          version: "v1",
          status,
          children: v1Node.children
            ? mergeTreeData(v1Node.children, [])
            : undefined,
        });
      }
    });

    // Add remaining v2 nodes (added nodes)
    v2Tree.forEach((v2Node) => {
      if (!processedNodes.has(v2Node.id)) {
        const status =
          v2Node.type === "element"
            ? isVersionsSwapped
              ? "removed"
              : "added"
            : "unchanged"; // Folders start as unchanged

        mergedTree.push({
          ...v2Node,
          version: "v2",
          status,
          children: v2Node.children
            ? mergeTreeData([], v2Node.children)
            : undefined,
        });
      }
    });

    // Update folder statuses based on children
    mergedTree.forEach((node) => updateFolderStatus(node));

    return mergedTree;
  }

  // Helper function to process single version data
  function processVersionData(treeView, version) {
    if (!treeView) {
      console.warn("No TreeView data available for version:", version);
      return [];
    }

    const tree = [];

    Object.entries(treeView).forEach(([projectName, project]) => {
      if (typeof project !== "object" || project === null) {
        return;
      }

      const projectNode = {
        id: `project_${projectName}`,
        name: projectName,
        type: "folder",
        version: version,
        status: "unchanged",
        children: [],
      };

      // Process Levels
      if (project.Levels) {
        const levelsNode = {
          id: `${projectNode.id}/levels`,
          name: "Levels",
          type: "folder",
          version: version,
          status: "unchanged",
          children: [],
        };

        Object.entries(project.Levels).forEach(([levelName, level]) => {
          const levelNode = {
            id: `${levelsNode.id}/level_${levelName}`,
            name: levelName,
            type: "folder",
            version: version,
            status: "unchanged",
            children: [],
          };

          Object.entries(level).forEach(([categoryName, category]) => {
            // Skip RVT Links category
            if (categoryName === "RVT Links") return;

            if (typeof category === "object" && category !== null) {
              const categoryNode = {
                id: `${levelNode.id}/category_${categoryName}`,
                name: categoryName,
                type: "folder",
                version: version,
                status: "unchanged",
                children: [],
              };

              Object.entries(category).forEach(([elementId, element]) => {
                if (element && typeof element === "object") {
                  categoryNode.children.push({
                    id: elementId,
                    name: `${
                      element["Family and Type"] || element.name || "Unknown"
                    } + ${elementId}`,
                    type: "element",
                    version: version,
                    status: "unchanged",
                    fullPath: `${levelsNode.id}/level_${levelName}/category_${categoryName}/element_${elementId}`,
                    familyType:
                      element["Family and Type"] || element.name || "Unknown",
                  });
                }
              });

              if (categoryNode.children.length > 0) {
                levelNode.children.push(categoryNode);
              }
            }
          });

          if (levelNode.children.length > 0) {
            levelsNode.children.push(levelNode);
          }
        });

        if (levelsNode.children.length > 0) {
          projectNode.children.push(levelsNode);
        }
      }

      // Process Links (similar structure as Levels)
      if (project.Links) {
        const linksNode = {
          id: `${projectNode.id}/links`,
          name: "Links",
          type: "folder",
          version: version,
          status: "unchanged",
          children: [],
        };

        Object.entries(project.Links).forEach(([linkName, link]) => {
          if (typeof link === "object" && link !== null) {
            const linkNode = {
              id: `${linksNode.id}/link_${linkName}`,
              name: linkName,
              type: "folder",
              version: version,
              status: "unchanged",
              children: [],
            };

            Object.entries(link).forEach(([levelName, level]) => {
              if (typeof level === "object" && level !== null) {
                const levelNode = {
                  id: `${linkNode.id}/level_${levelName}`,
                  name: levelName,
                  type: "folder",
                  version: version,
                  status: "unchanged",
                  children: [],
                };

                Object.entries(level).forEach(([categoryName, category]) => {
                  // Skip RVT Links category
                  if (categoryName === "RVT Links") return;

                  if (typeof category === "object" && category !== null) {
                    const categoryNode = {
                      id: `${levelNode.id}/category_${categoryName}`,
                      name: categoryName,
                      type: "folder",
                      version: version,
                      status: "unchanged",
                      children: [],
                    };

                    Object.entries(category).forEach(([elementId, element]) => {
                      if (element && typeof element === "object") {
                        categoryNode.children.push({
                          id: elementId,
                          name: `${
                            element["Family and Type"] ||
                            element.name ||
                            "Unknown"
                          } + ${elementId}`,
                          type: "element",
                          version: version,
                          status: "unchanged",
                          fullPath: `${linkNode.id}/level_${levelName}/category_${categoryName}/element_${elementId}`,
                          familyType:
                            element["Family and Type"] ||
                            element.name ||
                            "Unknown",
                        });
                      }
                    });

                    if (categoryNode.children.length > 0) {
                      levelNode.children.push(categoryNode);
                    }
                  }
                });

                if (levelNode.children.length > 0) {
                  linkNode.children.push(levelNode);
                }
              }
            });

            if (linkNode.children.length > 0) {
              linksNode.children.push(linkNode);
            }
          }
        });

        if (linksNode.children.length > 0) {
          projectNode.children.push(linksNode);
        }
      }

      if (projectNode.children.length > 0) {
        tree.push(projectNode);
      }
    });

    return tree;
  }

  return {
    treeData,
    addedElements,
    removedElements,
    changedElements,
    selectedElement,
    loading,
    error,
    compareNodeElements,
    handleElementSelect,
    handleClearSelection,
    setAddedElements,
    setRemovedElements,
    setChangedElements,
  };
}
