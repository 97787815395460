import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./index.css';

const DesktopAppModalWrapper = ({
  title,
  ChildrenCom,
  children,
  state,
  onClose,
  styles,
}) => {
  const { width = 1500, height = 800 } = styles || {};

  const containerEl = useRef(document.createElement("div"));
  const externalWindowRef = useRef(null);

  useEffect(() => {
    externalWindowRef.current = window.open(
      "",
      `DesktopAppModalWrapper-${title}`,
      `width=${width},height=${height}`
    );
    if (externalWindowRef.current) {
      externalWindowRef.current.document.body.appendChild(containerEl.current);
      const linkElement =
        externalWindowRef.current.document.createElement("style");
      linkElement.innerHTML = classes;
      externalWindowRef.current.document.head.appendChild(linkElement);
      externalWindowRef.current.onunload = () => onClose();
    }

    return () => {
      if (externalWindowRef.current) {
        console.log("close");
        externalWindowRef.current.close();
      }
    };
  }, [state]);

  return ReactDOM.createPortal(
    children ? children : <ChildrenCom winRef={externalWindowRef} />,
    containerEl.current
  );
};

export default DesktopAppModalWrapper;
