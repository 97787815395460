import React, { useEffect, useState } from "react";

import DetailTab from "./DetailTab";
import LogTab from "./LogTab";

import "./TabPanel.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./TabPanel.css';
import { Constants } from "../../constant/constants";

const TabPanel = ({
  imageData,
  detailTabData,
  checkedTabData,
  saveUpdatedIssue,
  logTabData,
  updateDetailTabData,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Details",
      content: (
        <DetailTab
          detailTabData={detailTabData}
          checkedTabData={checkedTabData}
          saveUpdatedIssue={saveUpdatedIssue}
          updateDetailTabData={updateDetailTabData}
        ></DetailTab>
      ),
    },
    {
      label: "Activity Logs",
      content: (
        <LogTab
          logTabData={logTabData}
          issueId={detailTabData.id}
          saveUpdatedIssue={saveUpdatedIssue}
          activeTab={activeTab}
          annotationId={detailTabData.id}
        ></LogTab>
      ),
    },
  ];

  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div className="issue-track-tab-header">
        <div className="issue-track-tab-header-name">
          <span>Issue</span>
        </div>
        <div className="issue-track-tab-list">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tabs ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <div className="issue-track-tab-content">{tabs[activeTab].content}</div>
    </>
  );
};

export default TabPanel;
