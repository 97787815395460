import React, { useState, useEffect } from "react";
import { useModelData } from "../../../hooks/useModelData";
import { useModelComparison } from "../../../hooks/useModelComparison";
import ModelComparison from "./ModelComparison";
import TreeView from "./TreeView";
import "../styles/ModelComparison.css";

function ModelComparisonWindow() {
  const [showDiffs, setShowDiffs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showOnlyChecked, setShowOnlyChecked] = useState(true);
  const [selectedNode, setSelectedNode] = useState(null);
  const [checkedNodes, setCheckedNodes] = useState(new Set());
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [isVersionsSwapped, setIsVersionsSwapped] = useState(false);

  const {
    projects,
    models,
    versions,
    selectedProject,
    selectedModel,
    selectedVersions,
    loading,
    error,
    handleProjectChange,
    handleModelChange,
    handleVersionChange,
  } = useModelData();

  // Add model comparison hook with swapped state
  const {
    treeData,
    addedElements,
    removedElements,
    changedElements,
    selectedElement,
    loading: comparisonLoading,
    error: comparisonError,
    compareNodeElements,
    handleElementSelect,
    handleClearSelection,
    setAddedElements,
    setRemovedElements,
    setChangedElements,
  } = useModelComparison(
    selectedVersions.v1,
    selectedVersions.v2,
    isVersionsSwapped
  );

  // Add ESC key handler
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && selectedElement) {
        // Only clear if an element is selected
        handleClearSelection();
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => document.removeEventListener("keydown", handleEscKey);
  }, [handleClearSelection, selectedElement]); // Add selectedElement to dependencies

  // Log version changes
  useEffect(() => {
    console.log("Selected versions changed:", {
      v1: selectedVersions.v1
        ? {
            id: selectedVersions.v1.id,
            version: selectedVersions.v1.version,
            fileName: selectedVersions.v1.fileName,
          }
        : null,
      v2: selectedVersions.v2
        ? {
            id: selectedVersions.v2.id,
            version: selectedVersions.v2.version,
            fileName: selectedVersions.v2.fileName,
          }
        : null,
    });
  }, [selectedVersions.v1, selectedVersions.v2]);

  // Add effect to log showDiffs changes
  useEffect(() => {
    console.log("ModelComparisonWindow: showDiffs changed to:", showDiffs);
  }, [showDiffs]);

  // Add effect to check all nodes when tree data changes
  useEffect(() => {
    if (treeData && treeData.length > 0) {
      const allNodes = new Set();

      // Helper function to collect all node IDs
      const collectNodeIds = (nodes) => {
        nodes.forEach((node) => {
          allNodes.add(node.id);
          if (node.children) {
            collectNodeIds(node.children);
          }
        });
      };

      collectNodeIds(treeData);
      setCheckedNodes(allNodes);
      compareNodeElements(allNodes);
    }
  }, [treeData]);

  // Handle node selection (for leaf nodes only)
  const handleNodeSelect = (node) => {
    if (node.type === "element") {
      setSelectedNode(node);
      // Call the comparison hook's handleElementSelect with the element ID
      handleElementSelect(node.id);
      console.log("Selected element:", node.id);
    }
  };

  // Handle checkbox changes
  const handleNodeCheck = (node, checked, affectedNodes) => {
    // Clear any selected element when changing node selection
    if (selectedElement) {
      handleClearSelection();
    }

    // Let the TreeView handle the checked nodes state update first
    const newCheckedNodes = new Set(checkedNodes);

    // Update all affected nodes (includes parent and children)
    if (affectedNodes) {
      if (checked) {
        affectedNodes.forEach((id) => newCheckedNodes.add(id));
      } else {
        affectedNodes.forEach((id) => newCheckedNodes.delete(id));
      }
    } else {
      // Fallback to single node update if no affected nodes provided
      if (checked) {
        newCheckedNodes.add(node.id);
      } else {
        newCheckedNodes.delete(node.id);
      }
    }

    // Update checked nodes and trigger comparison in one go
    handleCheckedNodesChange(newCheckedNodes);

    // Force a re-render of the ModelComparison component to update highlighting
    // Small delay to ensure state is updated
    setTimeout(() => {
      setShowDiffs((prev) => {
        // If showDiffs is already false, we need to trigger a re-render differently
        if (!prev) {
          // Toggle it true and back to false quickly
          setTimeout(() => setShowDiffs(false), 50);
          return true;
        }
        // If it's true, toggle it off and back on
        setTimeout(() => setShowDiffs(true), 50);
        return false;
      });
    }, 0);
  };

  // Handle expanded nodes changes
  const handleExpandedNodesChange = (newExpandedNodes) => {
    setExpandedNodes(newExpandedNodes);
  };

  // Handle checked nodes changes
  const handleCheckedNodesChange = (newCheckedNodes) => {
    setCheckedNodes(newCheckedNodes);
    // Trigger comparison with all checked nodes
    compareNodeElements(newCheckedNodes);
  };

  // Handle refresh function
  const handleRefresh = async () => {
    if (!selectedModel || !selectedVersions.v1 || !selectedVersions.v2) {
      console.log("Cannot refresh: missing model or version selections");
      return;
    }

    setIsLoading(true);
    try {
      // Clear current selection and state
      handleClearSelection();
      setCheckedNodes(new Set());
      setExpandedNodes(new Set());

      // Store current version IDs
      const v1Id = selectedVersions.v1.id;
      const v2Id = selectedVersions.v2.id;

      // Reset versions to trigger a full reload
      handleVersionChange("", "v1");
      handleVersionChange("", "v2");

      // Small delay to ensure state is cleared
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Re-fetch model data by re-triggering the version change handlers
      await Promise.all([
        handleVersionChange(v1Id, "v1"),
        handleVersionChange(v2Id, "v2"),
      ]);

      // Re-collect all node IDs and trigger comparison
      const allNodes = new Set();
      const collectNodeIds = (nodes) => {
        nodes.forEach((node) => {
          allNodes.add(node.id);
          if (node.children) {
            collectNodeIds(node.children);
          }
        });
      };
      collectNodeIds(treeData);

      // Update checked nodes and trigger comparison
      setCheckedNodes(allNodes);
      compareNodeElements(allNodes);

      // Force a re-render of diffs if they're shown
      if (showDiffs) {
        setShowDiffs(false);
        setTimeout(() => {
          setShowDiffs(true);
        }, 50);
      }
    } catch (error) {
      console.error("Error refreshing models:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle version swap
  const handleVersionsSwap = (newSwapState, elementData) => {
    setIsVersionsSwapped(newSwapState);

    // Handle element swapping if provided
    if (elementData?.shouldSwapElements) {
      // Swap added and removed elements
      setAddedElements(elementData.removedElements || []);
      setRemovedElements(elementData.addedElements || []);
      // Changed elements maintain their array
      setChangedElements(elementData.changedElements || []);
    } else {
      // Re-apply show diffs after a small delay to ensure state is updated
      setTimeout(() => {
        if (showDiffs) {
          const allNodes = new Set();
          const collectNodeIds = (nodes) => {
            nodes.forEach((node) => {
              allNodes.add(node.id);
              if (node.children) {
                collectNodeIds(node.children);
              }
            });
          };
          collectNodeIds(treeData);
          compareNodeElements(allNodes);
        }
      }, 100);
    }
  };

  if (error || comparisonError) {
    return (
      <div className="error-message">
        <h3>Error Loading Data</h3>
        <p>{error || comparisonError}</p>
        <p>Please check the console for more details.</p>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Changed from 100% to 100vh
        width: "100%",
        position: "fixed", // Added fixed positioning
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Left panel - Tree View */}
        <div
          style={{
            width: "25%",
            minWidth: "250px",
            maxWidth: "400px",
            borderRight: "1px solid #ccc",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div className="version-selector">
            <div className="selector-group">
              <label>Project {loading && "(Loading...)"}</label>
              <select
                value={selectedProject}
                onChange={(e) => handleProjectChange(e.target.value)}
                disabled={loading}
              >
                <option value="">Select Project</option>
                {projects &&
                  projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="selector-group">
              <label>Model</label>
              <select
                value={selectedModel}
                onChange={(e) => handleModelChange(e.target.value)}
                disabled={!selectedProject || loading}
              >
                <option value="">Select Model</option>
                {Object.keys(models).map((modelName) => (
                  <option key={modelName} value={modelName}>
                    {modelName}
                  </option>
                ))}
              </select>
            </div>
            <div className="selector-group">
              <label>Version 1</label>
              <select
                value={selectedVersions.v1?.id || ""}
                onChange={(e) => handleVersionChange(e.target.value, "v1")}
                disabled={!selectedModel || loading}
              >
                <option value="">Select Version 1</option>
                {versions.map((version) => (
                  <option key={version.id} value={version.id}>
                    Version {version.version} [
                    {new Date(version.uploadedAt).toLocaleString()}]
                  </option>
                ))}
              </select>
            </div>
            <div className="selector-group">
              <label>Version 2</label>
              <select
                value={selectedVersions.v2?.id || ""}
                onChange={(e) => handleVersionChange(e.target.value, "v2")}
                disabled={!selectedModel || loading}
              >
                <option value="">Select Version 2</option>
                {versions.map((version) => (
                  <option key={version.id} value={version.id}>
                    Version {version.version} [
                    {new Date(version.uploadedAt).toLocaleString()}]
                  </option>
                ))}
              </select>
            </div>
            <div
              className="button-container"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <button
                onClick={handleRefresh}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: isLoading ? "not-allowed" : "pointer",
                  opacity: isLoading ? 0.7 : 1,
                }}
                disabled={isLoading}
              >
                {isLoading ? "Refreshing..." : "Refresh"}
              </button>
              <label className="compare-switch">
                <input
                  type="checkbox"
                  checked={showDiffs}
                  onChange={(e) => {
                    console.log("Toggling showDiffs to:", e.target.checked);
                    setShowDiffs(e.target.checked);
                  }}
                />
                <span className="compare-slider">Show Diffs</span>
              </label>
              <label className="compare-switch">
                <input
                  type="checkbox"
                  checked={showOnlyChecked}
                  onChange={(e) => {
                    setShowOnlyChecked(e.target.checked);
                    // Force a re-render of the ModelComparison component
                    setTimeout(() => {
                      setShowDiffs(false);
                      setTimeout(() => {
                        setShowDiffs(true);
                      }, 50);
                    }, 0);
                  }}
                />
                <span className="compare-slider">Show Checked Only</span>
              </label>
            </div>
          </div>
          <div
            className="tree-view-container"
            style={{
              height: "auto",
              minHeight: "300px",
              overflow: "auto",
              borderTop: "1px solid #ccc",
            }}
          >
            {loading || comparisonLoading ? (
              <div
                style={{ padding: "16px", textAlign: "center", color: "#666" }}
              >
                Loading tree data...
              </div>
            ) : !selectedVersions.v1 || !selectedVersions.v2 ? (
              <div
                style={{ padding: "16px", textAlign: "center", color: "#666" }}
              >
                Select both versions to view the tree
              </div>
            ) : (
              <TreeView
                data={treeData}
                onSelect={handleElementSelect}
                onCheck={handleNodeCheck}
                checkedNodes={checkedNodes}
                onCheckedNodesChange={handleCheckedNodesChange}
                expandedNodes={expandedNodes}
                onExpandedNodesChange={handleExpandedNodesChange}
                selectedElement={selectedElement}
                version={
                  isVersionsSwapped
                    ? { v1: selectedVersions.v2, v2: selectedVersions.v1 }
                    : { v1: selectedVersions.v1, v2: selectedVersions.v2 }
                }
              />
            )}
          </div>
        </div>

        {/* Right panel - Model Comparison */}
        <div
          style={{
            flex: 1,
            minWidth: 0, // Added to prevent flex item from overflowing
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {selectedModel && selectedVersions.v1 && selectedVersions.v2 ? (
            <ModelComparison
              version1={selectedVersions.v1}
              version2={selectedVersions.v2}
              addedElements={addedElements}
              removedElements={removedElements}
              changedElements={changedElements}
              selectedElement={selectedElement}
              onElementSelect={handleElementSelect}
              onClearSelection={handleClearSelection}
              showDiffs={showDiffs}
              showOnlyChecked={showOnlyChecked}
              checkedNodes={checkedNodes}
              onVersionsSwap={handleVersionsSwap}
              onRefresh={handleRefresh}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "1.2rem",
                color: "#666",
              }}
            >
              Please select a model and both versions to compare
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModelComparisonWindow;
