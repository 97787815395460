// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appearence-template-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    color: #BFBFBF;
}

.appearance-template-li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 41px;
    border-bottom: 1px solid #1D1D1D;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
}

.appearance-template-li:hover {
    border-left: 3px solid #FD13B1;
    background-color: #3B3B3B;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
