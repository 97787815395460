import React, { useEffect, useState, Suspense, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ComparisonTables from "./ComparisonTables";
import { getXKTUrl } from "../../../middleware/modelData";
import {
  Viewer,
  XKTLoaderPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk@latest/dist/xeokit-sdk.es.min.js";

// Define colors for different states
const COLORS = {
  ADDED: [0x2e / 255, 0x7d / 255, 0x32 / 255], // #2E7D32 Dark green
  REMOVED: [0xc6 / 255, 0x28 / 255, 0x28 / 255], // #C62828 Dark red
  CHANGED: [0xff / 255, 0xa0 / 255, 0x00 / 255], // #FFA000 Strong amber
  SELECTED: [0x10 / 255, 0x10 / 255, 0xff / 255], // #2196F3 Light blue
};

function ModelComparisonContent({
  modelId = "MSC22032_(3D)",
  version1,
  version2,
  onRefresh,
  addedElements,
  removedElements,
  changedElements,
  selectedElement,
  onElementSelect,
  onClearSelection,
  showDiffs,
  showOnlyChecked,
  checkedNodes,
  onVersionsSwap,
}) {
  const syncViewers = useRef(true);
  const navigate = useNavigate();
  const [syncViewersUI, setSyncViewersUI] = useState(true);
  const [isVersionsSwapped, setIsVersionsSwapped] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showAdded, setShowAdded] = useState(true);
  const [showRemoved, setShowRemoved] = useState(true);
  const [showChanged, setShowChanged] = useState(true);
  const [viewers, setViewers] = useState({
    viewer1: null,
    viewer2: null,
  });

  // Cache for scene object maps
  const sceneObjectMaps = useRef(new WeakMap());

  // Helper function to create object map for a scene
  const createObjectMap = (sceneObjects) => {
    const objectMap = {};
    Object.entries(sceneObjects).forEach(([id, obj]) => {
      // Extract ID from within square brackets if present
      const bracketMatch = id.match(/\[([^\]]+)\]$/);
      const extractedId = bracketMatch ? bracketMatch[1] : id;
      objectMap[extractedId] = obj;
    });
    return objectMap;
  };

  // Helper function to find an object in a viewer by its ID
  const findObjectInViewer = (viewer, elementId) => {
    if (!viewer || !elementId) return null;
    if (!viewer.scene || !viewer.scene.objects) return null;

    // Try to get cached map
    let objectMap = sceneObjectMaps.current.get(viewer.scene);

    // If no cached map exists, create it
    if (!objectMap) {
      objectMap = createObjectMap(viewer.scene.objects);
      sceneObjectMaps.current.set(viewer.scene, objectMap);
    }

    // Handle linked element IDs (format: "XXXXX-YYYY")
    let foundObject = null;
    if (elementId.includes("-")) {
      const [linkPrefix, actualId] = elementId.split("-");
      // Try all possible formats
      const possibleIds = [
        elementId, // Full ID: "XXXXX-YYYY"
        actualId, // Just the part after hyphen: "YYYY"
        elementId.replace("-", ""), // Without hyphen: "XXXXXYYYY"
        `${linkPrefix}${actualId}`, // Concatenated: "XXXXXYYYY"
      ];

      for (const id of possibleIds) {
        if (objectMap[id]) {
          foundObject = objectMap[id];
          break;
        }
      }
    } else {
      // For regular elements, try both the ID and the ID without any hyphens
      foundObject =
        objectMap[elementId] || objectMap[elementId.replace("-", "")];
    }

    return foundObject;
  };

  // Helper function to format version display
  const getVersionDisplay = (version) => {
    if (!version) return "";
    if (version.version) return version.version;
    if (version.versionNumber) return version.versionNumber;
    if (version.uploadedAt) {
      const date = new Date(version.uploadedAt);
      return date.toLocaleString();
    }
    return "Unknown";
  };

  // Function to sync camera positions
  const syncCamera = (srcViewer, dstViewer) => {
    if (!syncViewers.current || isSyncing) return;
    setIsSyncing(true);
    try {
      const camera = srcViewer.camera;
      dstViewer.camera.eye = [...camera.eye];
      dstViewer.camera.look = [...camera.look];
      dstViewer.camera.up = [...camera.up];
    } finally {
      setIsSyncing(false);
    }
  };

  // Apply highlighting based on element status
  const applyHighlighting = () => {
    console.log("==== applyHighlighting START ====");
    if (!viewers.viewer1 || !viewers.viewer2) {
      console.log("Viewers not ready:", {
        viewer1: !!viewers.viewer1,
        viewer2: !!viewers.viewer2,
      });
      return;
    }
    if (!viewers.viewer1.scene || !viewers.viewer2.scene) {
      console.log("Scenes not ready:", {
        scene1: !!viewers.viewer1.scene,
        scene2: !!viewers.viewer2.scene,
      });
      return;
    }
    if (!viewers.viewer1.scene.objects || !viewers.viewer2.scene.objects) {
      console.log("Objects not ready:", {
        objects1: !!viewers.viewer1.scene.objects,
        objects2: !!viewers.viewer2.scene.objects,
      });
      return;
    }

    console.log("All prerequisites met, proceeding with highlighting");
    console.log("Highlighting states:", {
      showDiffs,
      showAdded,
      showRemoved,
      showChanged,
      showOnlyChecked,
    });

    // Get the correct arrays based on swap state
    const effectiveAddedElements = isVersionsSwapped
      ? removedElements
      : addedElements;
    const effectiveRemovedElements = isVersionsSwapped
      ? addedElements
      : removedElements;

    // Single pass to handle visibility and basic properties
    [viewers.viewer1, viewers.viewer2].forEach((viewer) => {
      if (!viewer || !viewer.scene || !viewer.scene.objects) return;
      Object.values(viewer.scene.objects).forEach((object) => {
        if (object) {
          // Extract ID from within square brackets if present
          const bracketMatch = object.id.match(/\[([^\]]+)\]$/);
          const elementId = bracketMatch ? bracketMatch[1] : object.id;

          // Set visibility based on checked state
          object.visible = !showOnlyChecked || checkedNodes.has(elementId);

          if (object.visible) {
            // Reset all properties to default
            object.colorize = null;
            object.ghosted = false;
            object.highlighted = false;
            object.selected = false;

            // Set opacity based on diffs state
            object.opacity = showDiffs ? 0.3 : 1.0;
          }
        }
      });
    });

    // If diffs are disabled, we're done
    if (!showDiffs) {
      return;
    }

    // Helper function to process element visibility and color
    const processElement = (element, object, color) => {
      if (object && object.visible) {
        object.colorize = color;
        object.opacity = 0.8;
      }
    };

    // Process all modified elements
    if (showRemoved && effectiveRemovedElements?.length > 0) {
      effectiveRemovedElements.forEach((element) => {
        const object = findObjectInViewer(viewers.viewer1, element.id);
        processElement(element, object, COLORS.REMOVED);
      });
    }

    if (showChanged && changedElements?.length > 0) {
      changedElements.forEach((element) => {
        const object1 = findObjectInViewer(viewers.viewer1, element.id);
        const object2 = findObjectInViewer(viewers.viewer2, element.id);
        processElement(element, object1, COLORS.CHANGED);
        processElement(element, object2, COLORS.CHANGED);
      });
    }

    if (showAdded && effectiveAddedElements?.length > 0) {
      effectiveAddedElements.forEach((element) => {
        const object = findObjectInViewer(viewers.viewer2, element.id);
        processElement(element, object, COLORS.ADDED);
      });
    }

    // Apply selection highlighting if there's a selected element
    if (selectedElement) {
      [viewers.viewer1, viewers.viewer2].forEach((viewer) => {
        const object = findObjectInViewer(viewer, selectedElement);
        if (object && object.visible) {
          object.colorize = COLORS.SELECTED;
          object.opacity = 1.0;
          object.highlighted = false;
          object.selected = true;
        }
      });
    }
  };

  useEffect(() => {
    if (!version1?.fileName || !version2?.fileName) {
      setError("Version information is incomplete");
      return;
    }

    setIsLoading(true);
    setIsInitialized(false);

    // Initialize viewers
    const viewer1 = new Viewer({
      canvasId: "direct-viewer1-canvas",
      transparent: true,
      backgroundColor: [1, 1, 1],
      preserveDrawingBuffer: true,
      pbr: true,
      sao: true,
    });

    const viewer2 = new Viewer({
      canvasId: "direct-viewer2-canvas",
      transparent: true,
      backgroundColor: [1, 1, 1],
      preserveDrawingBuffer: true,
      pbr: true,
      sao: true,
    });

    // Store viewer references immediately
    setViewers({
      viewer1,
      viewer2,
    });

    // Configure both viewers
    [viewer1, viewer2].forEach((viewer) => {
      viewer.camera.eye = [-2, 1, -6];
      viewer.camera.look = [8.4, 2.724, 20.899];
      viewer.camera.up = [1, 29, 4.039];

      viewer.cameraControl.followPointer = true;
      viewer.cameraControl.panRightClick = false;
      viewer.cameraControl.navMode = "orbit";
      viewer.cameraControl.smartPivot = true;

      viewer.scene.sao.enabled = true;
      viewer.scene.sao.numSamples = 60;
      viewer.scene.sao.kernelRadius = 170;
    });

    // Set up model loading
    const loader1 = new XKTLoaderPlugin(viewer1);
    const loader2 = new XKTLoaderPlugin(viewer2);

    // Load models
    const v1 = isVersionsSwapped ? version2 : version1;
    const v2 = isVersionsSwapped ? version1 : version2;

    let loadedCount = 0;
    const checkLoadingComplete = () => {
      loadedCount++;
      if (loadedCount === 2) {
        setIsInitialized(true);
        setIsLoading(false);
        // Add a small delay to ensure the scene is fully ready
        setTimeout(() => {
          // Fit view to show all objects in both viewers
          [viewer1, viewer2].forEach((viewer) => {
            if (!viewer || !viewer.scene) return;
            const scene = viewer.scene;
            const aabb = scene.getAABB(); // Get axis-aligned bounding box
            const diag = Math.sqrt(
              Math.pow(aabb[3] - aabb[0], 2) +
                Math.pow(aabb[4] - aabb[1], 2) +
                Math.pow(aabb[5] - aabb[2], 2)
            );
            const center = [
              (aabb[0] + aabb[3]) / 2,
              (aabb[1] + aabb[4]) / 2,
              (aabb[2] + aabb[5]) / 2,
            ];

            viewer.camera.eye = [
              center[0] - diag * 0.6,
              center[1] + diag * 0.6,
              center[2] - diag * 0.6,
            ];
            viewer.camera.look = center;
            viewer.camera.up = [0, 1, 0];
          });

          // Ensure we're in a clean state before applying highlighting
          setTimeout(() => {
            console.log("Applying initial highlighting after version change");
            if (showDiffs) {
              applyHighlighting();
            }
          }, 250);
        }, 100);
      }
    };

    // Set up model loaded handlers
    viewer1.scene.on("modelLoaded", () => {
      checkLoadingComplete();
    });

    viewer2.scene.on("modelLoaded", () => {
      checkLoadingComplete();
    });

    loader1.load({
      id: "model1",
      src: `${process.env.REACT_APP_API_URL}/uploads/models/${v1.fileName}`,
      edges: true,
    });

    loader2.load({
      id: "model2",
      src: `${process.env.REACT_APP_API_URL}/uploads/models/${v2.fileName}`,
      edges: true,
    });

    // Set up camera sync
    viewer1.scene.on("tick", () => !isSyncing && syncCamera(viewer1, viewer2));
    viewer2.scene.on("tick", () => !isSyncing && syncCamera(viewer2, viewer1));

    // Cleanup
    return () => {
      // Safely destroy viewer1
      if (viewer1) {
        try {
          // Remove event listeners first
          if (viewer1.scene) {
            viewer1.scene.off("tick");
            viewer1.scene.off("modelLoaded");
          }
          // Clear the scene if it exists
          if (viewer1.scene) {
            viewer1.scene.clear();
          }
          // Only call destroy if the viewer is still valid
          if (!viewer1._destroyed) {
            viewer1.destroy();
          }
        } catch (error) {
          console.warn("Error cleaning up viewer1:", error);
        }
      }

      // Safely destroy viewer2
      if (viewer2) {
        try {
          // Remove event listeners first
          if (viewer2.scene) {
            viewer2.scene.off("tick");
            viewer2.scene.off("modelLoaded");
          }
          // Clear the scene if it exists
          if (viewer2.scene) {
            viewer2.scene.clear();
          }
          // Only call destroy if the viewer is still valid
          if (!viewer2._destroyed) {
            viewer2.destroy();
          }
        } catch (error) {
          console.warn("Error cleaning up viewer2:", error);
        }
      }

      // Reset viewers state
      setViewers({
        viewer1: null,
        viewer2: null,
      });
    };
  }, [
    version1?.fileName,
    version2?.fileName,
    isVersionsSwapped,
    isSyncing,
    showDiffs,
  ]);

  // Effect to reapply highlighting when diff visibility changes
  useEffect(() => {
    if (isInitialized && !isLoading) {
      // Add a small delay to ensure scene is fully ready
      const timer = setTimeout(() => {
        console.log("Reapplying highlighting after state change");
        applyHighlighting();
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [
    showDiffs,
    showAdded,
    showRemoved,
    showChanged,
    selectedElement,
    isInitialized,
    isLoading,
    isVersionsSwapped,
  ]);

  // Function to handle version switching
  const handleVersionSwitch = async () => {
    setIsLoading(true);
    setIsInitialized(false);

    // Clear current models and destroy viewers
    if (viewers.viewer1 && viewers.viewer1.scene) {
      viewers.viewer1.scene.clear();
      viewers.viewer1.destroy();
    }
    if (viewers.viewer2 && viewers.viewer2.scene) {
      viewers.viewer2.scene.clear();
      viewers.viewer2.destroy();
    }

    // Toggle the swap state
    const newSwapState = !isVersionsSwapped;
    setIsVersionsSwapped(newSwapState);

    // Notify parent of the swap
    onVersionsSwap?.(newSwapState);

    // Reinitialize viewers
    const viewer1 = new Viewer({
      canvasId: "direct-viewer1-canvas",
      transparent: true,
      backgroundColor: [1, 1, 1],
      preserveDrawingBuffer: true,
      pbr: true,
      sao: true,
    });

    const viewer2 = new Viewer({
      canvasId: "direct-viewer2-canvas",
      transparent: true,
      backgroundColor: [1, 1, 1],
      preserveDrawingBuffer: true,
      pbr: true,
      sao: true,
    });

    // Store viewer references
    setViewers({
      viewer1,
      viewer2,
    });

    // Configure both viewers
    [viewer1, viewer2].forEach((viewer) => {
      viewer.camera.eye = [-2, 1, -6];
      viewer.camera.look = [8.4, 2.724, 20.899];
      viewer.camera.up = [1, 29, 4.039];

      viewer.cameraControl.followPointer = true;
      viewer.cameraControl.panRightClick = false;
      viewer.cameraControl.navMode = "orbit";
      viewer.cameraControl.smartPivot = true;

      viewer.scene.sao.enabled = true;
      viewer.scene.sao.numSamples = 60;
      viewer.scene.sao.kernelRadius = 170;
    });

    // Set up model loading
    const loader1 = new XKTLoaderPlugin(viewer1);
    const loader2 = new XKTLoaderPlugin(viewer2);

    // Load models based on swapped state
    const v1 = newSwapState ? version2 : version1;
    const v2 = newSwapState ? version1 : version2;

    let loadedCount = 0;
    const checkLoadingComplete = () => {
      loadedCount++;
      if (loadedCount === 2) {
        setIsInitialized(true);
        setIsLoading(false);
        // Add a small delay to ensure the scene is fully ready
        setTimeout(() => {
          // Fit view to show all objects in both viewers
          [viewer1, viewer2].forEach((viewer) => {
            if (!viewer || !viewer.scene) return;
            const scene = viewer.scene;
            const aabb = scene.getAABB(); // Get axis-aligned bounding box
            const diag = Math.sqrt(
              Math.pow(aabb[3] - aabb[0], 2) +
                Math.pow(aabb[4] - aabb[1], 2) +
                Math.pow(aabb[5] - aabb[2], 2)
            );
            const center = [
              (aabb[0] + aabb[3]) / 2,
              (aabb[1] + aabb[4]) / 2,
              (aabb[2] + aabb[5]) / 2,
            ];

            viewer.camera.eye = [
              center[0] - diag * 0.6,
              center[1] + diag * 0.6,
              center[2] - diag * 0.6,
            ];
            viewer.camera.look = center;
            viewer.camera.up = [0, 1, 0];
          });

          // Ensure we're in a clean state before applying highlighting
          setTimeout(() => {
            console.log("Applying initial highlighting after load");
            applyHighlighting();
          }, 250);
        }, 100);
      }
    };

    // Set up model loaded handlers
    viewer1.scene.on("modelLoaded", checkLoadingComplete);
    viewer2.scene.on("modelLoaded", checkLoadingComplete);

    // Load the models
    loader1.load({
      id: "model1",
      src: `${process.env.REACT_APP_API_URL}/uploads/models/${v1.fileName}`,
      edges: true,
    });

    loader2.load({
      id: "model2",
      src: `${process.env.REACT_APP_API_URL}/uploads/models/${v2.fileName}`,
      edges: true,
    });

    // Set up camera sync if enabled
    if (syncViewers.current) {
      viewer1.scene.on(
        "tick",
        () => !isSyncing && syncCamera(viewer1, viewer2)
      );
      viewer2.scene.on(
        "tick",
        () => !isSyncing && syncCamera(viewer2, viewer1)
      );
    }
  };

  if (error) {
    return (
      <div
        style={{
          padding: "20px",
          color: "#721c24",
          backgroundColor: "#f8d7da",
          border: "1px solid #f5c6cb",
          borderRadius: "4px",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        width: "100%",
        minWidth: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "calc(100% - 50px)",
          position: "relative",
          width: "100%",
          minWidth: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "60%",
            minHeight: "400px",
            position: "relative",
            zIndex: 1,
            gap: "0px",
            alignItems: "stretch",
            width: "100%",
            minWidth: "100%",
            overflow: "hidden",
          }}
        >
          {/* Viewer 1 */}
          <div
            style={{
              flex: "1 1 50%",
              position: "relative",
              border: "1px solid #ccc",
              margin: "5px 0 5px 5px",
              borderRadius: "4px",
              overflow: "hidden",
              minWidth: "50%",
              width: "50%",
            }}
          >
            {/* Base Version Label */}
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                background: "rgba(255,255,255,0.8)",
                padding: "4px 8px",
                borderRadius: 3,
                zIndex: 2,
                fontWeight: "bold",
                fontSize: "0.9em",
              }}
            >
              Base Model
            </div>
            <canvas
              id="direct-viewer1-canvas"
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                backgroundColor: "#f5f5f5",
              }}
              tabIndex="0"
            />
            {(!isInitialized || isLoading) && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#666",
                  fontSize: "14px",
                  textAlign: "center",
                  zIndex: 2,
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                {!isInitialized ? "Initializing viewer..." : "Loading model..."}
              </div>
            )}
            <div
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                background: "rgba(255,255,255,0.8)",
                padding: "4px 8px",
                borderRadius: 3,
                zIndex: 2,
              }}
            >
              Version{" "}
              {getVersionDisplay(isVersionsSwapped ? version2 : version1)}
            </div>
          </div>

          {/* Viewer 2 */}
          <div
            style={{
              flex: "1 1 50%",
              position: "relative",
              border: "1px solid #ccc",
              margin: "5px 5px 5px 0",
              borderRadius: "4px",
              overflow: "hidden",
              minWidth: "50%",
              width: "50%",
            }}
          >
            {/* Compared Version Label */}
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                background: "rgba(255,255,255,0.8)",
                padding: "4px 8px",
                borderRadius: 3,
                zIndex: 2,
                fontWeight: "bold",
                fontSize: "0.9em",
              }}
            >
              Compared Model
            </div>
            {/* Controls */}
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                display: "flex",
                gap: "10px",
                alignItems: "center",
                zIndex: 3,
                background: "rgba(255,255,255,0.8)",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "0.9em",
                  cursor: "pointer",
                }}
              >
                <input
                  type="checkbox"
                  checked={syncViewersUI}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    setSyncViewersUI(newValue);
                    syncViewers.current = newValue;
                  }}
                />
                Sync Viewers
              </label>
              <button
                onClick={handleVersionSwitch}
                style={{
                  padding: "4px 8px",
                  fontSize: "0.9em",
                  cursor: "pointer",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                compare-Switch Versions
              </button>
              <div
                onClick={() => navigate(-1)}
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  color: "#ff00ba",
                  cursor: "pointer",
                  margin: "0 10px",
                }}
              >
                X
              </div>
            </div>

            <canvas
              id="direct-viewer2-canvas"
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                backgroundColor: "#f5f5f5",
              }}
              tabIndex="0"
            />
            {(!isInitialized || isLoading) && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#666",
                  fontSize: "14px",
                  textAlign: "center",
                  zIndex: 2,
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                {!isInitialized ? "Initializing viewer..." : "Loading model..."}
              </div>
            )}
            <div
              style={{
                position: "absolute",
                bottom: 10,
                left: 10,
                background: "rgba(255,255,255,0.8)",
                padding: "4px 8px",
                borderRadius: 3,
                zIndex: 2,
              }}
            >
              Version{" "}
              {getVersionDisplay(isVersionsSwapped ? version1 : version2)}
            </div>
          </div>
        </div>

        <div
          style={{
            height: "40%",
            minHeight: "300px",
            borderTop: "1px solid #ccc",
            overflow: "auto",
            zIndex: 2,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            {/* Added Elements Section */}
            <div style={{ flex: 1 }}>
              <label
                className="compare-switch"
                style={{ marginBottom: "10px", display: "block" }}
              >
                <input
                  type="checkbox"
                  checked={showAdded}
                  onChange={(e) => setShowAdded(e.target.checked)}
                  disabled={!showDiffs}
                />
                <span className="compare-slider">Show Added</span>
              </label>
            </div>

            {/* Removed Elements Section */}
            <div style={{ flex: 1 }}>
              <label
                className="compare-switch"
                style={{ marginBottom: "10px", display: "block" }}
              >
                <input
                  type="checkbox"
                  checked={showRemoved}
                  onChange={(e) => setShowRemoved(e.target.checked)}
                  disabled={!showDiffs}
                />
                <span className="compare-slider">Show Removed</span>
              </label>
            </div>

            {/* Changed Elements Section */}
            <div style={{ flex: 1 }}>
              <label
                className="compare-switch"
                style={{ marginBottom: "10px", display: "block" }}
              >
                <input
                  type="checkbox"
                  checked={showChanged}
                  onChange={(e) => setShowChanged(e.target.checked)}
                  disabled={!showDiffs}
                />
                <span className="compare-slider">Show Changed</span>
              </label>
            </div>
          </div>

          <ComparisonTables
            addedElements={
              Array.isArray(addedElements)
                ? isVersionsSwapped
                  ? removedElements
                  : addedElements
                : []
            }
            removedElements={
              Array.isArray(removedElements)
                ? isVersionsSwapped
                  ? addedElements
                  : removedElements
                : []
            }
            changedElements={
              Array.isArray(changedElements) ? changedElements : []
            }
            selectedElement={selectedElement}
            onElementSelect={onElementSelect}
            onClearSelection={onClearSelection}
            isPropertyView={!!selectedElement}
          />
        </div>
      </div>
    </div>
  );
}

function ModelComparison({
  version1,
  version2,
  addedElements,
  removedElements,
  changedElements,
  selectedElement,
  onElementSelect,
  onClearSelection,
  showDiffs,
  showOnlyChecked,
  checkedNodes,
  onVersionsSwap,
  onRefresh,
}) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary
        fallback={
          <ErrorMessage message="Failed to load 3D viewers. Please try refreshing the page." />
        }
      >
        <ModelComparisonContent
          version1={version1}
          version2={version2}
          addedElements={addedElements}
          removedElements={removedElements}
          changedElements={changedElements}
          selectedElement={selectedElement}
          onElementSelect={onElementSelect}
          onClearSelection={onClearSelection}
          showDiffs={showDiffs}
          showOnlyChecked={showOnlyChecked}
          checkedNodes={checkedNodes}
          onVersionsSwap={onVersionsSwap}
          onRefresh={onRefresh}
        />
      </ErrorBoundary>
    </Suspense>
  );
}

function LoadingSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: "1.2rem",
        color: "#666",
      }}
    >
      Loading 3D Viewers...
    </div>
  );
}

function ErrorMessage({ message }) {
  return (
    <div
      style={{
        padding: "20px",
        color: "#721c24",
        backgroundColor: "#f8d7da",
        border: "1px solid #f5c6cb",
        borderRadius: "4px",
        margin: "20px",
      }}
    >
      <h3>Error Loading Viewers</h3>
      <p>{message}</p>
    </div>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ModelComparison;
