// ContextMenu.js
import React, { useEffect } from "react";

import "./ContextMenu.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./ContextMenu.css';
import { Constants } from "../../constant/constants";

const ContextMenu = ({ items, position, onClose }) => {
  useEffect(() => {
    const handleClick = () => {
      onClose();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div
        className="context-menu"
        style={{ top: `${position.y}px`, left: `${position.x}px` }}
      >
        {items.map((item, index) => (
          <div key={index} className="context-menu-item" onClick={item.onClick}>
            {item.label}
          </div>
        ))}
      </div>
    </>
  );
};

export default ContextMenu;
