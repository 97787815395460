// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  color: #bfbfbf;
  box-sizing: border-box;
  z-index: 20;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
