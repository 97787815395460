import { Constants } from "../constant/constants";

const initialState = {
  imagePlaneTabStatus: false,
};
export function ImagePlaneReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_IMAGEPLANE_TAB_STATUS: {
      return { ...state, imagePlaneTabStatus: action.payload };
    }
    default:
      return state;
  }
}
