import React from "react";

function ComparisonTables({
  addedElements,
  removedElements,
  changedElements,
  onElementSelect,
  selectedElement,
  onClearSelection,
  isPropertyView = false,
}) {
  // Add logging to verify incoming data
  console.log("ComparisonTables render:", {
    addedElements: {
      count: addedElements?.length || 0,
      data: addedElements,
    },
    removedElements: {
      count: removedElements?.length || 0,
      data: removedElements,
    },
    changedElements: {
      count: changedElements?.length || 0,
      data: changedElements,
    },
    selectedElement,
    isPropertyView,
  });

  // Determine if we should show the single properties table
  // This happens when an element is selected (isPropertyView) and there are no changes
  // or when all properties are marked as unchanged
  const hasChanges =
    isPropertyView &&
    (addedElements.length > 0 ||
      removedElements.length > 0 ||
      changedElements.some((elem) => !elem.isUnchanged));

  const showPropertiesTable = isPropertyView && !hasChanges;

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "0",
  };

  const thStyle = {
    backgroundColor: "#f5f5f5",
    padding: "8px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
  };

  const clickableIdStyle = {
    color: "#0066cc",
    cursor: "pointer",
    textDecoration: "underline",
  };

  const containerStyle = {
    flex: 1,
    padding: "10px",
    overflow: "auto",
    display: "block", // Changed from flex to block
  };

  const clearButtonStyle = {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "4px 12px",
    cursor: "pointer",
    background: "#2196F3", // Changed to blue
    color: "white",
    border: "none",
    borderRadius: "0", // Remove border radius
    display: isPropertyView ? "block" : "none",
    zIndex: 1000,
    fontSize: "14px",
    height: "33px", // Match the header height
  };

  return (
    <div style={{ display: "flex", height: "100%", position: "relative" }}>
      {/* Clear Selection Button */}
      <button
        style={clearButtonStyle}
        onClick={onClearSelection}
        title="Press ESC or click to return to element comparison"
      >
        ✕ Clear Selection
      </button>

      {/* Elements Table Mode or Property Comparison Mode */}
      <>
        {/* Added Table */}
        <div
          style={{
            ...containerStyle,
            display: showPropertiesTable ? "none" : "block",
            paddingTop: 0,
          }}
        >
          <h3
            style={{
              margin: "0",
              padding: "8px",
              color: "white",
              backgroundColor: "green",
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "calc(100% + 20px)", // Cover the full scrollable width
              boxSizing: "border-box",
              marginLeft: "-10px", // Compensate for container padding
              paddingLeft: "18px", // Add back padding plus a bit extra
            }}
          >
            {isPropertyView ? "Added Properties" : "Added Elements"}
          </h3>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>{isPropertyView ? "Property" : "ID"}</th>
                <th style={thStyle}>
                  {isPropertyView ? "Value" : "Family and Type"}
                </th>
              </tr>
            </thead>
            <tbody>
              {addedElements.map(({ id, familyType, property, value }) => (
                <tr key={id || property}>
                  <td style={tdStyle}>
                    {isPropertyView ? (
                      property
                    ) : (
                      <span
                        style={clickableIdStyle}
                        onClick={() => onElementSelect(id)}
                      >
                        {id}
                      </span>
                    )}
                  </td>
                  <td style={tdStyle}>{value || familyType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Removed Table */}
        <div
          style={{
            ...containerStyle,
            display: showPropertiesTable ? "none" : "block",
            paddingTop: 0,
          }}
        >
          <h3
            style={{
              margin: "0",
              padding: "8px",
              color: "white",
              backgroundColor: "red",
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "calc(100% + 20px)", // Cover the full scrollable width
              boxSizing: "border-box",
              marginLeft: "-10px", // Compensate for container padding
              paddingLeft: "18px", // Add back padding plus a bit extra
            }}
          >
            {isPropertyView ? "Removed Properties" : "Removed Elements"}
          </h3>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>{isPropertyView ? "Property" : "ID"}</th>
                <th style={thStyle}>
                  {isPropertyView ? "Value" : "Family and Type"}
                </th>
              </tr>
            </thead>
            <tbody>
              {removedElements.map(({ id, familyType, property, value }) => (
                <tr key={id || property}>
                  <td style={tdStyle}>
                    {isPropertyView ? (
                      property
                    ) : (
                      <span
                        style={clickableIdStyle}
                        onClick={() => onElementSelect(id)}
                      >
                        {id}
                      </span>
                    )}
                  </td>
                  <td style={tdStyle}>{value || familyType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Changed/Properties Table */}
        <div style={{ ...containerStyle, paddingTop: 0 }}>
          <h3
            style={{
              margin: "0",
              padding: "8px",
              color: "white",
              backgroundColor: showPropertiesTable ? "#2196F3" : "orange",
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "calc(100% + 20px)", // Cover the full scrollable width
              boxSizing: "border-box",
              marginLeft: "-10px", // Compensate for container padding
              paddingLeft: "18px", // Add back padding plus a bit extra
            }}
          >
            {showPropertiesTable
              ? "Properties"
              : isPropertyView
              ? "Changed Properties"
              : "Changed Elements"}
          </h3>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>{isPropertyView ? "Property" : "ID"}</th>
                {showPropertiesTable ? (
                  <th style={thStyle}>Value</th>
                ) : isPropertyView ? (
                  <>
                    <th style={thStyle}>Old Value</th>
                    <th style={thStyle}>New Value</th>
                  </>
                ) : (
                  <th style={thStyle}>Family and Type</th>
                )}
              </tr>
            </thead>
            <tbody>
              {showPropertiesTable
                ? // Show all properties when no changes
                  changedElements.map(({ property, familyType }) => (
                    <tr key={property}>
                      <td style={tdStyle}>{property}</td>
                      <td style={tdStyle}>
                        {typeof familyType === "object"
                          ? JSON.stringify(familyType)
                          : String(familyType)}
                      </td>
                    </tr>
                  ))
                : // Show changed properties/elements
                  changedElements
                    .filter((elem) => !elem.isUnchanged)
                    .map(({ id, property, oldValue, newValue, familyType }) => (
                      <tr key={id || property}>
                        <td style={tdStyle}>
                          {isPropertyView ? (
                            property
                          ) : (
                            <span
                              style={clickableIdStyle}
                              onClick={() => onElementSelect(id)}
                            >
                              {id}
                            </span>
                          )}
                        </td>
                        {isPropertyView ? (
                          <>
                            <td
                              style={{ ...tdStyle, backgroundColor: "#ffebee" }}
                            >
                              {oldValue}
                            </td>
                            <td
                              style={{ ...tdStyle, backgroundColor: "#e8f5e9" }}
                            >
                              {newValue}
                            </td>
                          </>
                        ) : (
                          <td style={tdStyle}>{familyType}</td>
                        )}
                      </tr>
                    ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
}

export default ComparisonTables;
