import React from "react";

import "./LSideBar.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./LSideBar.css';
import { Constants } from "../../constant/constants";

const LSideBar = ({
  isToggle,
  handleToggle,
  header,
  children,
  onContextMenu,
  isRight,
}) => {
  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div
        className={isToggle ? "l-sidebar toggle" : "l-sidebar"}
        onContextMenu={onContextMenu}
        style={isRight ? { width: "282px" } : {}}
      >
        <div className="sidebar-header">
          <div
            className="sidebar-header-name"
            style={
              Constants.DESKTOP_APP ? { "-webkit-app-region": "drag" } : {}
            }
          >
            <span>{header}</span>
          </div>
          <div className="sidebar-header-cancel-btn" onClick={handleToggle}>
            X
          </div>
        </div>
        <div className="seperate-line"></div>
        <div
          className={
            Constants.DESKTOP_APP ? "sidebar-body-app" : "sidebar-body"
          }
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default LSideBar;
