import { Constants } from "../constant/constants";

const initialState = {
  context: null,
  name: null,
  type: null,
  uuid: null,
  id: null,
  modelIds: [],
  modelTypes: [],
  modelNames: [],
  isPropertyOpen: false,
  multiSelectList: [],
  properties: {},
  selectedData: null,
  commonProperty: null,
};
export function PropertyReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.GET_MODEL_PROPERTIES:
      let commonProperties;

      if (action.payload.isCtrlPressed) {
        const temp = Object.entries(state.properties).filter(
          ([key, value]) => action.payload.properties[key] === value
        );
        commonProperties = Object.fromEntries(temp);
      } else commonProperties = action.payload.properties;
      return {
        ...state,
        properties: commonProperties,
      };
    case Constants.HANDLE_PROPERTY: {
      return { ...state, isPropertyOpen: action.payload };
    }
    case Constants.SET_MULTI_SELECT_ENTITY: {
      return { ...state, multiSelectList: action.payload };
    }
    case Constants.SET_SELECT_PROPERTY_DATA: {
      return { ...state, selectedData: action.payload };
    }
    case Constants.SET_COMMON_PROPERTY_DATA: {
      // console.log("commonProperty", action.payload);
      return { ...state, commonProperty: action.payload };
    }
    default:
      return state;
  }
}
