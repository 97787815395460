import React, { useState, useCallback } from "react";

// Helper function to get all descendant node IDs
function getAllDescendantIds(node) {
  const ids = new Set();
  if (!node.children) return ids;

  const stack = [...node.children];
  while (stack.length) {
    const current = stack.pop();
    ids.add(current.id);
    if (current.children) {
      stack.push(...current.children);
    }
  }
  return ids;
}

// Helper function to get initial checked nodes state
function getInitialCheckedNodes(nodes) {
  const checkedNodes = new Set();

  function processNode(node) {
    // Add current node
    checkedNodes.add(node.id);

    // Process children recursively
    if (node.children && node.children.length > 0) {
      node.children.forEach(processNode);
    }
  }

  nodes.forEach(processNode);
  return checkedNodes;
}

// Helper function to get initial expanded nodes state
function getInitialExpandedNodes(nodes) {
  const expandedNodes = new Set();

  function processNode(node) {
    // If node has children, check if any are non-leaf nodes
    if (node.children && node.children.length > 0) {
      const hasNonLeafChildren = node.children.some(
        (child) => child.type !== "element"
      );
      if (hasNonLeafChildren) {
        expandedNodes.add(node.id);
        // Recursively process children
        node.children.forEach(processNode);
      }
    }
  }

  nodes.forEach(processNode);
  return expandedNodes;
}

// Helper function to calculate node's checkbox state
function getNodeCheckState(node, checkedNodes) {
  if (!node.children || node.children.length === 0) {
    return { checked: checkedNodes.has(node.id), indeterminate: false };
  }

  let allChecked = true;
  let allUnchecked = true;
  let hasIndeterminate = false;

  for (const child of node.children) {
    const childState = getNodeCheckState(child, checkedNodes);
    if (childState.indeterminate) {
      hasIndeterminate = true;
      break;
    }
    if (childState.checked) {
      allUnchecked = false;
    } else {
      allChecked = false;
    }
    if (!allChecked && !allUnchecked) {
      break;
    }
  }

  return {
    checked: allChecked,
    indeterminate: hasIndeterminate || (!allChecked && !allUnchecked),
  };
}

// Add color coding helper function
function getNodeColor(status) {
  switch (status) {
    case "added":
      return "#2E7D32"; // Green
    case "removed":
      return "#C62828"; // Red
    case "changed":
      return "#FFA000"; // Orange
    default:
      return "inherit"; // Default color
  }
}

function TreeNode({
  node,
  onSelect,
  onCheck,
  checkedNodes,
  expandedNodes,
  onToggleExpand,
  selectedElement,
  isMultiSelected,
  selectedNodes,
  data,
}) {
  const hasChildren = node.children && node.children.length > 0;
  const isChecked = checkedNodes.has(node.id);
  const isExpanded = expandedNodes.has(node.id);
  const isSelected = node.type === "element" && node.id === selectedElement;
  const isNodeMultiSelected = selectedNodes.has(node.id);

  // Calculate checkbox state
  const checkState = getNodeCheckState(node, checkedNodes);

  // Clean up element name by removing duplicate ID
  const getDisplayName = (name) => {
    if (node.type === "element" && name) {
      // Remove the ID if it appears at the end in parentheses
      const match = name.match(/(.*?)\s*\+\s*(\d+)(?:\(\2\))?$/);
      if (match) {
        return `${match[1]} + ${match[2]}`;
      }
    }
    return name;
  };

  const handleExpand = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onToggleExpand(node.id);
    },
    [node.id, onToggleExpand]
  );

  const handleCheck = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const newChecked = e.target.checked;

      if (selectedNodes.size > 1 && selectedNodes.has(node.id)) {
        // If this node is part of a multi-selection, apply the change to all selected nodes
        const allAffectedIds = new Set();

        // Add all selected nodes and their descendants
        selectedNodes.forEach((selectedId) => {
          allAffectedIds.add(selectedId);
          // Find the node in the tree and get its descendants
          const findNodeAndDescendants = (searchNode) => {
            if (searchNode.id === selectedId) {
              if (searchNode.children) {
                const descendants = getAllDescendantIds(searchNode);
                descendants.forEach((id) => allAffectedIds.add(id));
              }
              return true;
            }
            if (searchNode.children) {
              return searchNode.children.some(findNodeAndDescendants);
            }
            return false;
          };
          // Search from the root level nodes
          data.some(findNodeAndDescendants);
        });

        onCheck(node, newChecked, allAffectedIds);
      } else {
        // Regular single-node checkbox behavior
        const descendantIds = hasChildren
          ? getAllDescendantIds(node)
          : new Set();
        const allIds = new Set(descendantIds);
        allIds.add(node.id);
        onCheck(node, newChecked, allIds);
      }
    },
    [node, hasChildren, onCheck, selectedNodes, data]
  );

  const handleNodeSelect = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (node.type === "element") {
        onSelect(node.id, e.ctrlKey);
      }
    },
    [node, onSelect]
  );

  return (
    <div className="tree-node">
      <div
        className="tree-node-content"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "4px",
          cursor: node.type === "element" ? "pointer" : "default",
          backgroundColor: isNodeMultiSelected
            ? "#e8f4ff"
            : isSelected
            ? "#e3f2fd"
            : "transparent",
          borderRadius: "4px",
          border: isNodeMultiSelected ? "1px solid #90caf9" : "none",
        }}
      >
        {/* Expand/Collapse control */}
        <div style={{ width: "16px", marginRight: "4px" }}>
          {hasChildren && (
            <span onClick={handleExpand} style={{ cursor: "pointer" }}>
              {isExpanded ? "▼" : "▶"}
            </span>
          )}
        </div>

        {/* Checkbox */}
        <label
          style={{
            marginRight: "4px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            checked={checkState.checked}
            ref={(input) => {
              if (input) {
                input.indeterminate = checkState.indeterminate;
              }
            }}
            onChange={handleCheck}
            style={{ cursor: "pointer" }}
          />
        </label>

        {/* Node name with status color */}
        <span
          onClick={handleNodeSelect}
          style={{
            // color: node.type === 'element' ? '#0066cc' : 'inherit',
            //  textDecoration: node.type === 'element' ? 'underline' : 'none'

            color: getNodeColor(node.status),
            textDecoration: node.type === "element" ? "underline" : "none",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {getDisplayName(node.name)}
        </span>
      </div>

      {hasChildren && isExpanded && (
        <div style={{ marginLeft: "20px" }}>
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onSelect={onSelect}
              onCheck={onCheck}
              checkedNodes={checkedNodes}
              expandedNodes={expandedNodes}
              onToggleExpand={onToggleExpand}
              selectedElement={selectedElement}
              isMultiSelected={isMultiSelected}
              selectedNodes={selectedNodes}
              data={data}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function TreeView({
  data,
  onSelect: parentOnSelect,
  onCheck,
  checkedNodes,
  onCheckedNodesChange,
  expandedNodes,
  onExpandedNodesChange,
  selectedElement,
  version,
}) {
  // Add state for tracking multiple selected nodes
  const [selectedNodes, setSelectedNodes] = useState(new Set());

  // Initialize expanded and checked nodes only on first mount
  React.useEffect(() => {
    // Set initial expanded nodes if none are expanded
    if (expandedNodes.size === 0) {
      const initialExpandedNodes = getInitialExpandedNodes(data);
      onExpandedNodesChange(initialExpandedNodes);
    }

    // Set initial checked nodes if none are checked
    if (checkedNodes.size === 0) {
      const initialCheckedNodes = getInitialCheckedNodes(data);
      onCheckedNodesChange(initialCheckedNodes);
    }
  }, []); // Empty dependency array means it only runs once on mount

  const handleSelect = useCallback(
    (nodeId, ctrlPressed) => {
      if (ctrlPressed) {
        // Handle multi-select with Ctrl key
        setSelectedNodes((prev) => {
          const newSelected = new Set(prev);
          if (newSelected.has(nodeId)) {
            newSelected.delete(nodeId);
          } else {
            newSelected.add(nodeId);
          }
          return newSelected;
        });
        // Don't call parentOnSelect when using multi-select
      } else {
        // Single select without Ctrl key
        setSelectedNodes(new Set([nodeId]));
        // Only call parentOnSelect for single selection
        parentOnSelect(nodeId);
      }
    },
    [parentOnSelect]
  );

  const handleCheck = useCallback(
    (node, checked, nodeIds) => {
      const newCheckedNodes = new Set(checkedNodes);
      if (checked) {
        nodeIds.forEach((id) => newCheckedNodes.add(id));
      } else {
        nodeIds.forEach((id) => newCheckedNodes.delete(id));
      }
      onCheckedNodesChange(newCheckedNodes);
      onCheck(node, checked, nodeIds);
    },
    [checkedNodes, onCheckedNodesChange, onCheck]
  );

  const handleToggleExpand = useCallback(
    (nodeId) => {
      const newExpandedNodes = new Set(expandedNodes);
      if (newExpandedNodes.has(nodeId)) {
        newExpandedNodes.delete(nodeId);
      } else {
        newExpandedNodes.add(nodeId);
      }
      onExpandedNodesChange(newExpandedNodes);
    },
    [expandedNodes, onExpandedNodesChange]
  );

  if (!data || data.length === 0) {
    return (
      <div
        style={{
          padding: "16px",
          color: "#666",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        No data available
      </div>
    );
  }

  return (
    <div className="tree-view">
      {/* Version header with simplified format */}
      {version?.v2 && version?.v1 && (
        <div
          style={{
            padding: "8px 12px",
            backgroundColor: "#f8f9fa",
            borderBottom: "1px solid #dee2e6",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#495057",
          }}
        >
          Version {version.v2.version} with {version.v1.version} merged
        </div>
      )}
      {data.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          onSelect={handleSelect}
          onCheck={handleCheck}
          checkedNodes={checkedNodes}
          expandedNodes={expandedNodes}
          onToggleExpand={handleToggleExpand}
          selectedElement={selectedElement}
          isMultiSelected={selectedNodes.has(node.id)}
          selectedNodes={selectedNodes}
          data={data}
        />
      ))}
    </div>
  );
}

export default TreeView;
