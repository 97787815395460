import React, { useState, useEffect, useMemo } from "react";
import { CustomToast } from "../../utlity/toastify";
import { connect } from "react-redux";

import Modal from "../../components/Modal/Modal";
import Dropdown from "../../components/DropDown/Dropdown";
import LSideBar from "../../components/LSideBar/LSideBar";
import { Button, CheckBox } from "../../components/Button/Button";
import { IconButton, CustomDate } from "../../components/Button/Button";
import Canvas from "../../components/Canvas/CustomCanvas";

import "./ClashDetectionTab.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./ClashDetectionTab.css';
import { Constants } from "../../constant/constants";

import {
  setClashDetectionTabStatusAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post, Patch } from "../../axios/axios";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";

const ClashDetectionTab = (props) => {
  const TYPES = ["Clash", "Clearance", "Double ups"];
  const GROUPING = ["No Grouping", "Group By Level", "Group by Grid"];
  const VIEWISOLATION = ["Hide other models", "Xray other models"];
  const STATUS = ["Unassigned", "Ignore", "Create Issue"];

  const [options, setOptions] = useState({
    stampOption: ["1AH", "1AV"],
    statusOption: ["Open", "Solved", "Closed", "InProgress"],
    typeOption: ["Standard Issue", "Other Issue"],
    assigneeOption: [
      { name: "Alexander Salna", company: "Northrop Consulting Engineers" },
      { name: "Adrian Wilkins", company: "Dina Group" },
    ],
  });
  const [open, setOpen] = useState(false);

  const [clashList, setClashList] = useState([]);

  const [isTestOpen, setIsTestOpen] = useState(false);
  const [openedTestId, setOpenedTestId] = useState(null);
  const [openedTest, setOpenedTest] = useState([]);

  const [clashName, setClashName] = useState("");
  const [clashType, setClashType] = useState(TYPES[0]);
  const [tolerance, setTolerance] = useState("");
  const [clashGroup, setClashGroup] = useState(GROUPING[0]);
  const [clashViewIsolation, setClashViewIsolation] = useState(
    VIEWISOLATION[0]
  );

  const [prevOpenedTest, setPrevOpenedTest] = useState({});

  const [markupImage, setMarkupImage] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [issueModal, setIssueModal] = useState({
    isOpen: false,
    height: `calc(80vh)`,
    title: "",
    stamp: options.stampOption[0],
    status: options.statusOption[0],
    type: options.typeOption[0],
    assignee: [options.assigneeOption[0]],
    dueDate: "",
    startDate: new Date().toLocaleDateString("en-CA"),
    // startDate: "2025-02-07",
    comment: "",
    inputTitleError: null,
    inputDueDateError: null,
    aabb1: {},
    aabb2: {},
    parentId: null,
    childId: null,
  });

  const [showList, setShowList] = useState([]);

  const [searchSet1, setSearchSet1] = useState({});
  const [searchSet2, setSearchSet2] = useState({});

  const [searchSet1Flag, setSearchSet1Flag] = useState();
  const [searchSet2Flag, setSearchSet2Flag] = useState();

  const [createAnnotation, setCreateAnnotation] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isSelectedReview, setIsSelectedReview] = useState(false);

  const [showClashDetectionList, setShowClashDetectionList] = useState([]);

  const [showClashDetectionArray1, setShowClashDetectionArray1] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllSearches = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response?.data) {
      console.log("response", response);
      setShowList(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  const getAllClashTests = async (pg = page) => {
    const url = BaseURL(
      `clashes/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response?.data) {
      setClashList(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };
  useEffect(() => {
    if (props.setClashDetectionTabStatus) getAllClashTests();
  }, [props.setClashDetectionTabStatus]);

  useEffect(() => {
    if (open) getAllSearches();
  }, [open]);

  // const [tempElement, setTempElement] = useState();
  useEffect(() => {
    // setShowList(props.searchSaveList);
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.searchSaveListFlag]);

  useEffect(() => {
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.clashDetectionListFlag]);

  useEffect(() => {
    if (props.viewer === null) return;

    showClashDetectionArray1.map((element, i) => {
      const entity = props.viewer.scene.objects[element.id];
      if (entity !== undefined) entity.selected = false;
    });

    selectedRows.map((element, i) => {
      const entity = props.viewer.scene.objects[element];
      if (entity !== undefined) entity.selected = true;
    });
  }, [selectedRows]);

  useEffect(() => {
    let flag = false;
    showClashDetectionArray1.map((element, i) => {
      if (element.id === props.addToClashDetectionFromView) flag = true;
    });
    if (flag) handleCheckboxChange(props.addToClashDetectionFromView);
  }, [props.selectContextMenuBtnStatus]);

  const handleOpen = () => {
    setOpen(true);
  };

  const getSearchSet1 = (e) => {
    console.log(e.target.textContent);
    console.log(props.searchSaveList);
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        console.log(element);
        setSearchSet1({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet1Flag(element.name);
      }
    });
  };
  const getSearchSet2 = (e) => {
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        setSearchSet2({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet2Flag(element.name);
      }
    });
  };

  const createClashTest = async () => {
    const url = BaseURL(`clashes`);

    const params = {
      name: clashName,
      type: clashType,
      tolerance: tolerance,
      clashGroup: clashGroup,
      clashViewIsolation: clashViewIsolation,
      compare1: searchSet1,
      compare2: searchSet2,
      projects: [`${props.projectName}`],
    };

    console.log("params", params);
    handleLoadingAction(true);

    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Clash added successfully", "affirmative");
    }

    handleLoadingAction(false);
    getAllClashTests();
    setOpen(false);
  };

  const closeTab = () => {
    props.setClashDetectionTabStatusAction(false);
  };

  useEffect(() => {
    // Early return if viewer is not available
    if (!props.viewer) return;

    // Skip processing if no rows are selected
    if (!selectedRows.length) return;

    // Initialize bounds with first valid entity to avoid Infinity comparisons
    const firstEntity = props.viewer.scene.objects[selectedRows[0]];
    if (!firstEntity) return;

    const bounds = {
      min: [...firstEntity.aabb.slice(0, 3)],
      max: [...firstEntity.aabb.slice(3, 6)],
    };

    // Update bounds for remaining entities
    selectedRows.slice(1).forEach((id) => {
      const entity = props.viewer.scene.objects[id];
      if (!entity?.aabb) return;

      const [x1, y1, z1, x2, y2, z2] = entity.aabb;
      bounds.min[0] = Math.min(bounds.min[0], x1);
      bounds.min[1] = Math.min(bounds.min[1], y1);
      bounds.min[2] = Math.min(bounds.min[2], z1);
      bounds.max[0] = Math.max(bounds.max[0], x2);
      bounds.max[1] = Math.max(bounds.max[1], y2);
      bounds.max[2] = Math.max(bounds.max[2], z2);
    });

    // Camera flight configuration
    const cameraConfig = {
      aabb: [...bounds.min, ...bounds.max],
      duration: 0.5,
    };

    // Handle camera flight and highlighting
    const handleFlightComplete = () => {
      const highlightTimeout = setTimeout(() => {
        props.viewer.scene.setObjectsHighlighted(
          props.viewer.scene.highlightedObjectIds,
          false
        );
      }, 500);

      // Cleanup timeout if component unmounts during flight
      return () => clearTimeout(highlightTimeout);
    };

    props.viewer.cameraFlight.flyTo(cameraConfig, handleFlightComplete);
  }, [selectedRows]);

  const handleCheckboxChange = (e, elements, type) => {
    const { checked } = e.target;
    const clashGroup = clashList[openedTestId]?.clashGroup;

    // Helper functions to improve readability and reduce code duplication
    const toggleSceneObjects = (entity1Id, entity2Id, isSelected) => {
      const entity1 = props.viewer.scene.objects[entity1Id];
      const entity2 = props.viewer.scene.objects[entity2Id];

      if (entity1 && entity2) {
        entity1.selected = isSelected;
        entity2.selected = isSelected;
      }
    };

    const updateSelectedRows = (elementId, shouldAdd) => {
      setSelectedRows((prevSelectedRows) => {
        if (shouldAdd) {
          return prevSelectedRows.includes(elementId)
            ? prevSelectedRows
            : [...prevSelectedRows, elementId];
        }
        return prevSelectedRows.filter((rowId) => rowId !== elementId);
      });
    };

    // Handle different grouping types
    switch (clashGroup) {
      case "No Grouping": {
        const entityId = elements.entity1Id._id;
        updateSelectedRows(entityId, checked);
        toggleSceneObjects(
          elements.entity1Id.id,
          elements.entity2Id.id,
          checked
        );
        break;
      }

      case "Group By Level": {
        if (type === "parent") {
          setSelectedRows((prevSelectedRows) => {
            const updatedSet = new Set(prevSelectedRows);

            // Toggle parent
            if (updatedSet.has(elements)) {
              updatedSet.delete(elements);
            } else {
              updatedSet.add(elements);
            }

            // Update children
            const children = openedTest[elements]?.children || [];
            children.forEach((child) => {
              if (checked) {
                updatedSet.add(child.entity1Id.id);
              } else {
                updatedSet.delete(child.entity1Id.id);
              }
            });

            return Array.from(updatedSet);
          });

          // Update scene objects for all children
          openedTest[elements]?.children?.forEach((child) => {
            toggleSceneObjects(child.entity1Id.id, child.entity2Id.id, checked);
          });
        } else {
          // Handle child selection
          updateSelectedRows(elements.entity1Id.id, checked);
          toggleSceneObjects(
            elements.entity1Id.id,
            elements.entity2Id.id,
            checked
          );
        }
        break;
      }

      case "Group by Grid": {
        if (type === "parent") {
          setSelectedRows((prevSelectedRows) => {
            const updatedSet = new Set(prevSelectedRows);
            const childId = openedTest[elements]?.childData?.entity1Id?.id;

            // Toggle parent and child
            if (updatedSet.has(elements)) {
              updatedSet.delete(elements);
              updatedSet.delete(childId);
            } else {
              updatedSet.add(elements);
              if (childId) updatedSet.add(childId);
            }

            return Array.from(updatedSet);
          });

          // Update scene objects for child
          const childData = openedTest[elements]?.childData;
          if (childData) {
            toggleSceneObjects(
              childData.entity1Id.id,
              childData.entity2Id.id,
              checked
            );
          }
        }
        break;
      }
      default:
        return;
    }
  };

  const getModelPropertiesFromJson = (compareValue1, compareValue2) => {
    if (!props.viewer || !props.metaData) return;

    const metaData = props.metaData;

    console.log("compare1", compareValue1);
    console.log("compareValue2", compareValue2);
    const keyNumber = Object.entries(metaData.Legend).find(
      ([, value]) => value.Name === compareValue1
    )?.[0];

    console.log("keyNumber", keyNumber);
    if (!keyNumber) return;

    const resultObject = Object.entries(metaData.Properties)
      .filter(([, items]) => items[keyNumber] === compareValue2)
      .map(([property]) => property);

    return resultObject;
  };

  const getProperties = (propertyName, propertyValue) => {
    const returnVal = [];

    const resultObject = getModelPropertiesFromJson(
      propertyName,
      propertyValue
    );

    console.log("resultOb", resultObject);
    if (!resultObject) return;
    const { objects } = props.viewer.scene;

    Object.entries(objects).forEach(([key, object]) => {
      const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
      if (resultObject.includes(idMatch)) {
        returnVal.push(object);
      }
    });

    return returnVal;
  };

  function intersect(aabb1, aabb2, tolerance) {
    const offset = tolerance / 0.3;
    return (
      aabb1[0] <= aabb2[3] - offset &&
      aabb1[3] >= aabb2[0] + offset && // X-axis
      aabb1[1] <= aabb2[4] - offset &&
      aabb1[4] >= aabb2[1] + offset && // Y-axis
      aabb1[2] <= aabb2[5] - offset &&
      aabb1[5] >= aabb2[2] + offset // Z-axis
    );
    // return (
    //   aabb1[0] <= aabb2[3] &&
    //   aabb1[3] >= aabb2[0] && // X-axis
    //   aabb1[1] <= aabb2[4] &&
    //   aabb1[4] >= aabb2[1] && // Y-axis
    //   aabb1[2] <= aabb2[5] &&
    //   aabb1[5] >= aabb2[2] // Z-axis
    // );
  }

  function isClearance(aabb1, aabb2, clearance) {
    // const dx = Math.max(aabb2[0] - aabb1[3], aabb1[0] - aabb2[3], 0); // Distance along X
    // const dy = Math.max(aabb2[1] - aabb1[4], aabb1[1] - aabb2[4], 0); // Distance along Y
    // const dz = Math.max(aabb2[2] - aabb1[5], aabb1[2] - aabb2[5], 0); // Distance along Z

    // const distance = Math.sqrt(dx * dx + dy * dy + dz * dz); // Euclidean distance between objects
    const closestPointA = [
      Math.max(aabb1[0], Math.min(aabb2[0], aabb1[3])),
      Math.max(aabb1[1], Math.min(aabb2[1], aabb1[4])),
      Math.max(aabb1[2], Math.min(aabb2[2], aabb1[5])),
    ];
    const closestPointB = [
      Math.max(aabb2[0], Math.min(aabb1[0], aabb2[3])),
      Math.max(aabb2[1], Math.min(aabb1[1], aabb2[4])),
      Math.max(aabb2[2], Math.min(aabb1[2], aabb2[5])),
    ];

    const distance = Math.sqrt(
      Math.pow(closestPointB[0] - closestPointA[0], 2) +
        Math.pow(closestPointB[1] - closestPointA[1], 2) +
        Math.pow(closestPointB[2] - closestPointA[2], 2)
    );
    console.log("distance", distance);
    console.log("clearance", clearance);
    console.log("clearance", clearance / 0.3);
    return distance < clearance / 0.3;
  }

  function isDoubleUp(aabb1, aabb2) {
    // Check if aabb1 is inside aabb2
    const isAABB1InsideAABB2 =
      aabb1[0] >= aabb2[0] &&
      aabb1[3] <= aabb2[3] && // X-axis
      aabb1[1] >= aabb2[1] &&
      aabb1[4] <= aabb2[4] && // Y-axis
      aabb1[2] >= aabb2[2] &&
      aabb1[5] <= aabb2[5]; // Z-axis

    // Check if aabb2 is inside aabb1
    const isAABB2InsideAABB1 =
      aabb2[0] >= aabb1[0] &&
      aabb2[3] <= aabb1[3] && // X-axis
      aabb2[1] >= aabb1[1] &&
      aabb2[4] <= aabb1[4] && // Y-axis
      aabb2[2] >= aabb1[2] &&
      aabb2[5] <= aabb1[5]; // Z-axis

    // Return true if either is fully inside the other
    return isAABB1InsideAABB2 || isAABB2InsideAABB1;
  }
  const onOpenClashTest = (id) => {
    setIsTestOpen(true);
    setOpenedTestId(id);
    setOpenedTest([]);
    console.log("clashList[id].testResult", clashList[id].testResult);
    setPrevOpenedTest(clashList[id].testResult);
  };

  function crossProduct(a, b) {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ];
  }

  // Constants
  const CLASH_TYPES = {
    CLASH: "Clash",
    DOUBLE_UPS: "Double ups",
    CLEARANCE: "Clearance",
  };

  const GROUP_TYPES = {
    NO_GROUPING: "No Grouping",
    GROUP_BY_LEVEL: "Group By Level",
    GROUP_BY_GRID: "Group by Grid",
  };

  const DEFAULT_STATUS = {
    UNASSIGNED: "Unassigned",
    NOT_REVIEWED: "Not reviewed",
  };

  const findClashingEntities = (element1, element2, clashType, tolerance) => {
    const isClashing = {
      [CLASH_TYPES.CLASH]: () =>
        intersect(element1.aabb, element2.aabb, tolerance),
      [CLASH_TYPES.DOUBLE_UPS]: () => isDoubleUp(element1.aabb, element2.aabb),
      [CLASH_TYPES.CLEARANCE]: () =>
        isClearance(element1.aabb, element2.aabb, tolerance),
    };

    if (isClashing[clashType]()) {
      element1.colorize = [1.0, 0.0, 0.0];
      element2.colorize = [0.0, 0.0, 1.0];
      return true;
    }
    return false;
  };

  const extractFamilyProperty = (element, MetaData) => {
    const idMatch = element.id.match(/\[([\d-]+)\]/);
    const propertyId = idMatch?.[1];

    if (propertyId && MetaData.Properties[propertyId]) {
      const propertyData = MetaData.Properties[propertyId];
      for (const [key, value] of Object.entries(MetaData.Legend)) {
        if (value.Name === "Family") {
          return propertyData[key];
        }
      }
    }
    return null;
  };

  const onClickRun = () => {
    const { viewer, metaData: MetaData } = props;
    const { scene } = viewer;
    const id = openedTestId;
    const currentTest = clashList[id];

    // Reset x-rayed objects
    scene.setObjectsXRayed(scene.xrayedObjectIds, false);

    // Get search sets
    const searchSet1 = currentTest.compare1;
    const searchSet2 = currentTest.compare2;

    // Get properties for both sets
    const value1 = getProperties(searchSet1.name, searchSet1.value);
    const value2 = getProperties(searchSet2.name, searchSet2.value);

    // Find clashing entities
    const clashEntities1 = [];
    const clashEntities2 = [];
    const tempXray = [];

    value1.forEach((element1) => {
      value2.forEach((element2) => {
        if (
          findClashingEntities(
            element1,
            element2,
            currentTest.type,
            currentTest.tolerance
          )
        ) {
          clashEntities1.push(element1);
          clashEntities2.push(element2);
          tempXray.push(element1, element2);
        }
      });
    });

    if (clashEntities1.length === 0) {
      alert("There is no collision");
      return;
    }

    // Update scene visibility
    scene.setObjectsVisible(scene.objectIds, false);
    tempXray.forEach((entity) => {
      entity.visible = true;
    });

    // Extract entity properties
    const entity1Property = clashEntities1.map((entity) =>
      extractFamilyProperty(entity, MetaData)
    );
    const entity2Property = clashEntities2.map((entity) =>
      extractFamilyProperty(entity, MetaData)
    );

    // Process based on grouping type
    let processedData;

    if (currentTest.clashGroup === GROUP_TYPES.NO_GROUPING) {
      processedData = entity1Property.map((element, index) => ({
        entity1: element,
        entity2: entity2Property[index],
        entity1Id: clashEntities1[index],
        entity2Id: clashEntities2[index],
        new: !prevOpenedTest.entities.some(
          (prev) =>
            prev.entity1 === clashEntities1[index].id &&
            prev.entity2 === clashEntities2[index].id
        ),
        total: prevOpenedTest.total + index + 1,
        open: true,
        status: DEFAULT_STATUS.UNASSIGNED,
      }));
    } else if (currentTest.clashGroup === GROUP_TYPES.GROUP_BY_LEVEL) {
      const levels = MetaData.ProjectInfo.Main.Levels;
      const sortedLevelEntries = Object.entries(levels).sort(
        ([, dataA], [, dataB]) => {
          return (
            parseFloat(dataA.Elevation || 0) - parseFloat(dataB.Elevation || 0)
          );
        }
      );

      // Initialize level data
      processedData = sortedLevelEntries.map(([, level]) => ({
        levelName: level.Name,
        new: 0,
        open: 0,
        status: DEFAULT_STATUS.NOT_REVIEWED,
        children: [],
      }));

      // Calculate level points
      const levelPoints = sortedLevelEntries.map(([, level]) => {
        const minPoint = level.Positionmin.split(",").map(Number);
        const maxPoint = level.Positionmax.split(",").map(Number);
        return (minPoint[2] + maxPoint[2]) / 2;
      });

      // Group entities by level
      clashEntities1.forEach((element, i) => {
        const position = (element.aabb[1] + element.aabb[4]) / 2;

        levelPoints.forEach((levelPoint, index) => {
          if (
            position < levelPoint &&
            position > (levelPoints[index - 1] || -Infinity)
          ) {
            const prevEntity = prevOpenedTest.entities.find(
              (prev) =>
                prev.entity1 === element.id &&
                prev.entity2 === clashEntities2[i].id
            );

            const childData = {
              entity1: entity1Property[i],
              entity2: entity2Property[i],
              entity1Id: element,
              entity2Id: clashEntities2[i],
              new: !prevEntity,
              open: prevEntity ? prevEntity.open : true,
              status: prevEntity?.status || DEFAULT_STATUS.UNASSIGNED,
            };

            if (!prevEntity) {
              processedData[index].new += 1;
            }
            if (childData.open) {
              processedData[index].open += 1;
            }
            processedData[index].children.push(childData);
          }
        });
      });

      // Add total numbers
      let currentTotal = prevOpenedTest.total;
      processedData.forEach((levelData, index) => {
        levelData.total = currentTotal + 1;
        if (levelData.children) {
          levelData.children.forEach((child, childIndex) => {
            child.total = levelData.total + childIndex + 1;
            currentTotal = child.total;
          });
        } else {
          currentTotal = levelData.total + 1;
        }
      });
    } else if (currentTest.clashGroup === GROUP_TYPES.GROUP_BY_GRID) {
      const grids = MetaData.ProjectInfo.Main.Grids;
      const { xAxiosPoints, zAxiosPoints } = processGridPoints(grids);
      processedData = processGridGrouping(
        clashEntities1,
        clashEntities2,
        entity1Property,
        entity2Property,
        xAxiosPoints,
        zAxiosPoints,
        prevOpenedTest
      );
    }

    setOpenedTest(processedData);
  };

  // Grid-specific helper functions
  const processGridPoints = (grids) => {
    const xAxiosPoints = [];
    const zAxiosPoints = [];

    Object.entries(grids)
      .sort(([, a], [, b]) => a.Name.localeCompare(b.Name))
      .forEach(([, grid]) => {
        const [end1X, end1Y, end1Z] = grid.End1.split(",").map(Number);
        const [end2X, end2Y, end2Z] = grid.End2.split(",").map(Number);

        const point1 = [-end1X, end1Z, end1Y];
        const point2 = [-end2X, end2Z, end2Y];

        const baseVector = [
          point2[0] - point1[0],
          point2[1] - point1[1],
          point2[2] - point1[2],
        ];

        const length = Math.sqrt(
          baseVector.reduce((sum, component) => sum + component * component, 0)
        );

        const rightDirection = baseVector.map(
          (component) => component / length
        );
        const up = [0, 1, 0];
        const rawDir = crossProduct(rightDirection, up);

        if (rawDir[0] === -1 && rawDir[2] === 0) {
          xAxiosPoints.push({
            xPos: (point1[0] + point2[0]) / 2,
            gridName: grid.Name,
          });
        } else if (rawDir[0] === 0 && rawDir[2] === 1) {
          zAxiosPoints.push({
            zPos: (point1[2] + point2[2]) / 2,
            gridName: grid.Name,
          });
        }
      });

    return { xAxiosPoints, zAxiosPoints };
  };

  const processGridGrouping = (
    clashEntities1,
    clashEntities2,
    entity1Property,
    entity2Property,
    xAxiosPoints,
    zAxiosPoints,
    prevOpenedTest
  ) => {
    const result = [];
    let currentTotal = prevOpenedTest.total;

    clashEntities1.forEach((element, i) => {
      const aabb1 = element.aabb;
      const aabb2 = clashEntities2[i].aabb;
      const position = Math.min(aabb1[2], aabb2[2]);

      zAxiosPoints.forEach((zAxiosPoint, index) => {
        if (
          position > zAxiosPoint.zPos &&
          position < (zAxiosPoints[index - 1]?.zPos ?? Infinity)
        ) {
          const prevEntity = prevOpenedTest.entities.find(
            (prev) =>
              prev.entity1 === element.id &&
              prev.entity2 === clashEntities2[i].id
          );

          const childData = {
            entity1: entity1Property[i],
            entity2: entity2Property[i],
            entity1Id: element,
            entity2Id: clashEntities2[i],
            new: !prevEntity,
            open: prevEntity ? prevEntity.open : true,
            status: prevEntity?.status || DEFAULT_STATUS.UNASSIGNED,
          };

          const gridEntry = {
            childData: [childData],
            gridZ: zAxiosPoint.gridName,
            gridXmin: null,
            gridXmax: null,
            new: childData.new ? 1 : 0,
            open: childData.open ? 1 : 0,
            status: DEFAULT_STATUS.NOT_REVIEWED,
          };

          // Process X grid positions
          const min = Math.min(element.aabb[0], clashEntities2[i].aabb[0]);
          const max = Math.max(element.aabb[3], clashEntities2[i].aabb[3]);

          xAxiosPoints.forEach((point, index) => {
            const prevPoint = xAxiosPoints[index - 1];

            if (index === 0) {
              if (min > point.xPos) gridEntry.gridXmin = point.gridName;
              if (max > point.xPos) gridEntry.gridXmax = "0";
            } else if (index === xAxiosPoints.length - 1) {
              gridEntry.gridXmin =
                min < point.xPos
                  ? "other"
                  : prevPoint && min < prevPoint.xPos
                  ? point.gridName
                  : gridEntry.gridXmin;
              gridEntry.gridXmax =
                max < point.xPos
                  ? point.gridName
                  : prevPoint && max < prevPoint.xPos
                  ? prevPoint.gridName
                  : gridEntry.gridXmax;
            } else {
              if (prevPoint && min > point.xPos && min < prevPoint.xPos)
                gridEntry.gridXmin = point.gridName;
              if (prevPoint && max > point.xPos && max < prevPoint.xPos)
                gridEntry.gridXmax = prevPoint.gridName;
            }
          });

          // Add totals
          if (result.length === 0) {
            gridEntry.total = currentTotal + 1;
            gridEntry.childData.total = gridEntry.total + 1;
            currentTotal = gridEntry.childData.total;
          } else {
            gridEntry.total = currentTotal + 1;
            gridEntry.childData.total = gridEntry.total + 1;
            currentTotal = gridEntry.childData.total;
          }

          result.push(gridEntry);
        }
      });
    });

    console.log("ClashTest", result);
    return result;
  };

  const onSaveTest = async () => {
    let entities = [];
    let total = 0;
    if (clashList[openedTestId].clashGroup === "No Grouping") {
      openedTest.forEach((ele, index) => {
        entities.push({
          entity1: ele.entity1Id.id,
          entity2: ele.entity2Id.id,
          open: ele.open === 1 ? true : false,
        });
        total = total + 1;
      });
    } else if (clashList[openedTestId].clashGroup === "Group By Level") {
      openedTest.forEach((ele, index) => {
        console.log("ele", ele);
        total = total + 1;
        ele.children?.forEach((child, i) => {
          total = total + 1;
          entities.push({
            entity1: child.entity1Id.id,
            entity2: child.entity2Id.id,
            open: child.open,
          });
        });
      });
    } else if (clashList[openedTestId].clashGroup === "Group by Grid") {
      openedTest.forEach((ele, index) => {
        total = total + 2;
        entities.push({
          entity1: ele.childData.entity1Id.id,
          entity2: ele.childData.entity2Id.id,
          open: ele.childData.open,
        });
      });
    }

    const params = {
      testResult: {
        entities: entities,
        total: total,
      },
    };

    const url = BaseURL(`clashes/${clashList[openedTestId]._id}`);
    handleLoadingAction(true);
    const response = await Patch(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Updated Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
  };

  const onChangeStatus = (e, i, type, childId) => {
    if (clashList[openedTestId].clashGroup === "No Grouping") {
      setOpenedTest((prev) =>
        prev.map((ele, index) =>
          index === i ? { ...ele, status: e.target.value } : ele
        )
      );
      if (e.target.value === "Create Issue") {
        setIssueModal({
          ...issueModal,
          isOpen: true,
          aabb1: openedTest[i].entity1Id.aabb,
          aabb2: openedTest[i].entity2Id.aabb,
          parentId: i,
        });

        const intersectionMinX = Math.max(
          openedTest[i].entity1Id.aabb[0],
          openedTest[i].entity2Id.aabb[0]
        );
        const intersectionMinY = Math.max(
          openedTest[i].entity1Id.aabb[1],
          openedTest[i].entity2Id.aabb[1]
        );
        const intersectionMinZ = Math.max(
          openedTest[i].entity1Id.aabb[2],
          openedTest[i].entity2Id.aabb[2]
        );
        const annotation = props.annotationPlugin.createAnnotation({
          worldPos: [intersectionMinX, intersectionMinY, intersectionMinZ],
          occludable: true,
          markerShown: true,
          labelShown: false,
          values: {
            glyph: issueModal.stamp,
            fontColor: "red",
            borderColor: "red",
          },
        });

        setCreateAnnotation(annotation);
        setIsSelectedReview(false);
      } else if (e.target.value === "Ignore") {
        props.viewer.scene.setObjectsColorized(
          [openedTest[i].entity1Id.id, openedTest[i].entity2Id.id],
          false
        );
      }
    } else if (clashList[openedTestId].clashGroup === "Group By Level") {
      if (type === "parent") {
        setOpenedTest((prev) =>
          prev.map((ele, index) =>
            index === i ? { ...ele, status: e.target.value } : ele
          )
        );
      } else {
        setOpenedTest((prev) =>
          prev.map((ele, index) =>
            index === i
              ? {
                  ...ele,
                  children: ele.children.map((child, childIndex) =>
                    childIndex === childId
                      ? { ...child, status: e.target.value }
                      : child
                  ),
                }
              : ele
          )
        );
        const children = openedTest[i].children;

        if (e.target.value === "Create Issue") {
          setIssueModal({
            ...issueModal,
            isOpen: true,
            aabb1: children[childId].entity1Id.aabb,
            aabb2: children[childId].entity2Id.aabb,
            parentId: i,
            childId: childId,
          });

          const intersectionMinX = Math.max(
            children[childId].entity1Id.aabb[0],
            children[childId].entity2Id.aabb[0]
          );
          const intersectionMinY = Math.max(
            children[childId].entity1Id.aabb[1],
            children[childId].entity2Id.aabb[1]
          );
          const intersectionMinZ = Math.max(
            children[childId].entity1Id.aabb[2],
            children[childId].entity2Id.aabb[2]
          );
          const annotation = props.annotationPlugin.createAnnotation({
            worldPos: [intersectionMinX, intersectionMinY, intersectionMinZ],
            occludable: true,
            markerShown: true,
            labelShown: false,
            values: {
              glyph: issueModal.stamp,
              fontColor: "red",
              borderColor: "red",
            },
          });

          setCreateAnnotation(annotation);
          setIsSelectedReview(false);
        } else if (e.target.value === "Ignore") {
          props.viewer.scene.setObjectsColorized(
            [children[childId].entity1Id.id, children[childId].entity2Id.id],
            false
          );
        }
      }
    } else if (clashList[openedTestId].clashGroup === "Group by Grid") {
      setOpenedTest((prev) =>
        prev.map((ele, index) =>
          index === i
            ? {
                ...ele,
                status: "Reviewed",
                childData: [
                  {
                    entity1: ele.childData[0].entity1,
                    entity2: ele.childData[0].entity2,
                    entity1Id: ele.childData[0].entity1Id,
                    entity2Id: ele.childData[0].entity2Id,
                    new: ele.childData[0].new,
                    open: ele.childData[0].open,
                    total: ele.childData[0].total,
                    status: e.target.value,
                  },
                ],
              }
            : ele
        )
      );
      if (e.target.value === "Create Issue") {
        setIssueModal({
          ...issueModal,
          isOpen: true,
          aabb1: openedTest[i].childData[0].entity1Id.aabb,
          aabb2: openedTest[i].childData[0].entity2Id.aabb,
          parentId: i,
        });

        const intersectionMinX = Math.max(
          openedTest[i].childData[0].entity1Id.aabb[0],
          openedTest[i].childData[0].entity2Id.aabb[0]
        );
        const intersectionMinY = Math.max(
          openedTest[i].childData[0].entity1Id.aabb[1],
          openedTest[i].childData[0].entity2Id.aabb[1]
        );
        const intersectionMinZ = Math.max(
          openedTest[i].childData[0].entity1Id.aabb[2],
          openedTest[i].childData[0].entity2Id.aabb[2]
        );
        const annotation = props.annotationPlugin.createAnnotation({
          worldPos: [intersectionMinX, intersectionMinY, intersectionMinZ],
          occludable: true,
          markerShown: true,
          labelShown: false,
          values: {
            glyph: issueModal.stamp,
            fontColor: "red",
            borderColor: "red",
          },
        });

        setCreateAnnotation(annotation);
        setIsSelectedReview(false);
      } else if (e.target.value === "Ignore") {
        props.viewer.scene.setObjectsColorized(
          [
            openedTest[i].childData[0].entity1Id.id,
            openedTest[i].childData[0].entity2Id.id,
          ],
          false
        );
      }
    }
  };

  // const onClickNotReview = async (e, i) => {
  //   const annotations = [];
  //   const markups = [];
  //   const flag = 5;
  //   if (clashList[openedTestId].clashGroup === "Group By Level") {
  //     if (e.target.innerText === "Not reviewed") {
  //       const updatedChildren = await openedTest[i].children?.map(
  //         async (child, index) => {
  //           console.log("child");

  //           if (child.status === "Unassigned" && index < flag) {
  //             console.log("here??????????????");

  //             const intersectionMinX = Math.max(
  //               child.entity1Id.aabb[0],
  //               child.entity2Id.aabb[0]
  //             );
  //             const intersectionMinY = Math.max(
  //               child.entity1Id.aabb[1],
  //               child.entity2Id.aabb[1]
  //             );
  //             const intersectionMinZ = Math.max(
  //               child.entity1Id.aabb[2],
  //               child.entity2Id.aabb[2]
  //             );

  //             const annotation = props.annotationPlugin.createAnnotation({
  //               worldPos: [
  //                 intersectionMinX,
  //                 intersectionMinY,
  //                 intersectionMinZ,
  //               ],
  //               occludable: true,
  //               markerShown: true,
  //               markerAlign: "left",
  //               labelShown: false,
  //               values: {
  //                 glyph: issueModal.stamp,
  //                 fontColor: "red",
  //                 borderColor: "red",
  //               },
  //             });

  //             const screenshot = await props.viewer.getSnapshotWithPlugins({
  //               format: "png",
  //               width: window.innerWidth * 3, // Upscale snapshot resolution by 3x
  //               height: window.innerHeight * 3,
  //             });

  //             console.log("screenshot", screenshot);

  //             annotations.push(annotation);
  //             markups.push(screenshot);

  //             // Return a new object with the updated status
  //             return { ...child, status: issueModal.stamp };
  //           }

  //           return child; // Return unchanged child if condition is not met
  //         }
  //       );

  //       console.log(">>>>>>>>>>>>>>>>", annotations);
  //       console.log(">>>>>>>>>>>>>>>>", markups);
  //       const updatedOpenedTest = openedTest.map((ele, index) =>
  //         index === i
  //           ? { ...ele, status: "Reviewed", children: updatedChildren }
  //           : ele
  //       );

  //       console.log("updated", updatedOpenedTest);
  //       setOpenedTest(updatedOpenedTest);
  //       // If needed, update the state or variable with updatedOpenedTest
  //       // setOpenedTest({...openedTest, }updatedOpenedTest);
  //     }
  //   }

  //   // if (clashList[openedTestId].clashGroup === "Group by Grid") {
  //   //   if (e.target.innerText === "Not reviewed") {
  //   //     const updatedChildren = openedTest[i].children?.map((child) => {
  //   //       console.log("child");

  //   //       if (child.status === "Unassigned") {

  //   //         const intersectionMinX = Math.max(
  //   //           child.entity1Id.aabb[0],
  //   //           child.entity2Id.aabb[0]
  //   //         );
  //   //         const intersectionMinY = Math.max(
  //   //           child.entity1Id.aabb[1],
  //   //           child.entity2Id.aabb[1]
  //   //         );
  //   //         const intersectionMinZ = Math.max(
  //   //           child.entity1Id.aabb[2],
  //   //           child.entity2Id.aabb[2]
  //   //         );

  //   //         const annotation = props.annotationPlugin.createAnnotation({
  //   //           worldPos: [intersectionMinX, intersectionMinY, intersectionMinZ],
  //   //           occludable: true,
  //   //           markerShown: true,
  //   //           markerAlign: "left",
  //   //           labelShown: false,
  //   //           values: {
  //   //             glyph: "AH",
  //   //             fontColor: "red",
  //   //             borderColor: "red",
  //   //           },
  //   //         });

  //   //         // Return a new object with the updated status
  //   //         return { ...child, status: issueModal.stamp };
  //   //       }

  //   //       return child; // Return unchanged child if condition is not met
  //   //     });

  //   //     const updatedOpenedTest = openedTest.map((ele, index) =>
  //   //       index === i
  //   //         ? { ...ele, status: "Reviewed", children: updatedChildren }
  //   //         : ele
  //   //     );

  //   //     console.log("updated", updatedOpenedTest);
  //   //     setOpenedTest(updatedOpenedTest);
  //   //     // If needed, update the state or variable with updatedOpenedTest
  //   //     // setOpenedTest({...openedTest, }updatedOpenedTest);
  //   //   }
  //   // }
  // };

  // const onClickNotReview = async (e, i) => {
  //   const annotations = [];
  //   const markups = [];
  //   const flag = 2;
  //   const batchSize = 20; // Set the batch size (adjust as needed to avoid crashes)
  //   props.handleLoadingAction(true);
  //   // Check for the "Group By Level" clash group
  //   if (clashList[openedTestId].clashGroup === "Group By Level") {
  //     if (e.target.innerText === "Not reviewed") {
  //       // Async map function for updatedChildren with batching
  //       const updatedChildren = [];

  //       // Process the children in batches to avoid too many concurrent async operations
  //       for (let j = 0; j < openedTest[i].children?.length; j += batchSize) {
  //         const currentBatch = openedTest[i].children.slice(j, j + batchSize);

  //         // Wait for all children in the current batch to be processed
  //         const batchResults = await Promise.all(
  //           currentBatch.map(async (child, index) => {
  //             if (child.status === "Unassigned") {
  //               const intersectionMinX = Math.max(
  //                 child.entity1Id.aabb[0],
  //                 child.entity2Id.aabb[0]
  //               );
  //               const intersectionMinY = Math.max(
  //                 child.entity1Id.aabb[1],
  //                 child.entity2Id.aabb[1]
  //               );
  //               const intersectionMinZ = Math.max(
  //                 child.entity1Id.aabb[2],
  //                 child.entity2Id.aabb[2]
  //               );

  //               const intersectionMaxX = Math.min(
  //                 child.entity1Id.aabb[3],
  //                 child.entity2Id.aabb[3]
  //               );
  //               const intersectionMaxY = Math.min(
  //                 child.entity1Id.aabb[4],
  //                 child.entity2Id.aabb[4]
  //               );
  //               const intersectionMaxZ = Math.min(
  //                 child.entity1Id.aabb[5],
  //                 child.entity2Id.aabb[5]
  //               );

  //               props.viewer.cameraFlight.flyTo({
  //                 aabb: [
  //                   intersectionMinX,
  //                   intersectionMinY,
  //                   intersectionMinZ,
  //                   intersectionMaxX,
  //                   intersectionMaxY,
  //                   intersectionMaxZ,
  //                 ],
  //               });
  //               // Create annotation
  //               const annotation = props.annotationPlugin.createAnnotation({
  //                 worldPos: [
  //                   intersectionMinX,
  //                   intersectionMinY,
  //                   intersectionMinZ,
  //                 ],
  //                 occludable: true,
  //                 markerShown: true,
  //                 markerAlign: "left",
  //                 labelShown: false,
  //                 values: {
  //                   glyph: issueModal.stamp,
  //                   fontColor: "red",
  //                   borderColor: "red",
  //                 },
  //               });

  //               // Capture the screenshot
  //               const screenshot = await props.viewer.getSnapshotWithPlugins({
  //                 format: "png",
  //                 width: window.innerWidth * 3, // Upscale snapshot resolution by 3x
  //                 height: window.innerHeight * 3,
  //               });

  //               annotations.push(annotation);
  //               markups.push(screenshot);

  //               // Return a new object with updated status
  //               return { ...child, status: issueModal.stamp };
  //             }
  //             return child; // Return unchanged child if condition is not met
  //           })
  //         );

  //         // Add processed children to updatedChildren
  //         updatedChildren.push(...batchResults);

  //         // Optional: Throttle the requests by adding a small delay between batches
  //         await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay as needed
  //       }

  //       console.log("Annotations:", annotations);
  //       console.log("Markups:", markups);

  //       // Update the openedTest state with updated children
  //       const updatedOpenedTest = openedTest.map((ele, index) =>
  //         index === i
  //           ? { ...ele, status: "Reviewed", children: updatedChildren }
  //           : ele
  //       );

  //       console.log("Updated Test:", updatedOpenedTest);

  //       setOpenedTest(updatedOpenedTest); // Update the state with the modified data
  //     }
  //   }

  //   props.handleLoadingAction(false);
  // };

  const onClickNotReview = (e, i) => {
    setIssueModal({
      ...issueModal,
      isOpen: true,
      parentId: i,
    });
    setIsSelectedReview(true);
  };

  const onCreateMultiAnnotation = async () => {
    if (!issueModal.title) {
      setIssueModal({ ...issueModal, inputTitleError: "Please Input Title" });
      return;
    }
    if (!issueModal.dueDate) {
      setIssueModal({
        ...issueModal,
        inputDueDateError: "Please Input DueDate",
      });
      return;
    }

    console.log("IssueModal", issueModal);
    setIssueModal({ ...issueModal, isOpen: false });

    props.handleLoadingAction(true);

    const annotations = [];
    const markups = [];
    const batchSize = 20;

    if (clashList[openedTestId].clashGroup !== "Group By Level") {
      props.handleLoadingAction(false);
      return;
    }

    const children = openedTest[issueModal.parentId].children || [];
    const filteredChildren = children.filter(
      (child) => child.status === "Unassigned"
    );

    if (filteredChildren.length === 0) {
      props.handleLoadingAction(false);
      return;
    }

    // Function to process each annotation with screenshot capture
    const processChild = async (child) => {
      const { entity1Id, entity2Id } = child;

      const intersectionMinX = Math.max(entity1Id.aabb[0], entity2Id.aabb[0]);
      const intersectionMinY = Math.max(entity1Id.aabb[1], entity2Id.aabb[1]);
      const intersectionMinZ = Math.max(entity1Id.aabb[2], entity2Id.aabb[2]);

      const intersectionMaxX = Math.min(entity1Id.aabb[3], entity2Id.aabb[3]);
      const intersectionMaxY = Math.min(entity1Id.aabb[4], entity2Id.aabb[4]);
      const intersectionMaxZ = Math.min(entity1Id.aabb[5], entity2Id.aabb[5]);

      const minX = Math.min(entity1Id.aabb[0], entity2Id.aabb[0]);
      const minY = Math.min(entity1Id.aabb[1], entity2Id.aabb[1]);
      const minZ = Math.min(entity1Id.aabb[2], entity2Id.aabb[2]);

      const maxX = Math.max(entity1Id.aabb[3], entity2Id.aabb[3]);
      const maxY = Math.max(entity1Id.aabb[4], entity2Id.aabb[4]);
      const maxZ = Math.max(entity1Id.aabb[5], entity2Id.aabb[5]);

      const center1 = (minX + maxX) / 2;
      const center2 = (minY + maxY) / 2;
      const center3 = (minZ + maxZ) / 2;

      console.log("center", [center1, center2, center3]);
      props.viewer.camera.eye = [center1 + 5, center2 + 5, center3 + 10];
      props.viewer.camera.look = [center1, center2, center3];
      props.viewer.camera.up = [0, 1, 0];

      props.viewer.scene.setObjectsXRayed(props.viewer.scene.objectIds, true);
      entity1Id.xrayed = false;
      entity2Id.xrayed = false;

      // Create annotation
      const annotation = props.annotationPlugin.createAnnotation({
        worldPos: [intersectionMinX, intersectionMinY, intersectionMinZ],
        occludable: true,
        markerShown: true,
        markerAlign: "left",
        labelShown: false,
        values: {
          glyph: issueModal.stamp,
          fontColor: "red",
          borderColor: "red",
        },
      });

      // Wait for camera movement before taking a screenshot
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      props.viewer.scene.render();
      const screenshot =
        props.viewer.scene.canvas.canvas.toDataURL("image/png");

      // Take a screenshot
      // const screenshot = await props.viewer.getSnapshotWithPlugins({
      //   format: "png",
      //   width: window.innerWidth * 2, // Keeping it optimized
      //   height: window.innerHeight * 2,
      // });

      return {
        annotation,
        screenshot,
        updatedChild: { ...child, status: issueModal.stamp },
      };
    };

    const updatedChildren = [];
    for (let j = 0; j < filteredChildren.length; j += batchSize) {
      const batch = filteredChildren.slice(j, j + batchSize);

      // Process all children in parallel for speed
      const batchResults = await Promise.all(batch.map(processChild));

      updatedChildren.push(...batchResults.map((res) => res.updatedChild));

      const annotations = batchResults.map((res) => res.annotation);
      const markups = batchResults.map((res) => res.screenshot);

      await saveAnnotations(annotations, markups);
      // Small delay for UI responsiveness
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    console.log("Annotations:", annotations);
    console.log("Markups:", markups);

    // Update state only once at the end
    const updatedOpenedTest = openedTest.map((ele, index) =>
      index === issueModal.parentId
        ? { ...ele, status: "Reviewed", children: updatedChildren }
        : ele
    );

    setOpenedTest(updatedOpenedTest);
    props.handleLoadingAction(false);
  };

  const saveAnnotations = async (annotations, markups) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    const formData = new FormData();
    const markupUrl = BaseURL("annotations/markup/batch");
    markups.forEach((markup) => {
      const base64Data = markup.replace(/^data:image\/png;base64,/, ""); // Remove base64 header
      const blob = new Blob(
        [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
        {
          type: "image/png",
        }
      );
      formData.append("markup", blob, `issue-${formattedDate}.png`);
    });

    const response = await Post(
      markupUrl,
      formData,
      apiHeader(props.token, true)
    );
    if (response !== undefined) {
      let params = [];
      annotations.forEach((annotation, index) => {
        params.push({
          entity: annotation.entity?.id,
          values: annotation._values,
          new: true,
          worldPos: annotation.worldPos,
          viewPos: annotation.viewPos,
          canvasPos: annotation.canvasPos,
          camera: {
            eye: props.viewer.camera.eye,
            look: props.viewer.camera.look,
            up: props.viewer.camera.up,
          },
          title: issueModal.title,
          stamp: issueModal.stamp,
          status: issueModal.status,
          type: issueModal.type,
          assignee: issueModal.assignee,
          dueDate: issueModal.dueDate,
          startDate: issueModal.startDate,
          comment: issueModal.comment,
          markUp: response?.data[index].fileName,
          projects: [`${props.projectName}`],
        });
      });
      const url = BaseURL("annotations/batch");
      const res = await Post(url, params, apiHeader(props.token));
      CustomToast("Saved Succeessfully!", "affirmative");
    }
  };
  const onClickOpenedTestRow = (entity1, entity2) => {
    const viewer = props.viewer;
    const scene = viewer.scene;
    scene.setObjectsXRayed(scene.objectIds, true);
    entity1.xrayed = false;
    entity2.xrayed = false;

    const minX = Math.min(entity1.aabb[0], entity2.aabb[0]);
    const minY = Math.min(entity1.aabb[1], entity2.aabb[1]);
    const minZ = Math.min(entity1.aabb[2], entity2.aabb[2]);

    // Update the maximum values
    const maxX = Math.max(entity1.aabb[3], entity2.aabb[3]);
    const maxY = Math.max(entity1.aabb[4], entity2.aabb[4]);
    const maxZ = Math.max(entity1.aabb[5], entity2.aabb[5]);
    viewer.cameraFlight.flyTo(
      {
        aabb: [minX, minY, minZ, maxX, maxY, maxZ],
        duration: 0.5,
      },
      () => {
        setTimeout(function () {
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
        }, 500);
      }
    );
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpand = (index) => {
    if (expandedRows.includes(index)) {
      // Collapse if already expanded
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      // Expand if not already expanded
      setExpandedRows([...expandedRows, index]);
    }
  };

  const captureScreenshot = async () => {
    const startTime = performance.now();
    const intersectionMinX = Math.min(issueModal.aabb1[0], issueModal.aabb2[0]);
    const intersectionMinY = Math.min(issueModal.aabb1[1], issueModal.aabb2[1]);
    const intersectionMinZ = Math.min(issueModal.aabb1[2], issueModal.aabb2[2]);

    const intersectionMaxX = Math.max(issueModal.aabb1[3], issueModal.aabb2[3]);
    const intersectionMaxY = Math.max(issueModal.aabb1[4], issueModal.aabb2[4]);
    const intersectionMaxZ = Math.max(issueModal.aabb1[5], issueModal.aabb2[5]);

    const center1 = (intersectionMinX + intersectionMaxX) / 2;
    const center2 = (intersectionMinY + intersectionMaxY) / 2;
    const center3 = (intersectionMinZ + intersectionMaxZ) / 2;

    props.viewer.camera.eye = [center1 + 5, center2 + 5, center3 + 10];
    props.viewer.camera.look = [center1, center2, center3];
    props.viewer.camera.up = [0, 1, 0];
    await new Promise((resolve) => setTimeout(resolve, 50));

    const screenshot = props.viewer.scene.canvas.canvas.toDataURL("image/png");
    console.timeEnd("Exception Time");
    const endTime = performance.now();
    const time = (endTime - startTime).toFixed(2);
    CustomToast(`It takes ${time}ms`, "affirmative");
    setMarkupImage(screenshot);
    console.log(screenshot);
    return screenshot;
  };

  const uploadMarkup = async (annotationId, markupImage) => {
    console.log("markupImage", markupImage);
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    // Convert the base64 image to a Blob
    const base64Data = markupImage.replace(/^data:image\/png;base64,/, ""); // Remove base64 header
    const blob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      {
        type: "image/png",
      }
    );

    console.log("formatted", typeof formattedDate);
    // console.log("fileUploader", fileUploader);
    const url = BaseURL("annotations/markup");

    let formData = new FormData();

    formData.append("markup", blob, `issue-${formattedDate}.png`);
    formData.append("annotation", annotationId);

    const res = await Post(url, formData, apiHeader(props.token, true));
    return res;
  };

  const handleIssueTracking = async () => {
    try {
      handleLoadingAction(true);

      // Validate required fields before proceeding
      const validationErrors = validateIssueModal(issueModal);
      if (validationErrors) {
        setIssueModal({ ...issueModal, ...validationErrors });
        return;
      }

      // Capture screenshot if needed
      const screenshot = markupImage ?? (await captureScreenshot());

      // Prepare annotation parameters
      const params = {
        ...createAnnotationParams(createAnnotation),
        ...getIssueModalParams(issueModal),
        camera: getCameraParams(props.viewer.camera),
        projects: [`${props.projectName}`],
      };

      // Submit annotation
      const url = BaseURL("annotations");
      const response = await Post(url, params, apiHeader(props.token));

      if (response?.data?.id) {
        await uploadMarkup(response.data.id, screenshot);
        CustomToast("Saved Successfully!", "affirmative");

        // Update UI state
        updateClashStatus();
        resetModalState();
      }
    } catch (error) {
      console.error("Error in handleIssueTracking:", error);
      CustomToast("Failed to save issue", "error");
    } finally {
      handleLoadingAction(false);
    }
  };

  // Helper functions
  const validateIssueModal = ({ title, dueDate }) => {
    const errors = {};

    if (!title) {
      errors.inputTitleError = "Please Input Title";
    }
    if (!dueDate) {
      errors.inputDueDateError = "Please Input DueDate";
    }

    return Object.keys(errors).length ? errors : null;
  };

  const createAnnotationParams = (annotation) => ({
    entity: annotation?.entity?.id,
    values: annotation?._values,
    new: true,
    worldPos: annotation?.worldPos,
    viewPos: annotation?.viewPos,
    canvasPos: annotation?.canvasPos,
  });

  const getIssueModalParams = ({
    title,
    stamp,
    status,
    type,
    assignee,
    dueDate,
    startDate,
    comment,
  }) => ({
    title,
    stamp,
    status,
    type,
    assignee,
    dueDate,
    startDate,
    comment,
  });

  const getCameraParams = (camera) => ({
    eye: camera.eye,
    look: camera.look,
    up: camera.up,
  });

  const updateClashStatus = () => {
    const { clashGroup } = clashList[openedTestId];
    const { parentId, childId, stamp } = issueModal;

    setOpenedTest((prev) => {
      switch (clashGroup) {
        case "No Grouping":
          return prev.map((ele, index) =>
            index === parentId ? { ...ele, status: stamp } : ele
          );

        case "Group By Level":
          return prev.map((ele, index) =>
            index === parentId
              ? {
                  ...ele,
                  children: ele.children.map((child, childIndex) =>
                    childIndex === childId ? { ...child, status: stamp } : child
                  ),
                }
              : ele
          );

        case "Group by Grid":
          return prev.map((ele, index) =>
            index === parentId
              ? {
                  ...ele,
                  status: "Reviewed",
                  childData: {
                    ...ele.childData,
                    status: stamp,
                  },
                }
              : ele
          );

        default:
          return prev;
      }
    });
  };

  const resetModalState = () => {
    setIssueModal({ ...issueModal, isOpen: false });
    setMarkupImage(null);
  };

  const onCreateIssue = () => {
    const validationErrors = validateIssueModal(issueModal);
    if (validationErrors) {
      setIssueModal({ ...issueModal, ...validationErrors });
      return;
    }

    handleIssueTracking();
  };

  const onCreateMarkUp = async () => {
    if (markupImage === null) await captureScreenshot();
    setIsImageEditorOpen(true);
  };

  const updateMarkup = (updatedMarkup) => {
    setMarkupImage(updatedMarkup);
    setIsImageEditorOpen(false);
  };

  // Table header component
  const TableHeader = ({ onSelectAll, isAllSelected }) => (
    <tr>
      <th style={{ width: "5%" }}>
        <CheckBox onChange={onSelectAll} checked={isAllSelected} />
      </th>
      <th style={{ width: "45%" }}>Name</th>
      <th style={{ width: "10%" }}>#</th>
      <th style={{ width: "10%" }}>Open</th>
      <th style={{ width: "10%" }}>New</th>
      <th style={{ width: "20%" }}>Status</th>
    </tr>
  );

  // Status cell component
  const StatusCell = ({ status, onChange, index, type, childIndex }) => {
    if (STATUS.includes(status)) {
      return (
        <Dropdown
          value={status}
          optionArray={STATUS}
          onChange={(e) => onChange(e, index, type, childIndex)}
          onClick={() => {}}
        />
      );
    }
    return <div className="clash-detection-status-stamp">{status}</div>;
  };

  // Toolbar component
  const Toolbar = ({ onBack, onRun, onSave }) => (
    <div className="clash-detection-tab-toolbar">
      <Button buttonName="Back" onClick={onBack} />
      <Button buttonName="Run Test" onClick={onRun} />
      <Button buttonName="Save" onClick={onSave} />
    </div>
  );

  // Memoize the handler to prevent unnecessary re-renders
  const handleSelectAll = useMemo(
    () => (e) => {
      setSelectedRows(e.target.checked ? openedTest.map((row) => row.id) : []);
    },
    [setSelectedRows, openedTest]
  );

  // Group-specific row renderer
  const renderNoGroupingRows = (element, i) => (
    <tr key={i} className="table-row">
      <td className="clash-detection-table-col1">
        <CheckBox
          onChange={(e) =>
            handleCheckboxChange(
              e,
              {
                entity1Id: element.entity1Id,
                entity2Id: element.entity2Id,
              },
              "parent"
            )
          }
          checked={selectedRows.includes(element.entity1Id.id)}
        />
      </td>
      <td
        onClick={() =>
          onClickOpenedTestRow(element.entity1Id, element.entity2Id)
        }
      >
        {`${element.entity1} vs ${element.entity2}`}
      </td>
      <td>{element.total}</td>
      <td>{element.open}</td>
      <td className="table-col-new">
        <div className={element.new ? "new-issue" : null} />
      </td>
      <td className="clash-detection-table-status">
        <StatusCell
          status={element.status}
          onChange={onChangeStatus}
          index={i}
          type="parent"
        />
      </td>
    </tr>
  );

  const renderGroupByLevelRows = (element, index) => (
    <React.Fragment key={index}>
      <tr className="table-row">
        <td className="clash-detection-table-col1">
          <CheckBox
            onChange={(e) => handleCheckboxChange(e, index, "parent")}
            checked={selectedRows.includes(index)}
          />
        </td>
        <td
          className="clash-detection-table-name"
          onClick={() => toggleExpand(index)}
        >
          <div
            className={
              expandedRows.includes(index)
                ? "dropup-icon"
                : "header-dropdown-icon"
            }
          />
          <div>{element.levelName}</div>
        </td>
        <td>{element.total}</td>
        <td>{element.open}</td>
        <td>{element.new}</td>
        <td>
          <Button
            buttonName={element.status}
            onClick={(e) => onClickNotReview(e, index)}
          />
        </td>
      </tr>
      {expandedRows.includes(index) && renderChildRows(element.children, index)}
    </React.Fragment>
  );

  const renderGroupByGridRows = (element, index) => (
    <React.Fragment key={index}>
      <tr className="table-row">
        <td className="clash-detection-table-col1">
          <CheckBox
            onChange={(e) => handleCheckboxChange(e, index, "parent")}
            checked={selectedRows.includes(index)}
          />
        </td>
        <td
          className="clash-detection-table-name"
          onClick={() => toggleExpand(index)}
        >
          <div
            className={
              expandedRows.includes(index)
                ? "dropup-icon"
                : "header-dropdown-icon"
            }
          />
          <div>{`${element.gridXmax}-${element.gridXmin} , ${element.gridZ}`}</div>
        </td>
        <td>{element.total}</td>
        <td>{element.open}</td>
        <td>{element.new}</td>
        <td>
          <Button
            buttonName={element.status}
            onClick={(e) => onClickNotReview(e, index)}
          />
        </td>
      </tr>
      {expandedRows.includes(index) &&
        renderChildRows(element.childData, index)}
    </React.Fragment>
  );

  const renderChildRows = (children, parentIndex) => {
    console.log("//////////////", children);
    return children?.map((child, childIndex) => (
      <tr key={`${parentIndex}-${childIndex}`} className="table-row-child">
        <td className="clash-detection-table-col1">
          <CheckBox
            onChange={(e) =>
              handleCheckboxChange(
                e,
                {
                  entity1Id: child.entity1Id,
                  entity2Id: child.entity2Id,
                },
                "child"
              )
            }
            checked={selectedRows.includes(child.entity1Id.id)}
          />
        </td>
        <td
          onClick={() => onClickOpenedTestRow(child.entity1Id, child.entity2Id)}
        >
          <div style={{ paddingLeft: "20px" }}>
            🔹 {`${child.entity1} VS ${child.entity2}`}
          </div>
        </td>
        <td>{child.total}</td>
        <td></td>
        <td className="table-col-new">
          <div className={child.new ? "new-issue" : null} />
        </td>
        <td className="clash-detection-table-status">
          <StatusCell
            status={child.status}
            onChange={onChangeStatus}
            index={parentIndex}
            type="child"
            childIndex={childIndex}
          />
        </td>
      </tr>
    ));
  };

  const webContents = (
    <>
      <LSideBar
        isToggle={props.setClashDetectionTabStatus}
        handleToggle={closeTab}
        header={"Clash Detection"}
      >
        {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
        {!isTestOpen && (
          <>
            {" "}
            <div className="clash-detection-tab-toolbar">
              <div>
                <Button buttonName={"Create"} onClick={handleOpen} />
              </div>
              <div>
                <Button buttonName={"Update"} onClick={handleOpen} />
              </div>
              <div>
                <Button buttonName={"Setting"} onClick={handleOpen} />
              </div>
            </div>
            <table className="custom-table">
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>
                    <CheckBox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows(
                            showClashDetectionArray1.map((row) => row.id)
                          );
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                      checked={selectedRows.length === clashList.length}
                    />
                  </th>
                  <th style={{ width: "35%" }}>Name</th>
                  <th style={{ width: "10%" }}>Up to date</th>
                  <th style={{ width: "20%" }}>Last run by</th>
                  <th style={{ width: "10%" }}>New clashes</th>
                  <th style={{ width: "10%" }}>Old clashes</th>
                  <th style={{ width: "10%" }}>Setting</th>
                </tr>
                {clashList.map((element, i) => {
                  return (
                    <tr
                      key={i}
                      // className={
                      //   selectedRows.includes(element.id) ? "selected" : ""
                      // }
                      className="table-row"
                    >
                      <td className="clash-detection-table-col1">
                        <CheckBox
                          onChange={(event) =>
                            handleCheckboxChange(event, i, "parent")
                          }
                          checked={selectedRows.includes(element._id)}
                        />
                      </td>
                      <td>{element.name}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <Button
                          buttonName={"Open"}
                          onClick={() => onOpenClashTest(i)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {isTestOpen && (
          <>
            <Toolbar
              onBack={() => {
                getAllClashTests();
                setIsTestOpen(false);
              }}
              onRun={onClickRun}
              onSave={onSaveTest}
            />

            <table className="custom-table">
              <tbody>
                <TableHeader
                  onSelectAll={handleSelectAll}
                  isAllSelected={selectedRows.length === openedTest?.length}
                />
                {openedTest?.map((element, index) => {
                  switch (clashList[openedTestId].clashGroup) {
                    case "No Grouping":
                      return renderNoGroupingRows(element, index);
                    case "Group By Level":
                      return renderGroupByLevelRows(element, index);
                    case "Group by Grid":
                      return renderGroupByGridRows(element, index); // Similar structure to Level grouping
                    default:
                      return null;
                  }
                })}
              </tbody>
            </table>
          </>
        )}
      </LSideBar>
      <Modal
        isToggle={open}
        handleToggle={() => setOpen(false)}
        header={"Clash Test"}
        height={`calc(70vh)`}
      >
        <div className="clash-detection-modal-container">
          <div className="clash-detection-modal">
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Name</span>
              </div>
              <div className="clash-detection-test-btn">
                <input
                  className="mycustom-button"
                  value={clashName}
                  onChange={(e) => {
                    setClashName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Type</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashType}
                  optionArray={TYPES}
                  onChange={(e) => {
                    setClashType(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Tolerance</span>
              </div>
              <div className="clash-detection-test-btn">
                <input
                  className="mycustom-button"
                  value={tolerance}
                  onChange={(e) => {
                    setTolerance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Grouping</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashGroup}
                  optionArray={GROUPING}
                  onChange={(e) => {
                    setClashGroup(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>View Isolation</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashViewIsolation}
                  optionArray={VIEWISOLATION}
                  onChange={(e) => {
                    setClashViewIsolation(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="modal-body-list">
              <div className="modal-body-list-colum">
                <h5>SearchSet A</h5>
                <ul className="modal-ul">
                  {showList?.map((_, i) => (
                    <li
                      onClick={(e) => getSearchSet1(e)}
                      className={
                        searchSet1Flag === _.name
                          ? "modal-li-clicked"
                          : "modal-li"
                      }
                      key={i}
                      value={_.name}
                    >
                      <div>{_.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-body-list-colum">
                <h5>SearchSet B</h5>
                <ul className="modal-ul">
                  {showList?.map((_, i) => (
                    <li
                      onClick={(e) => getSearchSet2(e)}
                      className={
                        searchSet2Flag === _.name
                          ? "modal-li-clicked"
                          : "modal-li"
                      }
                      key={i}
                      value={_.name}
                    >
                      <div>{_.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <Button buttonName={"Create"} onClick={() => createClashTest()} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isToggle={issueModal.isOpen}
        handleToggle={() => {
          if (!isSelectedReview)
            props.annotationPlugin.destroyAnnotation(createAnnotation.id);
          setIssueModal({ ...issueModal, isOpen: false });
        }}
        header={"Issue Modal"}
        height={issueModal.height}
      >
        <div className="annotation-issue-container">
          <div className="annotation-issue-modal">
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Issue Title</div>
              <div className="issue-modal-title-input">
                <input
                  className="mycustom-button"
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      title: event.target.value,
                      inputTitleError: null,
                    })
                  }
                  style={{ width: "98%" }}
                />
              </div>
              {issueModal.inputTitleError && (
                <p className="input-error">{issueModal.inputTitleError}</p>
              )}
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Stamp</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                // value={issueModal.stamp}
                optionArray={options.stampOption}
                onChange={(event) =>
                  setIssueModal({
                    ...issueModal,
                    stamp: event.target.value,
                  })
                }
                onClick={() => {}}
              /> */}
                <input
                  className="mycustom-button"
                  value={issueModal.stamp}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Status</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                // value={issueModal.stamp}
                optionArray={options.statusOption}
                onChange={(event) =>
                  setIssueModal({
                    ...issueModal,
                    status: event.target.value,
                  })
                }
                onClick={() => {}}
              /> */}
                <input
                  className="mycustom-button"
                  value={options.statusOption[0]}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Type</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                // value={issueModal.stamp}
                optionArray={options.typeOption}
                onChange={(event) =>
                  setIssueModal({
                    ...issueModal,
                    type: event.target.value,
                  })
                }
                onClick={() => {}}
              /> */}
                <input
                  className="mycustom-button"
                  value={options.typeOption[0]}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Assignee To</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                // value={issueModal.stamp}
                optionArray={options.assigneeOption}
                onChange={(event) =>
                  setIssueModal({
                    ...issueModal,
                    assignee: event.target.value,
                  })
                }
                onClick={() => {}}
              /> */}
                <input
                  className="mycustom-button"
                  value={options.assigneeOption[0].name}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Due To</div>
              <div className="issue-modal-title-input">
                <CustomDate
                  date={issueModal.dueDate}
                  handleDateChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      dueDate: event.target.value,
                      inputDueDateError: null,
                    })
                  }
                  style={{ width: "96%" }}
                />
              </div>
              {issueModal.inputDueDateError && (
                <p className="input-error">{issueModal.inputDueDateError}</p>
              )}
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Start Date</div>
              <div className="issue-modal-title-input">
                <CustomDate
                  date={new Date().toLocaleDateString("en-CA")}
                  // date={"2025-02-07"}
                  handleDateChange={() => {}}
                  style={{ width: "96%" }}
                  disabled={true}
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Comment</div>
              <div className="issue-modal-title-input">
                <textarea
                  className="issue-modal-textarea"
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      comment: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <IconButton
              buttonName={"Create"}
              buttonType={"Save"}
              onClick={() => {
                !isSelectedReview ? onCreateIssue() : onCreateMultiAnnotation();
              }}
            />
            {!isSelectedReview && (
              <IconButton
                buttonName={"Add MarkUp"}
                buttonType={"Save"}
                onClick={onCreateMarkUp}
              />
            )}
          </div>
        </div>
      </Modal>
      {isImageEditorOpen && (
        <div className="image-editor">
          <Canvas
            backgroundImage={markupImage}
            cancel={() => {
              setIsImageEditorOpen(false);
              setMarkupImage(null);
            }}
            updateMarkup={updateMarkup}
          />
        </div>
      )}
    </>
  );

  return props.setClashDetectionTabStatus && Constants.DESKTOP_APP ? (
    <DesktopAppModalWrapper
      title={"clash-detection"}
      children={webContents}
      state={props.setClashDetectionTabStatus}
      onClose={closeTab}
      styles={{
        width: "460px",
      }}
    />
  ) : (
    webContents
  );
};

const mapStateToProps = (state) => {
  return {
    setClashDetectionTabStatus:
      state.ClashDetectionReducer.setClashDetectionTabStatus,
    clashDetectionList: state.ClashDetectionReducer.clashDetectionList,
    clashDetectionListFlag: state.ClashDetectionReducer.clashDetectionListFlag,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    searchSaveListFlag: state.SearchSetsReducer.searchSaveListFlag,
    addToClashDetectionFromView:
      state.xeokitReducer.addToClashDetectionFromView,
    addToClashDetectionNormalClick:
      state.xeokitReducer.addToClashDetectionNormalClick,
    selectContextMenuBtnStatus: state.xeokitReducer.selectContextMenuBtnStatus,
    viewer: state.xeokitReducer.viewer,
    metaData: state.ProjectReducer.metaData,

    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
  };
};

const mapDispatchToProps = {
  setClashDetectionTabStatusAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClashDetectionTab);
