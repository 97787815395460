import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import {
  // ContextMenu,
  TreeViewPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";
import {
  setTreeViewStatusAction,
  setSelectedEntities,
} from "../../action/xeokitAction";

import ContextMenu from "../../components/ContextMenu/ContextMenu";
import Tab from "../../components/Tab/Tab";
import LSideBar from "../../components/LSideBar/LSideBar";

import "./TreeViewTab.css";
import { CheckBox } from "../../components/Button/Button";
import { Constants } from "../../constant/constants";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./TreeViewTab.css';

const TreeViewTab = (props) => {
  const closeTab = () => {
    props.setTreeViewStatusAction(false);
  };
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const spanRefs = useRef({});
  const [handleTreeView, setHandleTreeView] = useState(true);
  const [changedItems, setChangedItems] = useState([]);

  const getModelEntity = (key) => {
    const objects = props.viewer.scene.objects;
    const entity = Object.entries(objects).filter((objectKey) => {
      if (objectKey[0].includes(key)) {
        return objectKey[1];
      }
    });

    return entity;
  };
  const handleContextMenu = (event, key) => {
    event.preventDefault();
    const entity = getModelEntity(key);
    setContextMenuItems([
      {
        label: "View Fit",
        onClick: () => {
          const scene = props.viewer.scene;
          scene.setObjectsSelected(scene.selectedObjectIds, false);
          if (entity.length > 0) entity[0][1].selected = true;
          props.viewer.cameraFlight.flyTo(
            {
              aabb: scene.getAABB(entity[0]),
              duration: 0.5,
            },
            () => {
              setTimeout(function () {
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
              }, 500);
            }
          );
        },
      },
      {
        label: "View Fit All",
        onClick: () => {
          const scene = props.viewer.scene;
          props.viewer.cameraFlight.flyTo({
            aabb: scene.getAABB(),
            duration: 0.5,
          });
        },
      },
      {
        label: "Hide",
        onClick: () => {
          if (entity.length > 0) entity[0][1].visible = false;

          if (spanRefs.current[key]) {
            spanRefs.current[key].style.color = "#cbc3c3";
            setChangedItems([...changedItems, key]);
          }
        },
      },
      {
        label: "Hide Others",
        onClick: () => {
          const viewer = props.viewer;
          const scene = viewer.scene;
          const metaObject = viewer.metaScene.metaObjects[entity[0][1].id];
          if (!metaObject) {
            return;
          }
          scene.setObjectsVisible(scene.visibleObjectIds, false);
          scene.setObjectsXRayed(scene.xrayedObjectIds, false);
          scene.setObjectsSelected(scene.selectedObjectIds, false);
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
          metaObject.withMetaObjectsInSubtree((metaObject) => {
            const entity = scene.objects[metaObject.id];
            if (entity) {
              entity.visible = true;
            }
          });
          setHandleTreeView(false);
          if (changedItems) {
            changedItems.forEach((item) => {
              spanRefs.current[item].style.color = "#cbc3c3";
            });
          }
          if (spanRefs.current[key]) {
            spanRefs.current[key].style.color = "black";
            setChangedItems([...changedItems, key]);
          }
        },
      },
      {
        label: "Hide All",
        onClick: () => {
          props.viewer.scene.setObjectsVisible(
            props.viewer.scene.visibleObjectIds,
            false
          );
          setHandleTreeView(false);
          if (changedItems) {
            changedItems.forEach((item) => {
              spanRefs.current[item].style.color = "#cbc3c3";
            });
          }
        },
      },
      {
        label: "Show",
        onClick: () => {
          if (entity.length > 0) entity[0][1].visible = true;
          if (spanRefs.current[key]) {
            spanRefs.current[key].style.color = "black";
            setChangedItems([...changedItems, key]);
          }
        },
      },
      {
        label: "Show All",
        onClick: () => {
          const scene = props.viewer.scene;
          scene.setObjectsVisible(scene.objectIds, true);
          setHandleTreeView(true);
          if (changedItems) {
            console.log("changedItem", changedItems);
            changedItems.forEach((item) => {
              spanRefs.current[item].style.color = "black";
            });
          }
        },
      },
      {
        label: "X-ray",
        onClick: () => {
          if (entity.length > 0) entity[0][1].xrayed = true;
        },
      },
      {
        label: "Undo X-ray",
        onClick: () => {
          if (entity.length > 0) entity[0][1].xrayed = false;
        },
      },
      {
        label: "X-ray Others",
        onClick: () => {
          const viewer = props.viewer;
          const scene = viewer.scene;
          const metaObject = viewer.metaScene.metaObjects[entity[0][1].id];
          if (!metaObject) {
            return;
          }
          scene.setObjectsVisible(scene.objectIds, true);
          scene.setObjectsXRayed(scene.objectIds, true);
          scene.setObjectsSelected(scene.selectedObjectIds, false);
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
          metaObject.withMetaObjectsInSubtree((metaObject) => {
            const entity = scene.objects[metaObject.id];
            if (entity) {
              entity.xrayed = false;
            }
          });
        },
      },
      {
        label: "Reset X-ray",
        onClick: () => {
          props.viewer.scene.setObjectsXRayed(
            props.viewer.scene.xrayedObjectIds,
            false
          );
        },
      },
      { label: "Close", onClick: () => closeContextMenu() },
    ]);
    console.log("x", event);
    console.log("y", event);
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
    setContextMenuVisible(true);
  };

  const closeContextMenu = () => {
    setContextMenuVisible(false);
  };

  const [selectedItems, setSelectedItems] = useState({});

  // Function to handle checkbox change
  const handleCheckboxChange = (keyPath, isChecked) => {
    console.log("keyPath", keyPath, "isChecked", isChecked);

    const entity = getModelEntity(keyPath);
    console.log(entity);
    if (entity.length > 0) entity[0][1].selected = isChecked;
    props.setSelectedEntities({ keyPath, isChecked });
    // props.setSelectedEntities((prevSelectedItems) => ({
    //   ...prevSelectedItems,
    //   [keyPath]: isChecked,
    // }));

    console.log("select", props.selectedEntities);
  };

  const renderTree = (nodes, parentKey = "") => {
    // console.log("node", nodes);
    if (!nodes) return;
    return Object.entries(nodes)?.map(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const isChecked = !!props.selectedEntities[currentKey];
      return (
        <li key={currentKey}>
          <label className="list-item">
            {/* <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) =>
                handleCheckboxChange(currentKey, e.target.checked)
              }
              style={{ marginTop: "7x" }}
            /> */}
            {!/^(\d+(-\d+)*)$/.test(key) ? (
              <details className="details-item">
                <summary>
                  <div>📂</div>
                  <div> {key}</div>
                </summary>
                <ul>{renderTree(value)}</ul>
              </details>
            ) : (
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                {" "}
                <CheckBox
                  checked={isChecked}
                  onChange={(e) =>
                    handleCheckboxChange(currentKey, e.target.checked)
                  }
                />
                <span
                  onContextMenu={(event) => handleContextMenu(event, key)}
                  ref={(ele) => (spanRefs.current[key] = ele)}
                  className={handleTreeView ? "showTreeColor" : "hideTreeColor"}
                >
                  {value["Family and Type"] + " + " + key}
                </span>
              </div>
            )}
          </label>
        </li>
      );
    });
  };
  const wholeContext = (event) => {
    event.preventDefault();
  };
  const MetaData = props.metaData;
  // console.log("meta", MetaData);

  const webContents = (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <LSideBar
        isToggle={props.setTreeViewStatus}
        handleToggle={closeTab}
        header={"TreeView"}
      >
        {/* <table>
        <tbody>
          <tr>
            <th>Property Name</th>
            <th>Value</th>
          </tr>
          {Object.entries(props.properties).map(([key, value]) => (
            <tr
              className="property-row"
              onMouseUp={() => handleSelectList(key, value)}
            >
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>

      </table> */}
        <ul className="tree">
          {/* {Object.entries(MetaData.Properties).map((node, index) => (
          <li key={index}>{renderTree(MetaData.Properties)}</li>
        ))} */}
          {renderTree(MetaData?.TreeView)}
        </ul>
        {/* <div id="treeViewContainer"></div> */}
      </LSideBar>
      <div>
        {contextMenuVisible && (
          <ContextMenu
            items={contextMenuItems}
            position={contextMenuPosition}
            onClose={closeContextMenu}
          />
        )}
      </div>
    </>
  );

  return props.setTreeViewStatus && Constants.DESKTOP_APP ? (
    <DesktopAppModalWrapper
      title={"treeview"}
      children={webContents}
      state={props.setTreeViewStatus}
      onClose={closeTab}
      styles={{
        width: "460px",
      }}
    />
  ) : (
    webContents
  );
};

const mapStateToProps = (state) => {
  return {
    setTreeViewStatus: state.TreeViewReducer.setTreeViewStatus,
    viewer: state.xeokitReducer.viewer,
    selectedEntities: state.xeokitReducer.selectedEntities,
    metaData: state.ProjectReducer.metaData,
  };
};

const mapDispatchToProps = {
  setTreeViewStatusAction,
  setSelectedEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeViewTab);
// metaObjects
// 2gRXFgjRn2HPE$YoDLX3FV

// "id": "1xS3BCk291UvhgP2dvNMQJ"
