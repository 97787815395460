import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import Thumbnail from "../Thumbnail/Thumbnail";

import { CustomToast } from "../../utlity/toastify";
import Modal from "../../components/Modal/Modal";
import { IconButton } from "../../components/Button/Button";
import "./Header.css";
import Box from "../../components/Box/Box";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Logo from "../../asset/logo.svg";
import Measure from "../../asset/measurement.svg";
import Cut from "../../asset/cut.svg";
import Save from "../../asset/save.svg";
import Restart from "../../asset/restart.svg";
import MINIMISE from "../../asset/minimise.svg";
import Lock from "../../asset/lock.svg";
import Redo from "../../asset/redo.svg";
import ModelUpdate from "../../asset/modelUpdate.svg";
import MySpinner from "../../components/Spinner/Spinner";
import { Button } from "../../components/Button/Button";
import io from "socket.io-client";
import { apiUrl, modelUrl } from "../../config/apiUrl";

import Measurement from "./Measurement";

import { math } from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import {
  seSelectEntityStatusAction,
  setSectionCutBtnStatusAction,
  setMeasurementBtnStatusAction,
  setResetSectionPlaneBtnStatusAction,
  saveCurrentViewerAction,
  saveViewerAction,
  handleLoadingAction,
  handleViewerAction,
  saveProject,
} from "../../action/xeokitAction";

const Header = (props) => {
  const {
    viewer,
    sectionPlanePlugin,
    seSelectEntityStatusAction,
    handleLoadingAction,
    saveProject,
    user,
    projectId,
    projectName,
    token,
  } = props;

  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [isNewModelCreated, setIsNewModelCreated] = useState(false);

  const [menuBox, setMenuBox] = useState({
    isOpen: null,
    width: 278,
    height: 343,
    position: "absolute",
    top: 53,
    left: 10,
  });
  const [measurementBox, setMeasurementBox] = useState({
    isOpen: null,
    width: 332,
    height: 375,
    position: "absolute",
    top: 53,
    left: 20,
    isStraightLine: false,
    isTwoPoints: false,
    isTwoObjects: false,
    isElevation: false,
  });
  const [cutBox, setCutBox] = useState({
    isOpen: null,
    width: 332,
    height: 375,
    position: "absolute",
    top: 53,
    left: 20,
  });

  const [saveModal, setSaveModal] = useState({
    isOpen: false,
    height: 230,
    saveName: null,
    inputError: null,
  });

  const [menuOptions, setMenuOptions] = useState([
    "Project",
    "Model Compare page",
    "Edit Preferences",
    "Sync",
    "Share",
    "Help",
    "User",
  ]);
  const [measurementOptions, setMeasurementOptions] = useState([
    "Straight Line",
    "Two Points",
    "Two Objects",
    "Elevation",
  ]);

  const [optionClickHandler, setOptionClickHandler] = useState({
    isMenuClicked: "",
    isMeasurementClicked: "",
  });

  const [sectionPlanes, setSectionPlanes] = useState(
    Array.from({ length: 6 }, (_, i) => ({
      id: i,
      sectionPlane: null,
      isOpen: i === 0,
      lock: false,
    }))
  );
  const [sectionPlaneInputEvent, setSectionPlaneInputEvent] = useState(null);
  const [currentSectionPlaneId, setCurrentSectionPlaneId] = useState({ id: 0 });
  const [currentSectionPlane, setCurrentSectionPlane] = useState(
    Array.from({ length: 6 }, (_, i) => ({
      sectionPlane: false,
      lock: false,
    }))
  );

  const handleBtnClick = (btnType) => {
    if (btnType === "MeasureBtn") {
      measurementBox.isOpen
        ? setMeasurementBox({
            ...measurementBox,
            isOpen: false,
            isStraightLine: false,
            isTwoPoints: false,
            isTwoObjects: false,
            isElevation: false,
          })
        : setMeasurementBox({ ...measurementBox, isOpen: true });
      setOptionClickHandler({
        ...optionClickHandler,
        isMeasurementClicked: null,
      });
    } else if (btnType === "SectionCutBtn") {
      cutBox.isOpen
        ? setCutBox({
            ...cutBox,
            isOpen: false,
          })
        : setCutBox({ ...cutBox, isOpen: true });
      // if (props.sectionCutBtn === null || props.sectionCutBtn === false) {
      //   props.setSectionCutBtnStatusAction(true);
      //   setCutBox({ ...cutBox, isOpen: true });
      // } else {
      //   props.setSectionCutBtnStatusAction(false);
      //   setCutBox({ ...cutBox, isOpen: false });
      // }
    } else if (btnType === "ResetBtn") {
      console.log("Viewer", viewer._plugins);
      viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
      viewer.scene.setObjectsXRayed(viewer.scene.xrayedObjectIds, false);
      viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);
      viewer.scene.setObjectsColorized(viewer.scene.colorizedObjectIds, false);
      viewer.scene.setObjectsOpacity(viewer.scene.opacityObjectIds, true);
      viewer._plugins.forEach((plugin) => {
        if (plugin.id === "SectionPlanes") {
          plugin.clear();
        } else if (plugin.id === "Annotations") {
          plugin.clear();
        } else if (plugin.id === "DistanceMeasurements") {
          plugin.clear();
        }
      });
      CustomToast("Reset Successfully", "affirmative");
    } else {
      setSaveModal({ ...saveModal, isOpen: true });
    }
  };

  const handleMeasurementBtn = (type) => {
    props.viewer.scene.setObjectsSelected(
      props.viewer.scene.selectedObjectIds,
      false
    );
    switch (type) {
      case measurementOptions[1]:
        setMeasurementBox({
          ...measurementBox,
          isStraightLine: false,
          isTwoPoints: true,
          isTwoObjects: false,
          isElevation: false,
        });
        break;
      case measurementOptions[2]:
        setMeasurementBox({
          ...measurementBox,
          isStraightLine: false,
          isTwoPoints: false,
          isTwoObjects: true,
          isElevation: false,
        });
        break;
      case measurementOptions[3]:
        setMeasurementBox({
          ...measurementBox,
          isStraightLine: false,
          isTwoPoints: false,
          isTwoObjects: false,
          isElevation: true,
        });
        break;
      default:
        setMeasurementBox({
          ...measurementBox,
          isStraightLine: true,
          isTwoPoints: false,
          isTwoObjects: false,
          isElevation: false,
        });
        break;
    }
    setOptionClickHandler({
      ...optionClickHandler,
      isMeasurementClicked: type,
    });
  };

  const handleMenuBtn = (type) => {
    setOptionClickHandler({ ...optionClickHandler, isMenuClicked: type });
    switch (type) {
      case "Project":
        navigate("/projects");
        break;
      case "Model Compare page":
        navigate("/comparison");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!user.id) return;
    const newSocket = io(apiUrl);
    console.log("user");
    newSocket.emit("userConnected", user.id);
    newSocket.on("newNotification", (notification) => {
      CustomToast(`New model is added`, "affirmative");
      setIsNewModelCreated(true);
    });
  }, []);

  useEffect(() => {
    console.log("isNewmodelcreated", isNewModelCreated);
  }, [isNewModelCreated]);

  useEffect(() => {
    //when click sectioncut button, start input event to create section plane
    if (cutBox.isOpen) {
      console.log("important============================", currentSectionPlane);

      seSelectEntityStatusAction(false);
      const mouseInput = viewer.scene.input.on("dblclick", (coords) => {
        var pickResult = viewer.scene.pick({
          canvasPos: coords,
          pickSurface: true, // <<------ This causes picking to find the intersection point on the entity
        });

        if (pickResult && pickResult.worldNormal) {
          // Disallow SectionPlanes on point clouds, because points don't have normals

          if (pickResult.entity) {
            if (!pickResult.entity.isObject) {
              return;
            }
          }
          console.log("sectionPlanePlugin", sectionPlanePlugin);
          console.log("currentSectionPlaneId", currentSectionPlaneId.id);
          console.log("currentSectionPlane", currentSectionPlane);
          console.log(
            "currentSectionPlaneId.id",
            currentSectionPlane[currentSectionPlaneId.id].sectionPlane
          );
          if (
            currentSectionPlane[currentSectionPlaneId.id].sectionPlane === false //if current sectionplane is new, create a new sectionplane
          ) {
            sectionPlanes[currentSectionPlaneId.id].sectionPlane =
              sectionPlanePlugin.createSectionPlane({
                id: currentSectionPlaneId.id + 1,
                pos: pickResult.worldPos,
                dir: math.mulVec3Scalar(pickResult.worldNormal, -1),
              });

            for (let i = 0; i < currentSectionPlane.length; i++) {
              if (i === currentSectionPlaneId.id) {
                currentSectionPlane[i].sectionPlane = true;
              } else if (currentSectionPlane[i].lock)
                currentSectionPlane[i].sectionPlane = true;
              else currentSectionPlane[i].sectionPlane = false;
            }
          } else {
            //if current sectionplane is exist
            console.log("currentSectionPlaneId.id", currentSectionPlaneId.id);
            console.log("sectionPlanePlugin", sectionPlanePlugin);
            sectionPlanes[currentSectionPlaneId.id].sectionPlane.pos =
              pickResult.worldPos;
            sectionPlanes[currentSectionPlaneId.id].sectionPlane.dir =
              math.mulVec3Scalar(pickResult.worldNormal, -1);
          }
          sectionPlanePlugin.showControl(
            sectionPlanes[currentSectionPlaneId.id].id + 1
          );
        }
      });
      setSectionPlaneInputEvent(mouseInput);
    } else if (cutBox.isOpen === false) {
      seSelectEntityStatusAction(true);
      viewer.scene.input.off(sectionPlaneInputEvent);
      sectionPlanes.forEach((item) => {
        if (!item.lock && currentSectionPlane[item.id].sectionPlane) {
          sectionPlanePlugin.destroySectionPlane(item.id + 1);
          // currentSectionPlane[item.id].sectionPlane = false
        } else sectionPlanePlugin.hideControl(item.id + 1);
      });
      setCurrentSectionPlane(
        Array.from({ length: 6 }, (_, i) => ({
          sectionPlane: false,
          lock: false,
        }))
      );
    }
  }, [cutBox.isOpen]);

  const onSelectSectionPlane = (event, selectedPlane) => {
    setSectionPlanes((sectionPlanes) =>
      sectionPlanes.map((item, index) => {
        if (item.id === selectedPlane.id) {
          if (item.lock) {
            sectionPlanePlugin.showControl(item.id + 1);
          }
          //set selected sectionplane to true
          return { ...item, isOpen: true };
        } else {
          if (currentSectionPlane[item.id].sectionPlane && !item.lock) {
            //destroy un locked sectionplane
            sectionPlanePlugin.destroySectionPlane(item.id + 1);
            currentSectionPlane[item.id].sectionPlane = false;
          }
          return { ...item, isOpen: false }; //set un selected sectionplane to false
        }
      })
    );
    currentSectionPlaneId.id = selectedPlane.id; //set the current sectionplane id
  };

  const onLockSectionPlane = (event, selectedPlane) => {
    if (!currentSectionPlane[selectedPlane.id].sectionPlane) return;
    setSectionPlanes((sectionPlanes) =>
      sectionPlanes.map((item, index) =>
        item.id === selectedPlane.id ? { ...item, lock: !item.lock } : item
      )
    );
    currentSectionPlane[selectedPlane.id].lock =
      !currentSectionPlane[selectedPlane.id].lock;
  };

  const onResetSectionPlane = (event, selectedPlane) => {
    props.viewer.scene.setObjectsSelected(
      props.viewer.scene.selectedObjectIds,
      false
    );
    setSectionPlanes((sectionPlanes) =>
      sectionPlanes.map((item, index) => {
        if (item.id === selectedPlane.id) {
          sectionPlanePlugin.destroySectionPlane(item.id + 1);
          return { ...item, lock: false, sectionPlane: null };
        } else {
          return { ...item }; //set un selected sectionplane to false
        }
      })
    );
    currentSectionPlane[selectedPlane.id].lock = false;
    currentSectionPlane[selectedPlane.id].sectionPlane = false;
  };

  const onSaveViewer = async () => {
    if (!saveModal.saveName) {
      setSaveModal({ ...saveModal, inputError: "Please Input Name" });
      return;
    }
    const viewPoints = props.bcfViewPoint.getViewpoint({ snapshot: false });
    const JsonViewPoints = JSON.stringify(viewPoints);
    console.log("/??????", viewer?._plugins);
    const annotations = viewer?._plugins
      ?.map((plugin, index) =>
        plugin.id === "Annotations"
          ? Object.entries(plugin.annotations).length !== 0
            ? Object.entries(plugin.annotations).map((annotation, i) =>
                annotation !== undefined
                  ? {
                      id: annotation[1].id,
                      entity: annotation[1].entity?.id,
                      occludable: annotation[1]._occludable,
                      markerShown: annotation[1]._markerShown,
                      labelShown: annotation[1]._labelShown,
                      _values: annotation[1]._values,
                      _worldPos: annotation[1]._worldPos,
                      _viewPos: annotation[1]._viewPos,
                      _origin: annotation[1]._origin,
                      _rtcPos: annotation[1]._rtcPos,
                      _canvasPos: annotation[1]._canvasPos,
                    }
                  : null
              )
            : null
          : null
      )
      .filter(Boolean);

    const measurements = viewer?._plugins
      ?.map((plugin, index) =>
        plugin.id === "DistanceMeasurements"
          ? Object.entries(plugin._measurements).length !== 0
            ? Object.entries(plugin._measurements).map((measurement, i) => ({
                id: measurement[1].id,
                origin: {
                  entity: measurement[1]._originDot._entity.id,
                  worldPos: measurement[1]._originDot._worldPos,
                },
                target: {
                  entity: measurement[1]._targetDot._entity.id,
                  worldPos: measurement[1]._targetDot._worldPos,
                },
              }))
            : null
          : null
      )
      .filter(Boolean);

    const url = BaseURL(`viewers`);
    const params = {
      name: saveModal.saveName,
      bcf: {
        viewPoints: JsonViewPoints,
        annotations: annotations,
        measurements: measurements,
      },
      projects: [`${props.projectName}`],
    };

    handleLoadingAction(true);
    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Saved Successfully", "affirmative");
      handleViewerAction(true);
    }
    handleLoadingAction(false);
  };

  const onUpdateModel = async () => {
    const url = BaseURL(`models/latest/projects/${projectId}`);
    handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("responseModel", response);

      const jsonUrl = modelUrl(response.data?.json);
      const jsonResponse = await Get(jsonUrl, props.token);
      console.log(jsonResponse);

      if (jsonResponse !== undefined) {
        props.saveProject({
          projectId: projectId,
          projectName: projectName,
          model: response.data?.fileName,
          json: response.data?.json,
          metaData: jsonResponse.data,
        });
      }
    }
    handleLoadingAction(false);
    console.log("render");
    window.location.reload(true);
    // navigate(0);
  };
  return (
    <>
      <div className="header">
        <div className="logo">
          <img src={Logo} width={30} height={30} alt="Logo" />
          <div
            className="header-dropdown-icon"
            onClick={() => setMenuBox({ ...menuBox, isOpen: !menuBox.isOpen })}
          />
        </div>
        <div className="header-toolbar">
          <div className="header-toolbar-box">
            <img
              className={
                cutBox.isOpen || saveModal.isOpen
                  ? "measurement hide "
                  : measurementBox.isOpen
                  ? "measurement measurement-clicked show"
                  : "measurement show"
              }
              src={Measure}
              width={26}
              height={26}
              alt="measurement"
              onClick={() => handleBtnClick("MeasureBtn")}
            />
          </div>
          <div className="header-toolbar-box">
            <img
              className={
                measurementBox.isOpen || saveModal.isOpen
                  ? "cut hide"
                  : cutBox.isOpen
                  ? "cut cut-clicked show"
                  : "cut show"
              }
              src={Cut}
              width={26}
              height={26}
              alt="cut"
              onClick={() => handleBtnClick("SectionCutBtn")}
            />
          </div>
          {/* <div className="header-toolbar-box">
            <img
              className={
                measurementBox.isOpen || cutBox.isOpen
                  ? "save hide"
                  : saveModal.isOpen
                  ? "save save-clicked show"
                  : "save show"
              }
              src={Save}
              width={26}
              height={26}
              alt="save"
              onClick={() => handleBtnClick("SaveBtn")}
            />
          </div> */}
          <div className="header-toolbar-box">
            <img
              className={
                measurementBox.isOpen || cutBox.isOpen || saveModal.isOpen
                  ? "restart hide"
                  : "restart show"
              }
              src={Restart}
              width={26}
              height={26}
              alt="restart"
              onClick={() => handleBtnClick("ResetBtn")}
            />
          </div>
          <div className="header-toolbar-box header-toolbar-controller">
            <div className="header-toolbar-controller-elipses">
              <div className="header-toolbar-controller-elipses-row">
                <span />
                <span />
              </div>
              <div className="header-toolbar-controller-elipses-row">
                <span />
                <span />
              </div>
              <div className="header-toolbar-controller-elipses-row">
                <span />
                <span />
              </div>
              <div className="header-toolbar-controller-elipses-row">
                <span />
                <span />
              </div>
            </div>
            <div className="header-toolbar-controller-line"></div>
          </div>
          <div>
            {/* <Button
              buttonName={"Update Model"}
              onClick={() => onUpdateModel()}
              className={isNewModelCreated ? "updateModel-btn" : ""}
            /> */}

            <div className="header-toolbar-box">
              <img
                className={isNewModelCreated ? "updateModel-btn" : ""}
                src={ModelUpdate}
                width={26}
                height={26}
                alt="modelUpdate"
                onClick={() => onUpdateModel()}
              />
            </div>
          </div>
          {/* <MySpinner visible={true} /> */}
        </div>
      </div>
      <Box
        isToggle={menuBox.isOpen}
        style={{
          width: menuBox.width,
          height: menuBox.height,
          position: "absolute",
          top: menuBox.top,
          left: menuBox.left,
          zIndex: 1,
        }}
      >
        <div className="menubox-container">
          <div className="menubox-body">
            {menuOptions.map((option, i) => (
              <div
                className={
                  optionClickHandler.isMenuClicked === option
                    ? "menubox-li clicked"
                    : "menubox-li"
                }
                key={i}
                value={option}
                onClick={() => handleMenuBtn(option)}
              >
                <div>{option}</div>
              </div>
            ))}
          </div>
        </div>
      </Box>
      <Box
        isToggle={measurementBox.isOpen}
        style={{
          width: measurementBox.width,
          height: measurementBox.height,
          position: "absolute",
          top: measurementBox.top,
          left: measurementBox.left,
          zIndex: 1,
        }}
      >
        <div className="measurement-box-container">
          <div className="measurement-box-header">
            <div className="measurement-box-title">Measurements</div>
            {/* <div className="measurement-box-minimise">
              <img
                src={MINIMISE}
                width={9}
                height={13}
                alt="minimise"
                onClick={() => handleBtnClick("MeasureBtn")}
              />
            </div> */}
            <div
              className="measurement-box-minimise"
              onClick={() => handleBtnClick("MeasureBtn")}
            >
              X
            </div>
          </div>
          <div className="seperate-line"></div>
          <div className="measurement-box-body">
            <ul className="measurement-ul">
              {measurementOptions.map((option, i) => (
                <li
                  className={
                    optionClickHandler.isMeasurementClicked === option
                      ? "measurement-li clicked"
                      : "measurement-li"
                  }
                  key={i}
                  value={option}
                  onClick={() => handleMeasurementBtn(option)}
                >
                  <div>{option}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Box>
      <Box
        isToggle={cutBox.isOpen}
        style={{
          width: cutBox.width,
          height: cutBox.height,
          position: "absolute",
          top: cutBox.top,
          left: cutBox.left,
          zIndex: 1,
        }}
      >
        <div className="sectioncut-box-container">
          <div className="sectioncut-box-header">
            <div className="sectioncut-box-title">Section Cuts</div>
            <div
              className="sectioncut-box-minimise"
              onClick={() => handleBtnClick("SectionCutBtn")}
            >
              X
            </div>
          </div>
          <div className="seperate-line"></div>
          <div className="sectioncut-box-body">
            <div className="sectioncut-ul">
              {sectionPlanes.map((item, i) => (
                <div className="sectioncut-li" key={i}>
                  <div className="sectioncut-name">{`SectionPlane${
                    i + 1
                  }`}</div>
                  <div className="sectioncut-btn">
                    <div className="sectioncut-switch">
                      <ToggleSwitch
                        label={item.id}
                        switchStatus={item.isOpen}
                        onChange={(event) => onSelectSectionPlane(event, item)}
                      />
                    </div>
                    <div className="sectioncut-lock">
                      <img
                        src={Lock}
                        width={20}
                        height={22}
                        alt="lock"
                        onClick={(event) => onLockSectionPlane(event, item)}
                        className={item.lock ? "lock-clicked" : "lock"}
                      />
                    </div>
                    <div className="sectioncut-redo">
                      <img
                        src={Redo}
                        width={18}
                        height={18}
                        alt="redo"
                        onClick={(event) => onResetSectionPlane(event, item)}
                        className="redo"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Box>
      <Measurement
        isOpen={measurementBox.isOpen}
        isTwoPoints={measurementBox.isTwoPoints}
        isTwoObjects={measurementBox.isTwoObjects}
        isElevation={measurementBox.isElevation}
      />
      <Modal
        isToggle={saveModal.isOpen}
        handleToggle={() => setSaveModal({ ...saveModal, isOpen: false })}
        header={"Save viewer"}
        height={saveModal.height}
      >
        <div className="transparency-btn-container">
          <div className="transparency-btn-input">
            <input
              className="mycustom-button"
              onChange={(e) => {
                setSaveModal({
                  ...saveModal,
                  saveName: e.target.value,
                  inputError: null,
                });
              }}
              placeholder="Please input save name"
            />
            {saveModal.inputError && (
              <p className="input-error">{saveModal.inputError}</p>
            )}
          </div>

          <div className="transparency-btn-save">
            <IconButton
              buttonName={"Save"}
              buttonType={"Save"}
              onClick={() => onSaveViewer()}
            />
          </div>
        </div>
      </Modal>
      <Thumbnail isToggle={cutBox.isOpen} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionCutBtn: state.xeokitReducer.sectionCutBtn,
    measureBtn: state.xeokitReducer.measureBtn,
    selectEntityBtn: state.xeokitReducer.selectEntityBtn,
    resetSectionPlaneBtn: state.xeokitReducer.resetSectionPlaneBtn,
    viewer: state.xeokitReducer.viewer,
    saveViewerArray: state.xeokitReducer.saveViewerArray,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    bcfViewPoint: state.xeokitReducer.bcfViewPoint,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
    measurementPlugin: state.xeokitReducer.measurementPlugin,
    saveSectionPlanceCanvas: state.xeokitReducer.saveSectionPlanceCanvas,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
    user: state.AuthReducer.user,
  };
};

const mapDispatchToProps = {
  seSelectEntityStatusAction,
  setSectionCutBtnStatusAction,
  setMeasurementBtnStatusAction,
  setResetSectionPlaneBtnStatusAction,
  saveCurrentViewerAction,
  saveViewerAction,
  handleLoadingAction,
  handleViewerAction,
  saveProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
