import React, { useState } from "react";
import "./ToggleSwitch.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./ToggleSwitch.css';
import { Constants } from "../../constant/constants";

const ToggleSwitch = ({ label, switchStatus, onChange }) => {
  const [isChecked, setIsChecked] = useState(switchStatus);
  const handleToggle = (event) => {
    console.log("checked", event.target.checked);
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };
  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div className="toggle-container">
        <div className="toggle-switch">
          <input
            type="checkbox"
            className="checkbox"
            id={label}
            checked={switchStatus}
            onChange={handleToggle}
          />
          <label className="label" htmlFor={label}>
            <span className="inner" />
            <span className="switch" />
          </label>
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
