import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import RSideBar from "../../components/RSideBar/RSideBar";
import "./AppearenceTemplateTab.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./AppearenceTemplateTab.css';

import {
  handleAppearanceTemplateAction,
  searchAppearenceTemplateListAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";

const AppearenceTemplateTab = (props) => {
  const {
    viewer,
    isAppearanceTemplateOpen,
    projectId,
    token,
    handleLoadingAction,
    handleAppearanceTemplateAction,
    searchAppearenceTemplateListAction,
  } = props;

  const [templateList, setTemplateList] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `appearances/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response?.data) {
      setTemplateList(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  useEffect(() => {
    if (!isAppearanceTemplateOpen) return;
    getAllListing();
  }, [isAppearanceTemplateOpen]);

  const closeTab = () => {
    handleAppearanceTemplateAction(false);
  };

  const searchAppearenceTemplate = (e, item) => {
    console.log("item", item);
    viewer.scene.setObjectsColorized(viewer.scene.colorizedObjectIds, false);
    searchAppearenceTemplateListAction(item);
  };

  const webContents = (
    <RSideBar
      isToggle={isAppearanceTemplateOpen}
      handleToggle={() => handleAppearanceTemplateAction(false)}
      header={"Appearance Templates"}
    >
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <ul className="appearence-template-ul">
        {templateList.map((item, i) => (
          <li
            onClick={(e) => searchAppearenceTemplate(e, item)}
            className="appearance-template-li"
            key={i}
            value={item.name}
          >
            <div className="appearence-template-name">{item.name}</div>
          </li>
        ))}
      </ul>
    </RSideBar>
  );

  return isAppearanceTemplateOpen && Constants.DESKTOP_APP ? (
    <DesktopAppModalWrapper
      title={"appearance-template"}
      children={webContents}
      state={isAppearanceTemplateOpen}
      onClose={() => handleAppearanceTemplateAction(false)}
      styles={{
        width: "280px",
      }}
    />
  ) : (
    webContents
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    isAppearanceTemplateOpen:
      state.AppearenceTemplateReducer.isAppearanceTemplateOpen,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  handleAppearanceTemplateAction,
  searchAppearenceTemplateListAction,
  handleLoadingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppearenceTemplateTab);
