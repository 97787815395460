export class JsonComparer {
    constructor() {
        this.excludedPropertyNames = ['Area', '23', '_id'];
        this.legendElementBase = null;
        this.legendElementCompare = null;
        this.elementIdToNameDicBase = new Map();
        this.elementIdToNameDicCompare = new Map();
    }

    compareAndWriteReportStr(baseJsonStr, compareJsonStr) {
        try {
            // Parse JSON strings
            const baseJson = JSON.parse(baseJsonStr);
            const compareJson = JSON.parse(compareJsonStr);

            // Extract legend elements
            this.legendElementBase = baseJson.Legend || null;
            this.legendElementCompare = compareJson.Legend || null;

            // Extract TreeView elements and process family/type info
            if (baseJson.TreeView) {
                this.extractFamilyAndType(baseJson.TreeView, this.elementIdToNameDicBase);
            }
            if (compareJson.TreeView) {
                this.extractFamilyAndType(compareJson.TreeView, this.elementIdToNameDicCompare);
            }

            // Generate comparison report
            const report = this.compareJsonObjects(baseJson, compareJson);
            return JSON.stringify(report, null, 2);
        } catch (err) {
            console.error("Error in compareAndWriteReportStr:", err);
            return null;
        }
    }

    compareJsonObjects(obj1, obj2) {
        const baseJsonGuid = obj1.Guid || '';
        const compareJsonGuid = obj2.Guid || '';

        const report = {
            ComparePair: {
                BaseJsonGuid: baseJsonGuid,
                CompareJsonGuid: compareJsonGuid
            },
            Added: {},
            Deleted: {},
            Changed: {}
        };

        this.compareElements(obj1, obj2, '', report);
        return report;
    }

    compareElements(obj1, obj2, path, report) {
        if (!obj1 || !obj2) return;

        const obj1Properties = new Set(Object.keys(obj1));
        const obj2Properties = new Set(Object.keys(obj2));

        // Process properties in base JSON
        for (const key of obj1Properties) {
            let propertyName = key;

            // Skip excluded properties
            if (this.excludedPropertyNames.includes(propertyName)) {
                continue;
            }

            // Use meaningful names from the dictionaries
            if (this.elementIdToNameDicBase.has(propertyName)) {
                propertyName = this.elementIdToNameDicBase.get(propertyName);
            }

            const newPath = path ? `${path}.${propertyName}` : propertyName;

            if (obj2.hasOwnProperty(key)) {
                const obj1Value = obj1[key];
                const obj2Value = obj2[key];

                // Handle nested objects
                if (this.isObject(obj1Value) && this.isObject(obj2Value)) {
                    this.compareElements(obj1Value, obj2Value, newPath, report);
                } else if (!this.areValuesEqual(obj1Value, obj2Value)) {
                    report.Changed[newPath] = {
                        from: obj1Value,
                        to: obj2Value
                    };
                }
            } else {
                report.Deleted[newPath] = obj1[key];
            }
        }

        // Find added elements
        for (const key of obj2Properties) {
            if (!obj1Properties.has(key)) {
                let propertyName = key;

                // Skip excluded properties
                if (this.excludedPropertyNames.includes(propertyName)) {
                    continue;
                }

                // Use meaningful names from the dictionaries
                if (this.elementIdToNameDicCompare.has(propertyName)) {
                    propertyName = this.elementIdToNameDicCompare.get(propertyName);
                }

                const newPath = path ? `${path}.${propertyName}` : propertyName;
                report.Added[newPath] = obj2[key];
            }
        }
    }

    isObject(value) {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    areValuesEqual(value1, value2) {
        return JSON.stringify(value1) === JSON.stringify(value2);
    }

    extractFamilyAndType(treeView, elementIdToNameDic) {
        if (typeof treeView !== 'object' || treeView === null) {
            return;
        }

        Object.entries(treeView).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                if (value['Family and Type']) {
                    elementIdToNameDic.set(key, value['Family and Type']);
                }
                this.extractFamilyAndType(value, elementIdToNameDic);
            }
        });
    }
} 