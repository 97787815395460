import React, { useRef, useState, useEffect } from "react";
import TextBox from "./components/TextBox";
import Callout from "./components/Callout";
import {
  findNearestControlPoint,
  calculateControlPoints,
  renderCallout,
  renderTextBox,
} from "./utils/annotationUtils";
import "./CustomCanvas.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./CustomCanvas.css';
import { Constants } from "../../constant/constants";

// Custom Button component
const CustomButton = ({ buttonName, onClick, ...props }) => (
  <button className="button" onClick={onClick} {...props}>
    {buttonName}
  </button>
);

// Custom Dropdown component
const CustomDropdown = ({ optionArray, onChange, value }) => (
  <select
    className="dropdown"
    onChange={onChange}
    style={{ padding: "5px", margin: "5px" }}
    value={value}
  >
    {optionArray.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </select>
);

const Canvas = ({ backgroundImage, cancel, updateMarkup, type }) => {
  const [annotations, setAnnotations] = useState([]);
  const [currentText, setCurrentText] = useState("");
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [creationStep, setCreationStep] = useState(0);
  const [arrowPreview, setArrowPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedControlPoint, setDraggedControlPoint] = useState(null);
  const [tool, setTool] = useState("select");
  const [shapes, setShapes] = useState([]);
  const [selectedShape, setSelectedShape] = useState(null);

  const dragStartPos = useRef({ x: 0, y: 0 });
  const dragStartAnnotation = useRef(null);
  const canvasRef = useRef(null);
  const drawingContextRef = useRef(null);
  const historyContextRef = useRef(null);
  const backgroundRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [lineWidth, setLineWidth] = useState(5);
  const [color, setColor] = useState("#000000");
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const lastDrawPoint = useRef(null);
  const penPoints = useRef([]);

  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const dragStartShape = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    canvas.setAttribute("willReadFrequently", "true");

    initializeCanvas(canvas, context);
    loadBackgroundImage(backgroundImage, context, type);
  }, [backgroundImage, type]);

  useEffect(() => {
    if (shapes.length >= 0) {
      redrawMainCanvas();
    }
  }, [shapes]);

  const initializeCanvas = (canvas, context) => {
    // Set canvas size to match window
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    // Remove the scale transform as we'll handle scaling in coordinates
    context.lineCap = "round";
    canvas.setAttribute("willReadFrequently", "true");

    drawingContextRef.current = createOffscreenCanvas(canvas);
    historyContextRef.current = createOffscreenCanvas(canvas);
  };

  const createOffscreenCanvas = (sourceCanvas) => {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = sourceCanvas.width;
    offscreenCanvas.height = sourceCanvas.height;
    offscreenCanvas.setAttribute("willReadFrequently", "true");
    return offscreenCanvas.getContext("2d", { willReadFrequently: true });
  };

  const loadBackgroundImage = (src, context, type) => {
    const image = new Image();
    if (type === "url") image.src = URL.createObjectURL(src);
    else image.src = src;
    image.onload = () => {
      backgroundRef.current = image;
      redrawMainCanvas();
    };
  };

  const getAdjustedCoordinates = (event, forAnnotation = false) => {
    if (!event || !canvasRef.current) return null;

    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
    return {
      x: (event.clientX - rect.left) * (forAnnotation ? scaleX / 2 : scaleX),
      y: (event.clientY - rect.top) * (forAnnotation ? scaleY / 2 : scaleY),
    };
  };

  const startDrawing = (event) => {
    const { x, y } = getAdjustedCoordinates(event, false);

    if (tool === "select") {
      console.log("[startDrawing] Select mode - current tool:", tool);
      // Clear any existing selection first
      setSelectedShape(null);
      setSelectedAnnotation(null);

      // Try to find a shape that was clicked
      const clickedShapeIndex = findClickedShape(x, y);
      console.log("[startDrawing] Click coordinates:", { x, y });
      console.log("[startDrawing] Found shape index:", clickedShapeIndex);
      if (clickedShapeIndex !== -1) {
        const shape = shapes[clickedShapeIndex];
        console.log("[startDrawing] Found shape:", shape);
        setSelectedShape(clickedShapeIndex);
        // Start dragging if in select mode
        setIsDraggingShape(true);
        dragStartPos.current = { x, y };
        dragStartShape.current = { ...shape };
        // Force redraw after selection and focus canvas
        setTimeout(() => {
          redrawMainCanvas();
          canvasRef.current.focus();
        }, 0);
      }
      return;
    }

    if (tool === "textbox") {
      handleCanvasClick(event);
      return;
    }

    // Only start drawing if we're using a drawing tool
    console.log("[startDrawing] Starting to draw with tool:", tool);
    setStartPoint({ x, y });
    setIsDrawing(true);
    const drawCtx = drawingContextRef.current;
    drawCtx.strokeStyle = color;
    drawCtx.lineWidth = lineWidth;

    if (tool === "pen") {
      console.log("[startDrawing] Initializing pen drawing");
      penPoints.current = [{ x, y }];
      drawCtx.beginPath();
      drawCtx.moveTo(x, y);
    }
  };

  const saveToHistory = () => {
    const newHistoryEntry = {
      annotations: [...annotations],
      shapes: [...shapes],
      drawing: drawingContextRef.current
        ? drawingContextRef.current.getImageData(
            0,
            0,
            drawingContextRef.current.canvas.width,
            drawingContextRef.current.canvas.height
          )
        : null,
      color: color,
      lineWidth: lineWidth,
    };

    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(newHistoryEntry);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const finishDrawing = (event) => {
    if (!isDrawing) return;
    console.log("[finishDrawing] Starting with tool:", tool);
    setIsDrawing(false);

    const coords = event ? getAdjustedCoordinates(event, false) : null;
    if (startPoint && coords) {
      console.log("[finishDrawing] Creating new shape:", {
        startPoint,
        coords,
      });
      const newShape = {
        id: shapes.length,
        type: tool,
        startX: startPoint.x,
        startY: startPoint.y,
        endX: coords.x,
        endY: coords.y,
        color: color,
        lineWidth: lineWidth,
        points: tool === "pen" ? [...penPoints.current] : null,
      };

      console.log("[finishDrawing] New shape created:", newShape);
      const newShapes = [...shapes, newShape];

      // Draw the shape one last time before clearing
      const drawCtx = drawingContextRef.current;
      if (drawCtx) {
        drawCtx.strokeStyle = newShape.color;
        drawCtx.lineWidth = newShape.lineWidth;
        renderFinalShape(
          drawCtx,
          newShape.type,
          { x: newShape.startX, y: newShape.startY },
          { x: newShape.endX, y: newShape.endY }
        );
      }

      // Update state
      setShapes(newShapes);
      console.log(
        "[finishDrawing] Shapes array updated, length:",
        newShapes.length
      );

      // Clear any selected annotation before selecting the new shape
      setSelectedAnnotation(null);
      setCurrentText("");
      // Auto-select the newly created shape
      setSelectedShape(newShapes.length - 1);
      // Switch to select mode after shape creation
      setTool("select");
      saveToHistory();

      // Use the new shapes array for immediate redraw
      redrawMainCanvas();

      // Clear the drawing context after redraw
      if (drawingContextRef.current) {
        console.log("[finishDrawing] Clearing drawing context");
        drawingContextRef.current.clearRect(
          0,
          0,
          drawingContextRef.current.canvas.width,
          drawingContextRef.current.canvas.height
        );
      }
    }
  };

  const draw = (event) => {
    if (!isDrawing || !event || tool === "textbox" || tool === "select") return;

    const coords = getAdjustedCoordinates(event, false);
    if (!coords) return;

    if (tool === "pen") {
      penPoints.current.push(coords);
      const drawCtx = drawingContextRef.current;
      if (drawCtx) {
        drawCtx.lineTo(coords.x, coords.y);
        drawCtx.stroke();
      }
    } else {
      const drawCtx = drawingContextRef.current;
      if (drawCtx) {
        drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);
        renderFinalShape(drawCtx, tool, startPoint, coords);
      }
    }

    redrawMainCanvas();
  };

  const renderFinalShape = (ctx, tool, start, end) => {
    const { x, y } = start;
    const width = end.x - x;
    const height = end.y - y;

    switch (tool) {
      case "rectangle":
        ctx.strokeRect(x, y, width, height);
        break;
      case "arrow":
        drawArrow(ctx, x, y, end.x, end.y);
        break;
      case "line":
        drawLine(ctx, x, y, end.x, end.y);
        break;
      case "circle": {
        const centerX = (start.x + end.x) / 2;
        const centerY = (start.y + end.y) / 2;
        const radius =
          Math.sqrt(
            Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
          ) / 2;
        drawCircle(ctx, centerX, centerY, radius);
        break;
      }
      default:
        break;
    }
  };

  const redrawMainCanvas = () => {
    const context = canvasRef.current.getContext("2d", {
      willReadFrequently: true,
    });
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Save the current transform
    context.save();

    if (backgroundRef.current) {
      context.drawImage(
        backgroundRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }

    shapes.forEach((shape, index) => {
      context.save();
      context.strokeStyle = shape.color;
      context.lineWidth = shape.lineWidth;

      if (shape.type === "pen" && shape.points) {
        context.beginPath();
        context.moveTo(shape.points[0].x, shape.points[0].y);
        shape.points.forEach((point) => {
          context.lineTo(point.x, point.y);
        });
        context.stroke();
      } else {
        renderFinalShape(
          context,
          shape.type,
          { x: shape.startX, y: shape.startY },
          { x: shape.endX, y: shape.endY }
        );
      }
      context.restore();
    });

    context.drawImage(drawingContextRef.current.canvas, 0, 0);

    if (selectedShape !== null) {
      const shape = shapes[selectedShape];
      drawSelectionBox(context, shape);
    }

    context.restore();
  };

  const drawArrow = (ctx, fromX, fromY, toX, toY) => {
    const headLength = 15;
    const angle = Math.atan2(toY - fromY, toX - fromX);

    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle - Math.PI / 6),
      toY - headLength * Math.sin(angle - Math.PI / 6)
    );
    ctx.moveTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle + Math.PI / 6),
      toY - headLength * Math.sin(angle + Math.PI / 6)
    );
    ctx.stroke();
  };

  const drawLine = (ctx, fromX, fromY, toX, toY) => {
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.stroke();
  };

  const drawCircle = (ctx, x, y, radius) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.stroke();
  };

  const undo = () => {
    if (historyIndex <= 0) return;
    setHistoryIndex(historyIndex - 1);
    const historyEntry = history[historyIndex - 1];

    // Restore annotations and shapes
    setAnnotations(historyEntry.annotations);
    setShapes(historyEntry.shapes);

    // Clear selection since the selected shape might no longer exist
    setSelectedShape(null);
    setSelectedAnnotation(null);

    // Restore drawing properties
    setColor(historyEntry.color);
    setLineWidth(historyEntry.lineWidth);

    // Restore drawing
    if (historyEntry.drawing) {
      const historyCtx = drawingContextRef.current;
      historyCtx.putImageData(historyEntry.drawing, 0, 0);
    }
    redrawMainCanvas();
  };

  const redo = () => {
    if (historyIndex >= history.length - 1) return;
    setHistoryIndex(historyIndex + 1);
    const historyEntry = history[historyIndex + 1];

    // Restore annotations and shapes
    setAnnotations(historyEntry.annotations);
    setShapes(historyEntry.shapes);

    // Clear selection since the selected shape might no longer exist
    setSelectedShape(null);
    setSelectedAnnotation(null);

    // Restore drawing properties
    setColor(historyEntry.color);
    setLineWidth(historyEntry.lineWidth);

    // Restore drawing
    if (historyEntry.drawing) {
      const historyCtx = drawingContextRef.current;
      historyCtx.putImageData(historyEntry.drawing, 0, 0);
    }
    redrawMainCanvas();
  };

  const getImageData = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });

    // Save current canvas state
    const savedCanvas = document.createElement("canvas");
    savedCanvas.width = canvas.width;
    savedCanvas.height = canvas.height;
    savedCanvas.setAttribute("willReadFrequently", "true");
    const savedContext = savedCanvas.getContext("2d", {
      willReadFrequently: true,
    });
    savedContext.drawImage(canvas, 0, 0);

    // Render all annotations onto the canvas
    annotations.forEach((annotation) => {
      if (annotation.type === "callout") {
        renderCallout(context, annotation);
      } else {
        renderTextBox(context, annotation);
      }
    });

    // Get the image data with annotations
    const dataUrl = canvas.toDataURL("image/png");

    // Restore the original canvas state
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(savedCanvas, 0, 0);

    setPreviewImage(dataUrl);
    setShowPreview(true);
    updateMarkup(dataUrl);
  };

  const handleTextChange = (e) => {
    setCurrentText(e.target.value);
    if (selectedAnnotation !== null) {
      const updatedAnnotations = annotations.map((ann, index) =>
        index === selectedAnnotation ? { ...ann, text: e.target.value } : ann
      );
      setAnnotations(updatedAnnotations);
      // Don't save every keystroke to history
    }
  };

  const handleTextBlur = () => {
    setIsEditing(false);
    if (selectedAnnotation !== null) {
      saveToHistory();
    }
  };

  const handleTextKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setIsEditing(false);
    }
  };

  const handleCanvasClick = (e) => {
    if (tool === "textbox") {
      if (creationStep === 1) {
        setArrowPreview({
          end: { x: e.clientX, y: e.clientY },
          start: { x: e.clientX, y: e.clientY },
        });
        setCreationStep(2);
      } else if (creationStep === 2) {
        const TEXTBOX_WIDTH = 150;
        const TEXTBOX_HEIGHT = 40;

        let newAnnotation;
        if (arrowPreview) {
          // Creating a Callout
          newAnnotation = {
            id: annotations.length,
            text: currentText || "Type here...",
            x: e.clientX - TEXTBOX_WIDTH / 2,
            y: e.clientY,
            width: TEXTBOX_WIDTH,
            height: TEXTBOX_HEIGHT,
            connectedControlPoint: 1,
            arrowStart: { x: e.clientX, y: e.clientY },
            arrowEnd: arrowPreview.end,
            type: "callout",
          };
        } else {
          // Creating a plain TextBox
          newAnnotation = {
            id: annotations.length,
            text: currentText || "Type here...",
            x: e.clientX - TEXTBOX_WIDTH / 2,
            y: e.clientY - TEXTBOX_HEIGHT / 2,
            width: TEXTBOX_WIDTH,
            height: TEXTBOX_HEIGHT,
            type: "textbox",
          };
        }

        const newAnnotations = [...annotations, newAnnotation];
        setAnnotations(newAnnotations);

        // Clear any selected shape before selecting the new annotation
        setSelectedShape(null);
        setCreationStep(0);
        setArrowPreview(null);
        setCurrentText("");
        setSelectedAnnotation(newAnnotations.length - 1);
        setTool("select");
        saveToHistory();
      }
    } else if (tool === "select") {
      const coords = getAdjustedCoordinates(e, true);
      if (!coords) return;

      // First check for annotations since they should be on top
      const clickedAnnotation = findClickedAnnotation(e);
      if (clickedAnnotation !== -1) {
        // Clear shape selection when selecting an annotation
        setSelectedShape(null);
        setSelectedAnnotation(clickedAnnotation);
        setCurrentText(annotations[clickedAnnotation].text);
        canvasRef.current.focus();
        return;
      }

      // Then check for shapes
      const clickedShapeIndex = findClickedShape(coords.x, coords.y);
      if (clickedShapeIndex !== -1) {
        // Clear annotation selection when selecting a shape
        setSelectedAnnotation(null);
        setCurrentText("");
        setSelectedShape(clickedShapeIndex);
        canvasRef.current.focus();
        return;
      }

      // If nothing was clicked, clear all selections
      setSelectedShape(null);
      setSelectedAnnotation(null);
      setCurrentText("");
    }
  };

  const handleAnnotationClick = (e, index) => {
    e.stopPropagation();
    console.log("[Annotation Click] Selecting annotation:", index);
    console.log("[Annotation Click] Previous state:", {
      selectedShape,
      selectedAnnotation,
    });

    // Clear shape selection when clicking an annotation
    setSelectedShape(null);
    setSelectedAnnotation(index);
    setCurrentText(annotations[index].text);

    // Focus the canvas and add a small delay to ensure state is updated
    setTimeout(() => {
      console.log("[Annotation Click] Focusing canvas");
      canvasRef.current.focus();
    }, 0);

    console.log("[Annotation Click] New state:", {
      selectedShape: null,
      selectedAnnotation: index,
    });
  };

  const handleDragStart = (e, index) => {
    e.stopPropagation();
    setIsDragging(true);
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleControlPointDrag = (e, index, controlPointIndex) => {
    e.stopPropagation();
    setSelectedAnnotation(index);
    setDraggedControlPoint({
      type: "resize",
      annotationIndex: index,
      controlPointIndex,
    });
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleArrowControlDrag = (e, index, isStart) => {
    e.stopPropagation();
    setSelectedAnnotation(index);
    setDraggedControlPoint({
      type: isStart ? "arrowStart" : "arrowEnd",
      annotationIndex: index,
    });
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleDoubleClick = (index) => {
    setSelectedAnnotation(index);
    setIsEditing(true);
    setCurrentText(annotations[index].text);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (creationStep === 2 && tool === "textbox" && arrowPreview) {
        const { x, y } = getAdjustedCoordinates(e, true);
        setArrowPreview((prev) => ({ ...prev, start: { x, y } }));
        return;
      }

      // Add shape dragging logic first to handle it separately from annotations
      if (isDraggingShape && selectedShape !== null && dragStartShape.current) {
        const coords = getAdjustedCoordinates(e, false);
        if (!coords) return;

        const updatedShapes = shapes.map((shape, index) => {
          if (index === selectedShape) {
            if (shape.type === "pen" && shape.points) {
              // For pen shapes, calculate the absolute position difference
              const dx = coords.x - dragStartPos.current.x;
              const dy = coords.y - dragStartPos.current.y;

              return {
                ...shape,
                points: dragStartShape.current.points.map((point) => ({
                  x: point.x + dx,
                  y: point.y + dy,
                })),
              };
            } else {
              // For other shapes, move directly to new position based on cursor movement
              const dx = coords.x - dragStartPos.current.x;
              const dy = coords.y - dragStartPos.current.y;

              return {
                ...shape,
                startX: dragStartShape.current.startX + dx,
                startY: dragStartShape.current.startY + dy,
                endX: dragStartShape.current.endX + dx,
                endY: dragStartShape.current.endY + dy,
              };
            }
          }
          return shape;
        });
        setShapes(updatedShapes);
        redrawMainCanvas();
        return;
      }

      if (!draggedControlPoint && !isDragging) return;

      const { x, y } = getAdjustedCoordinates(e, true);
      const dx = x - dragStartPos.current.x;
      const dy = y - dragStartPos.current.y;

      if (draggedControlPoint && dragStartAnnotation.current) {
        const updatedAnnotations = annotations.map((ann, index) => {
          if (index === draggedControlPoint.annotationIndex) {
            const startAnn = dragStartAnnotation.current;
            let newAnnotation = { ...ann };

            if (draggedControlPoint.type === "resize") {
              // Handle resize based on control point
              const cpIndex = draggedControlPoint.controlPointIndex;

              // Calculate new dimensions
              let newWidth = ann.width;
              let newHeight = ann.height;
              let newX = ann.x;
              let newY = ann.y;

              // Handle horizontal resize
              if (cpIndex === 0 || cpIndex === 3 || cpIndex === 5) {
                // Left side
                newWidth = Math.max(50, startAnn.width - dx);
                newX = startAnn.x + (startAnn.width - newWidth);
              } else if (cpIndex === 2 || cpIndex === 4 || cpIndex === 7) {
                // Right side
                newWidth = Math.max(50, startAnn.width + dx);
              }

              // Handle vertical resize
              if (cpIndex === 0 || cpIndex === 1 || cpIndex === 2) {
                // Top side
                newHeight = Math.max(30, startAnn.height - dy);
                newY = startAnn.y + (startAnn.height - newHeight);
              } else if (cpIndex === 5 || cpIndex === 6 || cpIndex === 7) {
                // Bottom side
                newHeight = Math.max(30, startAnn.height + dy);
              }

              newAnnotation = {
                ...newAnnotation,
                x: newX,
                y: newY,
                width: newWidth,
                height: newHeight,
              };

              // For callouts, only update the arrow connection point to the box
              if (ann.type === "callout") {
                // Keep the original arrow points
                newAnnotation.arrowStart = { ...startAnn.arrowStart };
                newAnnotation.arrowEnd = { ...startAnn.arrowEnd };

                // Only update the connection point if it's on the edge being resized
                const connectedPoint =
                  calculateControlPoints(newAnnotation)[
                    newAnnotation.connectedControlPoint
                  ];
                const absoluteConnectedPoint = {
                  x: newAnnotation.x + connectedPoint.x,
                  y: newAnnotation.y + connectedPoint.y,
                };

                // Update the arrow start point to maintain relative position to the connected point
                if (cpIndex === newAnnotation.connectedControlPoint) {
                  const dx =
                    absoluteConnectedPoint.x -
                    (startAnn.x +
                      calculateControlPoints(startAnn)[
                        startAnn.connectedControlPoint
                      ].x);
                  const dy =
                    absoluteConnectedPoint.y -
                    (startAnn.y +
                      calculateControlPoints(startAnn)[
                        startAnn.connectedControlPoint
                      ].y);
                  newAnnotation.arrowStart.x += dx;
                  newAnnotation.arrowStart.y += dy;
                }
              }
            } else if (draggedControlPoint.type === "arrowStart") {
              newAnnotation.arrowStart = {
                x: startAnn.arrowStart.x + dx,
                y: startAnn.arrowStart.y + dy,
              };
              newAnnotation.connectedControlPoint = findNearestControlPoint(
                newAnnotation,
                newAnnotation.arrowStart
              );
            } else if (draggedControlPoint.type === "arrowEnd") {
              newAnnotation.arrowEnd = {
                x: startAnn.arrowEnd.x + dx,
                y: startAnn.arrowEnd.y + dy,
              };
            }
            return newAnnotation;
          }
          return ann;
        });
        setAnnotations(updatedAnnotations);
      } else if (
        isDragging &&
        selectedAnnotation !== null &&
        dragStartAnnotation.current
      ) {
        const updatedAnnotations = annotations.map((ann, index) => {
          if (index === selectedAnnotation) {
            const startAnn = dragStartAnnotation.current;
            const baseUpdate = {
              ...ann,
              x: startAnn.x + dx,
              y: startAnn.y + dy,
            };

            if (ann.type === "callout") {
              return {
                ...baseUpdate,
                arrowStart: {
                  x: startAnn.arrowStart.x + dx,
                  y: startAnn.arrowStart.y + dy,
                },
                arrowEnd: {
                  x: startAnn.arrowEnd.x + dx,
                  y: startAnn.arrowEnd.y + dy,
                },
              };
            }

            return baseUpdate;
          }
          return ann;
        });
        setAnnotations(updatedAnnotations);
      }
    };

    const handleMouseUp = () => {
      if (isDraggingShape && dragStartShape.current) {
        saveToHistory();
        setIsDraggingShape(false);
        dragStartShape.current = null;
      }
      if ((isDragging || draggedControlPoint) && dragStartAnnotation.current) {
        saveToHistory();
        setIsDragging(false);
        setDraggedControlPoint(null);
        dragStartAnnotation.current = null;
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    annotations,
    draggedControlPoint,
    isDragging,
    selectedAnnotation,
    creationStep,
    tool,
    arrowPreview,
    isDraggingShape,
    selectedShape,
    shapes,
  ]);

  const drawSelectionBox = (context, shape) => {
    context.save();

    let controlPoints = [];

    switch (shape.type) {
      case "rectangle": {
        const bounds = {
          x: Math.min(shape.startX, shape.endX),
          y: Math.min(shape.startY, shape.endY),
          width: Math.abs(shape.endX - shape.startX),
          height: Math.abs(shape.endY - shape.startY),
        };

        // Draw selection border
        context.strokeStyle = "#1976d2";
        context.lineWidth = 2;
        context.strokeRect(bounds.x, bounds.y, bounds.width, bounds.height);

        // Calculate control points
        controlPoints = [
          { x: bounds.x, y: bounds.y }, // Top-left
          { x: bounds.x + bounds.width / 2, y: bounds.y }, // Top-middle
          { x: bounds.x + bounds.width, y: bounds.y }, // Top-right
          { x: bounds.x, y: bounds.y + bounds.height / 2 }, // Middle-left
          { x: bounds.x + bounds.width, y: bounds.y + bounds.height / 2 }, // Middle-right
          { x: bounds.x, y: bounds.y + bounds.height }, // Bottom-left
          { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height }, // Bottom-middle
          { x: bounds.x + bounds.width, y: bounds.y + bounds.height }, // Bottom-right
        ];
        break;
      }

      case "circle": {
        const centerX = (shape.startX + shape.endX) / 2;
        const centerY = (shape.startY + shape.endY) / 2;
        const radius =
          Math.sqrt(
            Math.pow(shape.endX - shape.startX, 2) +
              Math.pow(shape.endY - shape.startY, 2)
          ) / 2;

        // Draw selection circle
        context.strokeStyle = "#1976d2";
        context.lineWidth = 2;
        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        context.stroke();

        // Calculate control points
        controlPoints = [
          { x: centerX - radius, y: centerY }, // Left
          { x: centerX + radius, y: centerY }, // Right
          { x: centerX, y: centerY - radius }, // Top
          { x: centerX, y: centerY + radius }, // Bottom
          {
            x: centerX - radius * Math.cos(Math.PI / 4),
            y: centerY - radius * Math.sin(Math.PI / 4),
          }, // Top-left
          {
            x: centerX + radius * Math.cos(Math.PI / 4),
            y: centerY - radius * Math.sin(Math.PI / 4),
          }, // Top-right
          {
            x: centerX - radius * Math.cos(Math.PI / 4),
            y: centerY + radius * Math.sin(Math.PI / 4),
          }, // Bottom-left
          {
            x: centerX + radius * Math.cos(Math.PI / 4),
            y: centerY + radius * Math.sin(Math.PI / 4),
          }, // Bottom-right
        ];
        break;
      }

      case "line":
      case "arrow": {
        // Draw selection line
        context.strokeStyle = "#1976d2";
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(shape.startX, shape.startY);
        context.lineTo(shape.endX, shape.endY);
        context.stroke();

        // For arrows, also draw the arrowhead selection
        if (shape.type === "arrow") {
          const headLength = 15;
          const angle = Math.atan2(
            shape.endY - shape.startY,
            shape.endX - shape.startX
          );
          context.beginPath();
          context.moveTo(shape.endX, shape.endY);
          context.lineTo(
            shape.endX - headLength * Math.cos(angle - Math.PI / 6),
            shape.endY - headLength * Math.sin(angle - Math.PI / 6)
          );
          context.moveTo(shape.endX, shape.endY);
          context.lineTo(
            shape.endX - headLength * Math.cos(angle + Math.PI / 6),
            shape.endY - headLength * Math.sin(angle + Math.PI / 6)
          );
          context.stroke();
        }

        // Control points at start, end, and middle
        controlPoints = [
          { x: shape.startX, y: shape.startY }, // Start point
          { x: shape.endX, y: shape.endY }, // End point
          {
            x: (shape.startX + shape.endX) / 2,
            y: (shape.startY + shape.endY) / 2,
          }, // Middle point
        ];
        break;
      }

      case "pen": {
        if (!shape.points || shape.points.length < 2) break;

        // Draw selection outline
        context.strokeStyle = "#1976d2";
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(shape.points[0].x, shape.points[0].y);
        shape.points.forEach((point) => {
          context.lineTo(point.x, point.y);
        });
        context.stroke();

        // Add control points at start, end, and several points along the path
        const numControlPoints = Math.min(8, shape.points.length);
        for (let i = 0; i < numControlPoints; i++) {
          const index = Math.floor(
            (i * (shape.points.length - 1)) / (numControlPoints - 1)
          );
          controlPoints.push(shape.points[index]);
        }
        break;
      }
    }

    // Draw control points
    controlPoints.forEach((point) => {
      context.beginPath();
      context.arc(point.x, point.y, 4, 0, 2 * Math.PI);
      context.fillStyle = "#ffffff";
      context.fill();
      context.strokeStyle = "#1976d2";
      context.lineWidth = 2;
      context.stroke();
    });

    context.restore();
  };

  const calculatePenBounds = (points) => {
    if (!points || points.length === 0)
      return { x: 0, y: 0, width: 0, height: 0 };

    const xPoints = points.map((p) => p.x);
    const yPoints = points.map((p) => p.y);

    const minX = Math.min(...xPoints);
    const maxX = Math.max(...xPoints);
    const minY = Math.min(...yPoints);
    const maxY = Math.max(...yPoints);

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  };

  const findClickedShape = (x, y) => {
    // Check shapes in reverse order (top to bottom)
    for (let i = shapes.length - 1; i >= 0; i--) {
      const shape = shapes[i];
      if (isPointInShape(x, y, shape)) {
        return i;
      }
    }
    return -1;
  };

  const isPointInShape = (x, y, shape) => {
    // Increase padding to make selection easier
    const padding = 10;

    switch (shape.type) {
      case "rectangle":
        const rectBounds = {
          x: Math.min(shape.startX, shape.endX),
          y: Math.min(shape.startY, shape.endY),
          width: Math.abs(shape.endX - shape.startX),
          height: Math.abs(shape.endY - shape.startY),
        };

        // Add extra padding for very thin rectangles
        const minDimPadding =
          Math.min(rectBounds.width, rectBounds.height) < 20 ? 15 : padding;

        return (
          x >= rectBounds.x - minDimPadding &&
          x <= rectBounds.x + rectBounds.width + minDimPadding &&
          y >= rectBounds.y - minDimPadding &&
          y <= rectBounds.y + rectBounds.height + minDimPadding
        );

      case "circle":
        const centerX = (shape.startX + shape.endX) / 2;
        const centerY = (shape.startY + shape.endY) / 2;
        const radius =
          Math.sqrt(
            Math.pow(shape.endX - shape.startX, 2) +
              Math.pow(shape.endY - shape.startY, 2)
          ) / 2;

        const distanceFromCenter = Math.sqrt(
          Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2)
        );

        // Add extra padding for small circles
        const circlePadding = radius < 20 ? 15 : padding;
        return distanceFromCenter <= radius + circlePadding;

      case "line":
      case "arrow":
        // For lines and arrows, check if point is close to the line
        const lineLength = Math.sqrt(
          Math.pow(shape.endX - shape.startX, 2) +
            Math.pow(shape.endY - shape.startY, 2)
        );

        if (lineLength === 0) return false;

        // Calculate distance from point to line using the formula:
        // distance = |((x2-x1)(y1-y)-(x1-x)(y2-y1))|/sqrt((x2-x1)²+(y2-y1)²)
        const distance =
          Math.abs(
            (shape.endX - shape.startX) * (shape.startY - y) -
              (shape.startX - x) * (shape.endY - shape.startY)
          ) / lineLength;

        // Add extra padding for short lines
        const linePadding = lineLength < 20 ? 15 : padding;

        // Also check if the point is within the bounding box of the line
        const withinBounds =
          x >= Math.min(shape.startX, shape.endX) - linePadding &&
          x <= Math.max(shape.startX, shape.endX) + linePadding &&
          y >= Math.min(shape.startY, shape.endY) - linePadding &&
          y <= Math.max(shape.startY, shape.endY) + linePadding;

        return distance <= linePadding && withinBounds;

      case "pen":
        if (!shape.points || shape.points.length < 2) return false;

        // First do a quick bounding box check
        const bounds = calculatePenBounds(shape.points);
        const quickCheck =
          x >= bounds.x - padding * 2 &&
          x <= bounds.x + bounds.width + padding * 2 &&
          y >= bounds.y - padding * 2 &&
          y <= bounds.y + bounds.height + padding * 2;

        if (!quickCheck) return false;

        // Then check if point is close to any segment of the pen stroke
        for (let i = 1; i < shape.points.length; i++) {
          const p1 = shape.points[i - 1];
          const p2 = shape.points[i];

          const segmentLength = Math.sqrt(
            Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)
          );

          if (segmentLength === 0) continue;

          const distance =
            Math.abs((p2.x - p1.x) * (p1.y - y) - (p1.x - x) * (p2.y - p1.y)) /
            segmentLength;

          // Add extra padding for short segments
          const penPadding = segmentLength < 10 ? 15 : padding;

          const withinBounds =
            x >= Math.min(p1.x, p2.x) - penPadding &&
            x <= Math.max(p1.x, p2.x) + penPadding &&
            y >= Math.min(p1.y, p2.y) - penPadding &&
            y <= Math.max(p1.y, p2.y) + penPadding;

          if (distance <= penPadding && withinBounds) {
            return true;
          }
        }
        return false;

      default:
        return false;
    }
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setColor(newColor);

    // If there's a selected shape, update its color
    if (selectedShape !== null) {
      const updatedShapes = shapes.map((shape, index) => {
        if (index === selectedShape) {
          return {
            ...shape,
            color: newColor,
          };
        }
        return shape;
      });
      setShapes(updatedShapes);
      saveToHistory();
      redrawMainCanvas();
    }
  };

  const handleLineWidthChange = (event) => {
    const newWidth = parseInt(event.target.value, 10);
    setLineWidth(newWidth);

    // If there's a selected shape, update its line width
    if (selectedShape !== null) {
      const updatedShapes = shapes.map((shape, index) => {
        if (index === selectedShape) {
          return {
            ...shape,
            lineWidth: newWidth,
          };
        }
        return shape;
      });
      setShapes(updatedShapes);
      saveToHistory();
      redrawMainCanvas();
    }
  };

  // Add new helper function to find clicked annotation
  const findClickedAnnotation = (e) => {
    const coords = getAdjustedCoordinates(e, true);
    if (!coords) return -1;

    // Check annotations in reverse order (top to bottom)
    for (let i = annotations.length - 1; i >= 0; i--) {
      const annotation = annotations[i];
      if (isPointInAnnotation(coords, annotation)) {
        return i;
      }
    }
    return -1;
  };

  // Add new helper function to check if point is inside annotation
  const isPointInAnnotation = (point, annotation) => {
    const { x, y } = point;
    const bounds = {
      left: annotation.x,
      right: annotation.x + annotation.width,
      top: annotation.y,
      bottom: annotation.y + annotation.height,
    };

    // Check if point is inside the annotation box
    if (
      x >= bounds.left &&
      x <= bounds.right &&
      y >= bounds.top &&
      y <= bounds.bottom
    ) {
      return true;
    }

    // For callouts, also check if point is near the arrow
    if (annotation.type === "callout") {
      const arrowStart = annotation.arrowStart;
      const arrowEnd = annotation.arrowEnd;

      // Calculate distance from point to arrow line
      const lineLength = Math.sqrt(
        Math.pow(arrowEnd.x - arrowStart.x, 2) +
          Math.pow(arrowEnd.y - arrowStart.y, 2)
      );

      if (lineLength === 0) return false;

      const distance =
        Math.abs(
          (arrowEnd.x - arrowStart.x) * (arrowStart.y - y) -
            (arrowStart.x - x) * (arrowEnd.y - arrowStart.y)
        ) / lineLength;

      const padding = 10; // Same padding as used for shapes

      // Check if point is within padding distance of the arrow line
      const withinBounds =
        x >= Math.min(arrowStart.x, arrowEnd.x) - padding &&
        x <= Math.max(arrowStart.x, arrowEnd.x) + padding &&
        y >= Math.min(arrowStart.y, arrowEnd.y) - padding &&
        y <= Math.max(arrowStart.y, arrowEnd.y) + padding;

      if (distance <= padding && withinBounds) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
      <div className="imageEditor-container">
        <canvas
          ref={canvasRef}
          onMouseDown={(e) => startDrawing(e)}
          onMouseUp={(e) => {
            if (tool !== "textbox") {
              finishDrawing(e);
            }
          }}
          onMouseMove={(e) => {
            if (tool !== "select" && tool !== "textbox") {
              draw(e);
            }
          }}
          onKeyDown={(e) => {
            console.log("[Canvas KeyDown] Key pressed:", e.key);
            console.log("[Canvas KeyDown] Current state:", {
              selectedShape,
              selectedAnnotation,
              annotations: annotations.length,
              isFocused: document.activeElement === canvasRef.current,
            });

            if (e.key === "Delete") {
              if (selectedShape !== null) {
                const updatedShapes = shapes.filter(
                  (_, index) => index !== selectedShape
                );
                console.log("[Canvas Delete] Deleting shape:", selectedShape);
                setSelectedShape(null);
                setShapes(updatedShapes);
                saveToHistory();
                redrawMainCanvas();
              }
              if (selectedAnnotation !== null) {
                console.log(
                  "[Canvas Delete] Attempting to delete annotation:",
                  selectedAnnotation
                );
                const updatedAnnotations = annotations.filter(
                  (_, index) => index !== selectedAnnotation
                );
                console.log(
                  "[Canvas Delete] New annotations length:",
                  updatedAnnotations.length
                );
                setAnnotations(updatedAnnotations);
                setSelectedAnnotation(null);
                setCurrentText("");
                saveToHistory();
                redrawMainCanvas();
              }
            }
          }}
          tabIndex={0}
          style={{
            outline: "none",
          }}
          className="image-editor-canvas"
        />
        <div className="image-editor-menu-container">
          <div className="image-editor-menu">
            <CustomButton
              buttonName={"Select"}
              onClick={() => setTool("select")}
              aria-pressed={tool === "select"}
            />
            <CustomButton
              buttonName={"TextBox"}
              onClick={() => {
                setTool("textbox");
                setCreationStep(2);
                setSelectedAnnotation(null);
                setCurrentText("");
              }}
              aria-pressed={tool === "textbox" && creationStep === 2}
            />
            <CustomButton
              buttonName={"Callout"}
              onClick={() => {
                setTool("textbox");
                setCreationStep(1);
                setSelectedAnnotation(null);
                setCurrentText("");
              }}
              aria-pressed={tool === "textbox" && creationStep === 1}
            />
            <input
              type="text"
              value={currentText}
              onChange={handleTextChange}
              placeholder="Type annotation text..."
              style={{ padding: "5px", margin: "5px" }}
            />
            <CustomButton
              buttonName={"Pen"}
              onClick={() => setTool("pen")}
              aria-pressed={tool === "pen"}
            />
            <CustomButton
              buttonName={"Rectangle"}
              onClick={() => setTool("rectangle")}
              aria-pressed={tool === "rectangle"}
            />
            <CustomButton
              buttonName={"Arrow"}
              onClick={() => setTool("arrow")}
              aria-pressed={tool === "arrow"}
            />
            <CustomButton
              buttonName={"Line"}
              onClick={() => setTool("line")}
              aria-pressed={tool === "line"}
            />
            <CustomButton
              buttonName={"Circle"}
              onClick={() => setTool("circle")}
              aria-pressed={tool === "circle"}
            />
            <input
              type="color"
              value={
                selectedShape !== null && shapes[selectedShape]
                  ? shapes[selectedShape].color
                  : color
              }
              onChange={handleColorChange}
              className="color"
            />
            <CustomDropdown
              optionArray={[1, 3, 5, 7, 10]}
              onChange={handleLineWidthChange}
              value={
                selectedShape !== null && shapes[selectedShape]
                  ? shapes[selectedShape].lineWidth
                  : lineWidth
              }
            />
            <CustomButton buttonName={"Undo"} onClick={undo} />
            <CustomButton buttonName={"Redo"} onClick={redo} />
            <CustomButton buttonName={"Save"} onClick={getImageData} />
          </div>
        </div>
        <span className="image-editor-cancel-btn" onClick={() => cancel(false)}>
          X
        </span>

        {/* Preview arrow during callout creation */}
        {tool === "textbox" && creationStep === 2 && arrowPreview && (
          <svg
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 1,
            }}
          >
            <defs>
              <marker
                id="preview-arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="9"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="black" />
              </marker>
            </defs>
            <path
              d={`M ${arrowPreview.start.x} ${arrowPreview.start.y} L ${arrowPreview.end.x} ${arrowPreview.end.y}`}
              stroke="black"
              strokeWidth="2"
              fill="none"
              markerEnd="url(#preview-arrowhead)"
            />
          </svg>
        )}

        {/* Image Preview Modal */}
        {showPreview && (
          <div
            className="preview-modal"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              className="preview-content"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                maxWidth: "90%",
                maxHeight: "90%",
                overflow: "auto",
                position: "relative",
                backgroundColor: "lightblue",
              }}
            >
              <button
                onClick={() => setShowPreview(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  padding: "5px 10px",
                  border: "none",
                  background: "#ff4444",
                  color: "white",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Close
              </button>
              <img
                src={previewImage}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "calc(90vh - 60px)",
                  display: "block",
                }}
              />
            </div>
          </div>
        )}

        {/* Render annotations */}
        {annotations.map((annotation, index) =>
          annotation.type === "callout" ? (
            <Callout
              key={annotation.id}
              annotation={annotation}
              isSelected={selectedAnnotation === index}
              isDragging={isDragging}
              isEditing={isEditing && selectedAnnotation === index}
              text={currentText}
              onSelect={(e) => handleAnnotationClick(e, index)}
              onDragStart={(e) => handleDragStart(e, index)}
              onControlPointDrag={(e, cpIndex) =>
                handleControlPointDrag(e, index, cpIndex)
              }
              onArrowControlDrag={(e, isStart) =>
                handleArrowControlDrag(e, index, isStart)
              }
              onDoubleClick={() => handleDoubleClick(index)}
              onTextChange={handleTextChange}
              onTextBlur={handleTextBlur}
              onTextKeyDown={handleTextKeyDown}
            />
          ) : (
            <TextBox
              key={annotation.id}
              annotation={annotation}
              isSelected={selectedAnnotation === index}
              isDragging={isDragging}
              isEditing={isEditing && selectedAnnotation === index}
              text={currentText}
              onSelect={(e) => handleAnnotationClick(e, index)}
              onDragStart={(e) => handleDragStart(e, index)}
              onControlPointDrag={(e, cpIndex) =>
                handleControlPointDrag(e, index, cpIndex)
              }
              onDoubleClick={() => handleDoubleClick(index)}
              onTextChange={handleTextChange}
              onTextBlur={handleTextBlur}
              onTextKeyDown={handleTextKeyDown}
            />
          )
        )}
      </div>
    </>
  );
};

export default Canvas;
