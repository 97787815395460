import React from "react";
import { connect } from "react-redux";

//import Actions
import { handlePropertyAction } from "../../action/xeokitAction";

import { Constants } from "../../constant/constants";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";
import PropertyTab from "./PropertyTab";

const PropertyTabWrapper = (props) => {
  const { isPropertyOpen, handlePropertyAction } = props;

  return isPropertyOpen && Constants.DESKTOP_APP ? (
    <DesktopAppModalWrapper
      title={"property"}
      ChildrenCom={PropertyTab}
      state={isPropertyOpen}
      onClose={() => handlePropertyAction(false)}
      styles={{
        width: "460px",
      }}
    />
  ) : (
    <PropertyTab />
  );
};

const mapStateToProps = (state) => {
  return {
    isPropertyOpen: state.PropertyReducer.isPropertyOpen,
  };
};

const mapDispatchToProps = {
  handlePropertyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTabWrapper);
