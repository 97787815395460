// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.log-tab {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

}

.log-tab-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.log-tab-markup {
  border: 2px solid #cccccc;
}

.log-tab-row-title {
  font-size: 25px;
  text-align: start;
}

.log-tab-row-content {
  font-size: 20px;
}

.log-tab-row-created {
  font-size: 15px;
  color: red;
}

.log-tab-logs-container {
  height: 75%;
  width: 100%;
  overflow-y: scroll;
}

.log-tab-comment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25%;
}

.log-tab-comment-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.log-tab-comment-textarea {
  background-color: #232323;
  border-radius: 5px;
  min-height: 50px;
  color: #BFBFBF;
  max-width: 284px;
  min-width: 284px;
}

.log-tab-logs {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
}

.log-tab-logs-date {
  font-size: 20px;
  font-weight: 500;
  line-height: 15px;
}

.log-tab-logs-avatar {
  width: 30px;
  height: 30px;
  border: 1px solid #BFBFBF;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.log-tab-logs-user {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.log-tab-logs-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 15px;
}

.log-tab-logs-content {
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
  gap: 10px;
}

.log-tab-logs-comment-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}

.log-tab-logs-comment {
  width: 80%;
  height: auto;
  background-color: #1a83f4;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 15px;
  color: #BFBFBF;
  position: relative;
  /* display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 10px;
  max-width: 300px;
  color: #333;
  font-size: 14px; */
}

.chat-tail {
  position: absolute;
  bottom: -6px;
  right: -2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 10px 0 0;
  border-color: #1a83f4 transparent transparent transparent;
  transform: rotate(-45deg);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
