import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { CustomToast } from "../../utlity/toastify";

import {
  searchElementAction,
  handleLoadingAction,
  handleViewerAction,
  setDefaultViewAction,
  searchAppearenceTemplateListAction,
} from "../../action/xeokitAction";
import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post, Patch, Delete } from "../../axios/axios";
import { Constants } from "../../constant/constants";
import DefaultView from "../../asset/defaultView.svg";
import DefaultView_Variant from "../../asset/defaultView_variant.svg";
import Save from "../../asset/save.svg";
import RSideBar from "../../components/RSideBar/RSideBar";
import Modal from "../../components/Modal/Modal";
import Box from "../../components/Box/Box";
import { IconButton } from "../../components/Button/Button";
import "./ViewerTab.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./ViewerTab.css';
import LSideBar from "../../components/LSideBar/LSideBar";

const ViewerTab = (props) => {
  const {
    viewer,
    isViewerOpen,
    projectId,
    projectName,
    token,
    handleLoadingAction,
    handleViewerAction,
    bcfViewPoint,
    selectedAppearenceTemplateList,
    distanceMeasurementPlugin,
    sectionPlanePlugin,
    setDefaultViewAction,
    searchAppearenceTemplateListAction,
    winRef,
  } = props;

  const [viewerList, setViewerList] = useState([]);
  const [optionClickHandler, setOptionClickHandler] = useState({
    listItem: "",
    defaultView: "",
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();
  const boxRef = useRef(null); //box ref
  const [boxData, setBoxData] = useState({
    isOpen: false,
    top: 0,
    left: 0,
    rowData: null,
    width: 200,
    height: 180,
    offset: 40,
    zIndex: 21,
  });

  const [saveModal, setSaveModal] = useState({
    isOpen: false,
    height: 230,
    saveName: null,
    inputError: null,
  });

  const [renameModal, setRenameModal] = useState({
    isOpen: false,
    height: 230,
    saveName: null,
    inputError: null,
    id: null,
  });

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `viewers/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response?.data) {
      setViewerList(response?.data?.results);
      response?.data?.results?.map((item) => {
        console.log("defaultView????????", item.defaultView);
        if (item.defaultView) {
          setOptionClickHandler({
            ...optionClickHandler,
            defaultView: item._id,
          });
          setDefaultViewAction({
            bcf: item.bcf,
            appearanceProfile: item.appearanceProfile,
          });
        }
      });

      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  useEffect(() => {
    if (!isViewerOpen) return;
    console.log("Viewer", isViewerOpen);
    getAllListing();
  }, [isViewerOpen]);

  const loadViewer = (e, list) => {
    console.log("load", distanceMeasurementPlugin);
    console.log("load", viewer);
    // annotationPlugin.clear();
    // measurementPlugin.clear();
    setOptionClickHandler({
      ...optionClickHandler,
      listItem: list._id,
    });
    viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
    viewer.scene.setObjectsXRayed(viewer.scene.xrayedObjectIds, false);
    viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);
    viewer.scene.setObjectsColorized(viewer.scene.colorizedObjectIds, false);
    viewer.scene.setObjectsOpacity(viewer.scene.opacityObjectIds, true);
    viewer._plugins.forEach((plugin) => {
      if (plugin._id === "SectionPlanes") {
        plugin.clear();
      } else if (plugin._id === "Annotations") {
        // plugin.clear();
      } else if (plugin._id === "DistanceMeasurements") {
        // plugin.clear();
      }
    });

    console.log("//////////////////////////", list);
    const bcfViewPoints = JSON.parse(list.bcf.viewPoints);
    bcfViewPoint.setViewpoint(bcfViewPoints);

    console.log("VIeweer", bcfViewPoints);
    console.log("sectionPlanePlugin", sectionPlanePlugin);
    console.log("sectionPlanePlugin", sectionPlanePlugin);
    console.log("sectionPlanePlugin", list?.bcf?.annotations);
    searchAppearenceTemplateListAction(list.appearanceProfile);

    // list?.bcf?.annotations?.map((annotation, i) => {
    //   annotationPlugin.createAnnotation({
    //     id: annotation[0]?._id,
    //     entity: viewer.scene.objects[annotation[0]?.entity],
    //     worldPos: [
    //       annotation[0]?._worldPos[0],
    //       annotation[0]?._worldPos[1],
    //       annotation[0]?._worldPos[2],
    //     ],
    //     occludable: annotation[0]?.occludable,
    //     markerShown: annotation[0]?.markerShown,
    //     labelShown: annotation[0]?.labelShown,

    //     values: annotation[0]?._values,
    //   });
    // });

    // list?.bcf?.measurements?.map((measurement, i) =>
    //   measurement.map((ele, j) =>
    //     distanceMeasurementPlugin.createMeasurement({
    //       id: ele?._id,
    //       origin: {
    //         entity: viewer.scene.objects[ele?.origin.entity],
    //         worldPos: [
    //           ele?.origin.worldPos[0],
    //           ele?.origin.worldPos[1],
    //           ele?.origin.worldPos[2],
    //         ],
    //       },
    //       target: {
    //         entity: viewer.scene.objects[ele?.target.entity],
    //         worldPos: [
    //           ele?.target.worldPos[0],
    //           ele?.target.worldPos[1],
    //           ele?.target.worldPos[2],
    //         ],
    //       },
    //     })
    //   )
    // );

    // let sectionPlane = [];
    // props.saveViewerArray.map((element, i) => {
    //   if (element.viewerName === e.target.textContent) {
    //     const bcfViewpoints = new BCFViewpointsPlugin(props.viewer);
    //     bcfViewpoints.setViewpoint(element.viewer);
    //     console.log(props.viewer);
    //     // element.viewer.restoreObjects(props.viewer.scene);
    //     // sectionPlane = element.sectionPlane;
    //   }
    // });

    // console.log("sectionPlanes", sectionPlane);

    // sectionPlane.map((element, i) => {
    //   Object.values(props.sectionPlanePlugin.sectionPlanes).map((item, i) => {
    //     console.log("item", item);
    //     if (element._id === item._id) {
    //       console.log("????", item);
    //       item.dir = element.dir;
    //       item.pos = element.pos;
    //     }
    //   });
    // });
  };

  //handle the mouse event. When users click outside box, close the box
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      if (winRef?.current) {
        winRef.current.resizeBy(-200, 0);
      }
      setBoxData({ ...boxData, isOpen: false });
      setRenameModal({ ...renameModal, isOpen: false });
      winRef?.current
        ? winRef.current.document.removeEventListener(
            "mousedown",
            handleClickOutside
          )
        : document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  //handle table row click
  const handleRowClick = (event, id, bcf, appearanceProfile) => {
    if (winRef?.current) {
      winRef.current.resizeBy(200, 0);
    }
    const rowRect = event.currentTarget.getBoundingClientRect();
    const modalHeight = boxRef.current
      ? boxRef.current.offsetHeight
      : boxData.height;
    const viewportHeight = window.innerHeight;

    const adjustedTop =
      rowRect.top - boxData.offset + modalHeight + 5 > viewportHeight
        ? rowRect.top - boxData.offset - modalHeight + 5
        : rowRect.top - boxData.offset;

    console.log("box", adjustedTop);
    setBoxData({
      ...boxData,
      isOpen: true,
      top: adjustedTop,
      ...(Constants.DESKTOP_APP ? { left: 282 } : { right: 282 }),
      rowData: id,
      bcf: bcf,
      appearanceProfile: appearanceProfile,
    });
    winRef?.current
      ? winRef.current.document.addEventListener(
          "mousedown",
          handleClickOutside
        )
      : document.addEventListener("mousedown", handleClickOutside);
  };

  const handleDefaultView = async (type) => {
    console.log("BOxDATA", boxData);
    const url = BaseURL(`viewers/defaultViewer/${boxData.rowData}`);
    const params = {
      defaultView: type === "default" ? true : false,
      project: projectName,
    };

    console.log("url", url);
    handleLoadingAction(true);
    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Updated Succeessfully!", "affirmative");

      if (type === "default") {
        setOptionClickHandler({
          ...optionClickHandler,
          defaultView: boxData.rowData,
        });
        setDefaultViewAction({
          bcf: boxData.bcf,
          appearanceProfile: boxData.appearanceProfile,
        });
      } else {
        setOptionClickHandler({
          ...optionClickHandler,
          defaultView: null,
        });
        setDefaultViewAction({ bcf: {}, appearanceProfile: {} });
      }
    }
    handleLoadingAction(false);
  };

  const onUpdateView = async () => {
    const url = BaseURL(`viewers/${boxData.rowData}`);
    const viewPoints = props.bcfViewPoint.getViewpoint({ snapshot: false });
    const JsonViewPoints = JSON.stringify(viewPoints);
    const params = {
      bcf: { viewPoints: JsonViewPoints },
      appearanceProfile: selectedAppearenceTemplateList,
      project: projectName,
    };

    handleLoadingAction(true);
    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Updated Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    getAllListing();
  };

  const onRemoveView = async () => {
    const url = BaseURL(`viewers/${boxData.rowData}`);
    handleLoadingAction(true);
    const response = await Delete(url, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Deleted Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    getAllListing();
  };

  const onRenameView = async () => {
    console.log("log", boxData);
    setRenameModal({
      ...renameModal,
      isOpen: true,
      id: boxData.rowData,
    });
  };

  const onSaveRenameView = async () => {
    const url = BaseURL(`viewers/${renameModal._id}`);
    const params = {
      name: renameModal.saveName,
      project: projectName,
    };

    handleLoadingAction(true);
    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Updated Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    getAllListing();
  };

  const onSaveView = async () => {
    if (!saveModal.saveName) {
      setSaveModal({ ...saveModal, inputError: "Please Input Name" });
      return;
    }
    const viewPoints = props.bcfViewPoint.getViewpoint({ snapshot: false });
    const JsonViewPoints = JSON.stringify(viewPoints);
    console.log("/??????", viewer?._plugins);
    const annotations = viewer?._plugins
      ?.map((plugin, index) =>
        plugin._id === "Annotations"
          ? Object.entries(plugin.annotations).length !== 0
            ? Object.entries(plugin.annotations).map((annotation, i) =>
                annotation !== undefined
                  ? {
                      id: annotation[1]._id,
                      entity: annotation[1].entity?._id,
                      occludable: annotation[1]._occludable,
                      markerShown: annotation[1]._markerShown,
                      labelShown: annotation[1]._labelShown,
                      _values: annotation[1]._values,
                      _worldPos: annotation[1]._worldPos,
                      _viewPos: annotation[1]._viewPos,
                      _origin: annotation[1]._origin,
                      _rtcPos: annotation[1]._rtcPos,
                      _canvasPos: annotation[1]._canvasPos,
                    }
                  : null
              )
            : null
          : null
      )
      .filter(Boolean);

    const measurements = viewer?._plugins
      ?.map((plugin, index) =>
        plugin._id === "DistanceMeasurements"
          ? Object.entries(plugin._measurements).length !== 0
            ? Object.entries(plugin._measurements).map((measurement, i) => ({
                id: measurement[1]._id,
                origin: {
                  entity: measurement[1]._originDot._entity._id,
                  worldPos: measurement[1]._originDot._worldPos,
                },
                target: {
                  entity: measurement[1]._targetDot._entity._id,
                  worldPos: measurement[1]._targetDot._worldPos,
                },
              }))
            : null
          : null
      )
      .filter(Boolean);

    const url = BaseURL(`viewers`);
    const params = {
      name: saveModal.saveName,
      bcf: {
        viewPoints: JsonViewPoints,
        annotations: annotations,
        measurements: measurements,
      },
      appearanceProfile: selectedAppearenceTemplateList,
      projects: [`${props.projectName}`],
    };

    handleLoadingAction(true);
    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Saved Successfully", "affirmative");
      getAllListing();
      winRef?.current && winRef.current.resizeBy(-150, 0);
      setSaveModal({ ...saveModal, isOpen: false });
      handleViewerAction(true);
    }
    handleLoadingAction(false);
  };

  const handleBtnClick = () => {
    winRef?.current && winRef.current.resizeBy(150, 0);
    setSaveModal({ ...saveModal, isOpen: true });
  };

  const contentBody = (
    <>
      <div className="savepoints-toolbar">
        <div className="header-toolbar-box">
          <img
            className={
              saveModal.isOpen ? "save save-clicked show" : "save show"
            }
            src={Save}
            width={26}
            height={26}
            alt="save"
            onClick={() => handleBtnClick()}
          />
        </div>
      </div>
      <ul className="appearence-template-ul">
        {viewerList.map((item, i) => (
          <li
            className={
              optionClickHandler.listItem === item._id
                ? "appearance-template-li clicked"
                : "appearance-template-li"
            }
            key={i}
          >
            <div
              className="appearence-template-name"
              onClick={(e) => loadViewer(e, item)}
            >
              {item.name}
            </div>
            <div
              onClick={(event) =>
                handleRowClick(
                  event,
                  item._id,
                  item.bcf,
                  item.appearanceProfile
                )
              }
            >
              <img
                src={
                  optionClickHandler.defaultView === item._id
                    ? DefaultView_Variant
                    : DefaultView
                }
                className="viewer-default"
                alt="default view"
                width={25}
                height={25}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      {Constants.DESKTOP_APP ? (
        <LSideBar
          isToggle={isViewerOpen}
          handleToggle={() => {
            handleViewerAction(false);
          }}
          header={"Save Points"}
          isRight={true}
        >
          <style>{`${classes}`}</style>
          {contentBody}
        </LSideBar>
      ) : (
        <RSideBar
          isToggle={isViewerOpen}
          handleToggle={() => {
            handleViewerAction(false);
          }}
          header={"Save Points"}
        >
          {contentBody}
        </RSideBar>
      )}
      {
        <Box
          isToggle={boxData.isOpen}
          style={{
            width: boxData.width,
            height: boxData.height,
            position: "absolute",
            top: boxData.top,
            ...(Constants.DESKTOP_APP
              ? { left: boxData.left }
              : { right: boxData.right }),
            zIndex: boxData.zIndex,
            border: "1px solid #FF00BA",
            borderRadius: "5px",
          }}
          ref={boxRef}
        >
          <div className="box-property-container">
            <div
              className="box-property-option"
              onClick={() => handleDefaultView("default")}
            >
              Set As Default View
            </div>
            <div className="box-property-option" onClick={() => onUpdateView()}>
              Update View
            </div>
            <div className="box-property-option" onClick={() => onRenameView()}>
              Rename View
            </div>
            <div className="box-property-option" onClick={() => onRemoveView()}>
              Remove View
            </div>
            <div
              className="box-property-option"
              onClick={() => handleDefaultView("clear")}
            >
              Clear Default View
            </div>
          </div>
        </Box>
      }
      <Modal
        isToggle={renameModal.isOpen}
        handleToggle={() => setRenameModal({ ...renameModal, isOpen: false })}
        header={"Rename"}
        height={renameModal.height}
      >
        <div className="transparency-btn-container">
          <div className="transparency-btn-input">
            <input
              className="mycustom-button"
              onChange={(e) => {
                setRenameModal({
                  ...renameModal,
                  saveName: e.target.value,
                  inputError: null,
                });
              }}
              placeholder="Please input save name"
            />
            {renameModal.inputError && (
              <p className="input-error">{renameModal.inputError}</p>
            )}
          </div>

          <div className="transparency-btn-save">
            <IconButton
              buttonName={"Save"}
              buttonType={"Save"}
              onClick={() => onSaveRenameView()}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isToggle={saveModal.isOpen}
        handleToggle={() => {
          winRef?.current && winRef.current.resizeBy(-150, 0);
          setSaveModal({ ...saveModal, isOpen: false });
        }}
        header={"Save viewer"}
        height={saveModal.height}
      >
        <div className="transparency-btn-container">
          <div className="transparency-btn-input">
            <input
              className="mycustom-button"
              onChange={(e) => {
                setSaveModal({
                  ...saveModal,
                  saveName: e.target.value,
                  inputError: null,
                });
              }}
              placeholder="Please input save name"
            />
            {saveModal.inputError && (
              <p className="input-error">{saveModal.inputError}</p>
            )}
          </div>

          <div className="transparency-btn-save">
            <IconButton
              buttonName={"Save"}
              buttonType={"Save"}
              onClick={() => onSaveView()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isViewerOpen: state.ViewerReducer.isViewerOpen,
    properties: state.PropertyReducer.properties,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
    bcfViewPoint: state.xeokitReducer.bcfViewPoint,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
    distanceMeasurementPlugin: state.xeokitReducer.distanceMeasurementPlugin,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    selectedAppearenceTemplateList:
      state.AppearenceTemplateReducer.selectedAppearenceTemplateList,
    viewer: state.xeokitReducer.viewer,
  };
};

const mapDispatchToProps = {
  searchElementAction,
  handleLoadingAction,
  handleViewerAction,
  setDefaultViewAction,
  searchAppearenceTemplateListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerTab);
