import { useState, useEffect } from "react";
import { getProjects, getModelsByProject } from "../middleware/modelData";

// Clean up json filename by removing '_modelData' if it exists
const cleanJsonFilename = (filename) => {
  if (!filename) return filename;
  return filename.replace("_modelData", "");
};

export function useModelData() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [models, setModels] = useState({});
  const [selectedModel, setSelectedModel] = useState("");
  const [versions, setVersions] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState({
    v1: "",
    v2: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch projects
  useEffect(() => {
    async function fetchProjects() {
      try {
        console.log("Fetching projects...");
        setLoading(true);
        const data = await getProjects();
        console.log("Projects API response data:", data);

        // Check if data is an array directly
        const projectsArray = Array.isArray(data)
          ? data
          : Array.isArray(data.data)
          ? data.data
          : [];

        // Transform the data to match the expected format
        const transformedProjects = projectsArray.map((project) => ({
          id: project.id || project._id || project.projectId,
          name: project.name || project.projectName || "Unnamed Project",
        }));

        console.log("Transformed projects:", transformedProjects);
        setProjects(transformedProjects);

        // Set the first project as default if available
        if (transformedProjects.length > 0) {
          setSelectedProject(transformedProjects[0].id);
        }
      } catch (err) {
        const errorMessage = `Error fetching projects: ${err.message}`;
        console.error(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }

    fetchProjects();
  }, []);

  // Fetch models when project is selected
  useEffect(() => {
    async function fetchModels() {
      if (!selectedProject) {
        setModels({});
        return;
      }

      try {
        setLoading(true);
        console.log("Fetching models for project:", selectedProject);
        const data = await getModelsByProject(selectedProject);
        console.log("Models API response data:", data);

        // Transform the models data
        const transformedModels = {};
        if (typeof data === "object") {
          Object.entries(data).forEach(([modelName, versions]) => {
            console.log("Processing model:", modelName, "versions:", versions);

            if (Array.isArray(versions) && versions.length > 0) {
              // Store the original model name for display
              const displayName = modelName;
              // Use a clean name as the key (for internal use)
              const cleanModelName = modelName
                .replace(/\.xkt$/, "")
                .replace(/_{3D}/g, "") // Remove _{3D}
                .replace(/\s*\(\d+\)$/, ""); // Remove (number) at the end

              console.log("Processing model versions:", {
                displayName,
                cleanModelName,
                versions: versions.map((v) => ({
                  id: v._id || v.id,
                  originalName: v.originalName,
                  createdAt: v.createdAt || v.updatedAt || v.timestamp,
                })),
              });

              // Filter out versions without required data
              const validVersions = versions.filter((version) => {
                // Check if version has required fields
                const hasValidId = version._id || version.id;
                const hasValidDate =
                  version.createdAt || version.updatedAt || version.timestamp;
                const isValid = hasValidId && hasValidDate;
                console.log("Version validation:", {
                  hasValidId,
                  hasValidDate,
                  isValid,
                  id: version._id || version.id,
                  date:
                    version.createdAt || version.updatedAt || version.timestamp,
                  version: JSON.stringify(version, null, 2),
                });
                return isValid;
              });

              console.log("Valid versions count:", validVersions.length);

              if (validVersions.length > 0) {
                transformedModels[displayName] = validVersions.map(
                  (version) => {
                    // Log the raw version data
                    console.log("Raw version data:", {
                      id: version._id || version.id,
                      originalName: version.originalName,
                      fileName: version.fileName,
                      modelData: version.modelData,
                      fullVersion: version,
                    });

                    const transformed = {
                      ...version,
                      id: version._id || version.id,
                      originalName: version.originalName || modelName,
                      ProjectExportedTime:
                        version.createdAt ||
                        version.updatedAt ||
                        version.timestamp,
                      ExportedBy: version.user || "Unknown",
                      fileName:
                        version.fileName || version.originalName || modelName,
                      json:
                        version.json ||
                        version.modelData ||
                        `${version._id || version.id}.json`,
                      version:
                        version.versionNumber || version.version || "unknown", // Ensure version is set
                    };

                    // Log in the same format as the original implementation
                    console.log("Fetching XKT file:", transformed.fileName);
                    console.log("Fetching JSON file:", transformed.json);

                    console.log(
                      "Transformed version:",
                      JSON.stringify(transformed, null, 2)
                    );
                    return transformed;
                  }
                );
              }
            }
          });
        }

        console.log("Final transformed models:", transformedModels);
        setModels(transformedModels);
        const defaultMOdelName = "MSC22032_{3D}.xkt";
        //const defaultMOdelName = 'ProjectLinksTest_threeLinkInstances_{3D}.xkt';
        // Set default model if available
        const defaultModelName = Object.keys(transformedModels).find(
          (name) => name === defaultMOdelName
        );
        if (defaultModelName) {
          setSelectedModel(defaultModelName);
        }

        setVersions([]); // Reset versions
        setSelectedVersions({ v1: "", v2: "" }); // Reset selected versions
      } catch (err) {
        const errorMessage = `Error fetching models: ${err.message}`;
        console.error(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }

    fetchModels();
  }, [selectedProject]);

  // Update versions when model is selected
  useEffect(() => {
    if (!selectedModel || !models[selectedModel]) {
      setVersions([]);
      return;
    }

    try {
      // Filter and transform versions
      const modelVersions = models[selectedModel]
        .filter((version) => {
          // Strictly filter out invalid versions
          const hasValidId = Boolean(version._id || version.id);
          const hasValidDate = Boolean(
            version.uploadedAt ||
              version.createdAt ||
              version.updatedAt ||
              version.timestamp
          );
          const hasValidVersionNumber = Boolean(
            version.versionNumber || version.version
          );
          const isValid = hasValidId && hasValidDate && hasValidVersionNumber;

          // Log filtering for debugging
          console.log("Version validation:", {
            version: version.versionNumber || version.version,
            hasValidId,
            hasValidDate,
            hasValidVersionNumber,
            isValid,
          });

          return isValid;
        })
        .map((version) => {
          // Get the version number, ensuring it's not undefined
          const versionNumber = version.versionNumber || version.version;
          if (!versionNumber || versionNumber === "undefined") {
            console.log(
              "Skipping version with invalid version number:",
              version
            );
            return null;
          }

          // Get the date, ensuring it's valid
          const dateStr =
            version.uploadedAt ||
            version.createdAt ||
            version.updatedAt ||
            version.timestamp;
          const date = new Date(dateStr);
          if (isNaN(date.getTime())) {
            console.log("Skipping version with invalid date:", version);
            return null;
          }

          const formattedDate = date.toLocaleString();

          return {
            id: version._id || version.id,
            version: versionNumber,
            uploadedAt: dateStr,
            uploadedBy: version.uploadedBy || version.user || "Unknown",
            fileName: version.fileName || version.originalName || selectedModel,
            json:
              version.json ||
              version.modelData ||
              `${version._id || version.id}.json`,
          };
        })
        .filter(Boolean); // Remove any null entries from invalid versions

      // Sort versions by date (newest first)
      const sortedVersions = modelVersions.sort(
        (a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)
      );

      console.log("Processed versions:", sortedVersions);
      setVersions(sortedVersions);

      // Set default versions (first for v2, second for v1)
      if (sortedVersions.length >= 2) {
        const v2Version = sortedVersions[0]; // Newest version for v2
        const v1Version = sortedVersions[1]; // Second newest version for v1
        setSelectedVersions({
          v1: v1Version,
          v2: v2Version,
        });
      }
    } catch (err) {
      console.error("Error processing versions:", err);
      setError("Error processing versions");
    }
  }, [selectedModel, models]);

  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
  };

  const handleModelChange = (modelName) => {
    setSelectedModel(modelName);
  };

  const handleVersionChange = (versionId, slot) => {
    // If versionId is empty, clear the selected version for that slot
    if (!versionId) {
      setSelectedVersions((prev) => ({
        ...prev,
        [slot]: "",
      }));
      return;
    }

    // Find the full version object from the versions array
    const selectedVersion = versions.find((v) => v.id === versionId);
    if (!selectedVersion) {
      console.error("Version not found:", versionId);
      return;
    }

    // Update the selected version
    setSelectedVersions((prev) => ({
      ...prev,
      [slot]: selectedVersion,
    }));

    // Log the version change
    console.log(`Version changed for ${slot}:`, selectedVersion);
  };

  return {
    projects,
    models,
    versions,
    selectedProject,
    selectedModel,
    selectedVersions,
    loading,
    error,
    handleProjectChange,
    handleModelChange,
    handleVersionChange,
  };
}
