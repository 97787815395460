import React from "react";
import { connect } from "react-redux";

//import Actions
import { handleViewerAction } from "../../action/xeokitAction";
import { Constants } from "../../constant/constants";
import DesktopAppModalWrapper from "../../pages/desktopAppModalWrapper";
import ViewerTab from "./ViewerTab";

const ViewerTabWrapper = (props) => {
  const { isViewerOpen, handleViewerAction } = props;

  return isViewerOpen && Constants.DESKTOP_APP ? (
    <DesktopAppModalWrapper
      title={"view"}
      ChildrenCom={ViewerTab}
      state={isViewerOpen}
      onClose={() => handleViewerAction(false)}
      styles={{
        width: "280px",
      }}
    />
  ) : (
    <ViewerTab />
  );
};

const mapStateToProps = (state) => {
  return {
    isViewerOpen: state.ViewerReducer.isViewerOpen,
  };
};

const mapDispatchToProps = {
  handleViewerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerTabWrapper);
