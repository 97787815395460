// Modal.js

import React from "react";

import "./Modal.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import classes from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./Modal.css';
import { Constants } from "../../constant/constants";

const Modal = ({
  isToggle,
  handleToggle,
  header,
  width = "400px",
  height = "300px",
  children,
}) => {
  return (
    isToggle && (
      <>
        {Constants.DESKTOP_APP && <style>{`${classes}`}</style>}
        <div
          className="modal-container"
          style={Constants.DESKTOP_APP ? { backgroundColor: 'rgb(0,0,0,0)' } : {}}
        >
          <div className="modal" style={{ width: width, height: height }}>
            <div className="modal-header">
              <div className="modal-header-name">
                <span>{header}</span>
              </div>
              <div className="modal-header-cancel-btn" onClick={handleToggle}>
                X
              </div>
            </div>
            <div className="modal-line"></div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </>
    )
  );
};

export default Modal;
