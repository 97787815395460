// import React, { useState, useEffect, act } from "react";
// import { connect } from "react-redux";

// import { CameraMemento } from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

// import "./SelectEntity.css";

// import getModelPropertyAction, {
//   saveActionBufferAction,
//   setMultiSelectEntityAction,
//   setCommonPropertyDataAction,
//   addToClashDetectionListFromView,
//   addToClashDetectionListNormalClick,
//   setSelectedEntities,
//   setSelectedEntitiesFalse,
//   setSelectedEntityIdAction,
// } from "../../action/xeokitAction";
// import { Meta } from "react-router-dom";

// const SelectEntity = (props) => {
//   const { viewer } = props;
//   const [mouseInput, setMouseInput] = useState(null);
//   const [keyDown, setKeyDown] = useState(null);
//   const [keyUp, setKeyUp] = useState(null);
//   const [mouseDown, setMouseDown] = useState(null);
//   const [mouseMove, setMouseMove] = useState(null);
//   const [mouseUp, setMouseUp] = useState(null);

//   // const [actionBuffer, setActionBuffer] = useState([]);
//   // useEffect(() => {
//   //   console.log("actionBuffer", actionBuffer);
//   // }, [actionBuffer]);
//   useEffect(() => {
//     const actionBuffer = [];
//     if (props.setSelectEntityStatus && props.viewer) {
//       let lastEntity = null;
//       let isCtrlPressed = false;
//       let isZpressed = false;
//       let isShiftPressed = false;
//       let cumulative = [];
//       let commonProperty = null;
//       const onKeyDown = props.viewer.scene.input.on("keydown", (keyCode) => {
//         switch (keyCode) {
//           case 27:
//             if (lastEntity !== null) {
//               lastEntity.selected = false;
//             }
//             props.viewer.scene.setObjectsSelected(
//               props.viewer.scene.selectedObjectIds,
//               false
//             );
//             props.setSelectedEntitiesFalse(false);
//             props.getModelPropertyAction({
//               properties: {},
//               isCtrlPressed: false,
//             });
//             break;
//           case 16:
//             isShiftPressed = true;
//             break;
//           case 17:
//             isCtrlPressed = true;
//             break;
//           case 90:
//             isZpressed = true;
//             if (isCtrlPressed) {
//               // console.log("Ctrl + Z", props.actionBuffer);

//               // const temp = props.actionBuffer;
//               // console.log("realtemp", temp);
//               // const length = temp.length;
//               // if (length < 1) break;
//               // temp[length - 1].actionValue.restoreCamera(props.viewer.scene);
//               // temp.pop();
//               // console.log("temp", temp);
//               // props.actionBuffer.map((element, i) => {
//               //   if (element.actionName === "camera") {
//               //     element.actionValue.restoreCamera(props.viewer.scene);
//               //   }
//               // });
//               console.log("Ctrl + Z", actionBuffer);

//               const length = actionBuffer.length;
//               if (length < 1) break;
//               props.bcfViewPoint.setViewpoint(
//                 actionBuffer[actionBuffer.length - 1]
//               );
//               // actionBuffer.filter((item, index) => item.length - 1 === index)
//               actionBuffer.pop();
//             }
//             break;
//           default:
//             console.log("Some other key is down", keyCode);
//         }
//       });
//       const onKeyUp = props.viewer.scene.input.on("keyup", (keyCode) => {
//         switch (keyCode) {
//           case 27:
//             break;
//           case 16:
//             isShiftPressed = false;
//             break;
//           case 17:
//             isCtrlPressed = false;
//             break;
//           case 90:
//             isZpressed = false;
//             break;
//           default:
//             console.log("Some other key is up", keyCode);
//         }
//       });
//       const mouseInput = props.viewer.scene.input.on("mousedown", (coords) => {
//         const event = props.viewer.scene.input;

//         if (!event.mouseDownLeft) return;
//         var pickResult = props.viewer.scene.pick({
//           canvasPos: coords,
//           pickSurface: true, // <<------ This causes picking to find the intersection point on the entity
//         });
//         if (pickResult && pickResult.worldNormal) {
//           // Disallow SectionPlanes on point clouds, because points don't have normals

//           console.log("pickResult", pickResult);

//           // pickResult.entity.selected = true;
//           if (pickResult.entity.owner)
//             props.setSelectedEntityIdAction(pickResult.entity.owner.id);
//           if (isCtrlPressed) {
//             const nodeId = pickResult.entity.id;
//             props.addToClashDetectionListFromView(pickResult.entity.id);
//             const MetaData = props.metaData;
//             const properties = {};
//             const idMatch = nodeId.match(/\[([\d-]+)\]/);
//             console.log("idMatach", idMatch);
//             const propertyId = idMatch ? idMatch[1] : null;
//             props.setSelectedEntities({
//               keyPath: propertyId,
//               isChecked: true,
//             });
//             if (propertyId && MetaData.Properties[propertyId]) {
//               const propertyData = MetaData.Properties[propertyId];
//               Object.entries(propertyData).forEach(([key, value]) => {
//                 if (MetaData.Legend[key]) {
//                   const legendKey = MetaData.Legend[key].Name;
//                   properties[legendKey] = value;
//                 }
//               });
//             }
//             const resultObject = {};
//             console.log("props.property", props.properties);
//             console.log("property", properties);
//             // Object.entries(properties).forEach(([key, value]) => {
//             //   console.log("???????????????", key, "<<<<<<", value);
//             // });

//             // if (Object.keys(props.properties).length !== 0) {
//             //   const commonProperties = Object.entries(props.properties).filter(
//             //     ([key, value]) => properties[key] === value
//             //   );
//             //   console.log("????????", commonProperties);
//             //   props.getModelPropertyAction(
//             //     Object.fromEntries(commonProperties)
//             //   );
//             // } else {
//             //   console.log("}}}}}}}}}}}}}}}}");
//             //   props.getModelPropertyAction(properties);
//             // }
//             props.getModelPropertyAction({
//               properties: properties,
//               isCtrlPressed: true,
//             });
//             pickResult.entity.selected = true;
//             cumulative.push(pickResult.entity);
//             // if (commonProperty === null) commonProperty = { ...properties };
//             // else {
//             //   for (const key in commonProperty) {
//             //     if (commonProperty[key] === properties[key]) {
//             //       resultObject[key] = commonProperty[key];
//             //     }
//             //   }
//             //   commonProperty = { ...resultObject };
//             //   console.log("result", resultObject);
//             // }
//             // pickResult.entity.selected = true;
//             // cumulative.push(pickResult.entity);
//             // props.getModelPropertyAction({ ...resultObject });
//             // cumulative.map((element, i) => {
//             //   const objectId1 = pickResult.entity.id;
//             //   const objectId2 = element.id;
//             //   const metaObject1 = props.viewer.metaScene.metaObjects[objectId1];
//             //   const metaObject2 = props.viewer.metaScene.metaObjects[objectId2];
//             //   if (metaObject1.type === metaObject2.type) {
//             //     let flag = false;
//             //     commonProperty.map((element, i) => {
//             //       if (element.type) flag = true;
//             //     });
//             //     if (!flag) commonProperty.push({ type: metaObject1.type });
//             //   }
//             //   if (metaObject1.name === metaObject2.name) {
//             //     let flag = false;
//             //     commonProperty.map((element, i) => {
//             //       if (element.name) flag = true;
//             //     });
//             //     if (!flag) commonProperty.push({ name: metaObject1.name });
//             //   }
//             // });
//             // props.setMultiSelectEntityAction(structuredClone(commonProperty));
//           } else if (isShiftPressed) {
//             pickResult.entity.selected = false;
//             props.addToClashDetectionListFromView(pickResult.entity.id);
//           } else if (pickResult.entity && !isCtrlPressed) {
//             if (!pickResult.entity.isObject) {
//               return;
//             }

//             // const objectId = pickResult.entity.id;
//             const nodeId = pickResult.entity.id;
//             props.addToClashDetectionListNormalClick(pickResult.entity.id);
//             // const metaObject = viewer.metaScene.metaObjects[objectId];
//             // console.log(viewer);

//             // Object.entries(viewer.metaScene.metaObjectsByType).map((_, i) =>
//             //   modelTypes.push(_[0])
//             // );
//             // Object.entries(viewer.metaScene.metaObjects).map((_, i) => {
//             //   modelIds.push(_[0]);
//             //   modelNames.push(_[1].name);
//             // });
//             // const property = {
//             //   context: null,
//             //   name: metaObject.name,
//             //   type: metaObject.type,
//             //   uuid: metaObject.originalSystemId,
//             //   id: metaObject.id,
//             //   modelIds: modelIds,
//             //   modelNames: modelNames,
//             //   modelTypes: modelTypes,
//             // };
//             // props.getModelPropertyAction(property);

//             props.setSelectedEntitiesFalse(false);
//             const MetaData = props.metaData;
//             const properties = {};
//             // console.log("metaData", MetaData);
//             // console.log("nodeId", nodeId);
//             const idMatch = nodeId.match(/\[([\d-]+)\]/);
//             // const idMatch = nodeId.match(/\[([\d-]+)\]/);
//             // console.log("idMatch", idMatch);

//             const propertyId = idMatch ? idMatch[1] : null;
//             props.setSelectedEntities({
//               keyPath: propertyId,
//               isChecked: true,
//             });
//             if (propertyId && MetaData.Properties[propertyId]) {
//               const propertyData = MetaData.Properties[propertyId];

//               Object.entries(propertyData).forEach(([key, value]) => {
//                 if (MetaData.Legend[key]) {
//                   const legendKey = MetaData.Legend[key].Name;
//                   properties[legendKey] = value;
//                 }
//               });
//             }
//             // console.log("suprise", properties);
//             props.getModelPropertyAction({
//               properties: properties,
//               isCtrlPressed: false,
//             });
//             props.setCommonPropertyDataAction({ ...properties });
//           }

//           if (!isCtrlPressed && !isShiftPressed) {
//             props.actionBuffer.map((element, i) => {
//               if (element.actionName === "camera") {
//                 element.actionValue.saveCamera(props.viewer.scene);
//               }
//             });
//             props.viewer.scene.setObjectsSelected(
//               props.viewer.scene.selectedObjectIds,
//               false
//             );
//             if (pickResult.entity.selected) pickResult.entity.selected = false;
//             else {
//               if (lastEntity !== null) {
//                 console.log("lastEntity");
//                 lastEntity.selected = false;
//                 pickResult.entity.selected = true;

//                 lastEntity = pickResult.entity;
//               } else {
//                 console.log("CurrentEntity");
//                 pickResult.entity.selected = true;
//                 lastEntity = pickResult.entity;
//               }
//             }
//           }
//         }
//       });
//       let isMouseDown = false;
//       let isMouseMove = false;

//       // Event listener for mousedown
//       const onMouseDown = props.viewer.scene.input.on(
//         "mousedown",
//         (canvasCoords) => {
//           console
//             .log
//             // "Mouse down at: x=" + canvasCoords[0] + ", y=" + canvasCoords[1]
//             ();
//           isMouseDown = true;
//         }
//       );

//       // Event listener for mousemove
//       const onMouseMove = props.viewer.scene.input.on(
//         "mousemove",
//         (canvasCoords) => {
//           isMouseMove = true;
//         }
//       );

//       const onMouseUp = props.viewer.scene.input.on(
//         "mouseup",
//         (canvasCoords) => {
//           if (isMouseDown && isMouseMove) {
//             isMouseDown = false;
//             isMouseMove = false;

//             const viewPoints = props.bcfViewPoint.getViewpoint({
//               snapshot: false,
//             });
//             if (actionBuffer.length > 49) actionBuffer.shift();
//             actionBuffer.push(viewPoints);
//             console.log("actionBUffer", actionBuffer);
//             // const temp = props.actionBuffer;
//             // // console.log("wired temp", temp);
//             // temp.push({
//             //   actionName: "camera",
//             //   actionValue: cameraMemento,
//             // });
//             // console.log(props.actionBuffer);
//             // console.log(temp);
//             // const cameraViewer = structuredClone(cameraMemento);
//             // console.log("cameraMemento", cameraMemento);
//             // props.saveActionBufferAction("hello");
//           }
//         }
//       );

//       setMouseInput(mouseInput);
//       setKeyDown(onKeyDown);
//       setKeyUp(onKeyUp);
//       // setMouseDown(onMouseDown);
//       // setMouseMove(onMouseMove);
//       // setMouseUp(onMouseUp);
//     } else if (props.setSelectEntityStatus === false) {
//       props.viewer.scene.input.off(mouseInput);
//       props.viewer.scene.input.off(keyDown);
//       props.viewer.scene.input.off(keyUp);
//       // props.viewer.scene.input.off(mouseDown);
//       // props.viewer.scene.input.off(mouseMove);
//       // props.viewer.scene.input.off(mouseUp);
//     }
//   }, [props.setSelectEntityStatus]);

//   // const [mouseClickEvent, setMouseClickEvent] = useState(null);
//   // const [keyDownEvent, setKeyDownEvent] = useState(null);
//   // const [keyUpEvent, setKeyUpEvent] = useState(null);
//   // const [prevElement, setPrevElement] = useState({ id: null });

//   // useEffect(() => {
//   //   if (props.setSelectEntityStatus && viewer) {
//   //     let isCtrlPressed;
//   //     const onKeyDown = props.viewer.scene.input.on("keydown", (keyCode) => {
//   //       switch (keyCode) {
//   //         case 27:
//   //           viewer.scene.setObjectsSelected(
//   //             viewer.scene.selectedObjectIds,
//   //             false
//   //           );
//   //           break;
//   //         case 16:
//   //           // isShiftPressed = true;
//   //           break;
//   //         case 17:
//   //           isCtrlPressed = true;
//   //           break;
//   //         default:
//   //           console.log("Some other key is down", keyCode);
//   //           break;
//   //       }
//   //     });
//   //     const onKeyUp = props.viewer.scene.input.on("keyup", (keyCode) => {
//   //       switch (keyCode) {
//   //         case 16:
//   //           // isShiftPressed = true;
//   //           break;
//   //         case 17:
//   //           isCtrlPressed = false;
//   //           break;
//   //         default:
//   //           console.log("Some other key is down", keyCode);
//   //           break;
//   //       }
//   //     });
//   //     const mouseInput = viewer.scene.input.on("mousedown", (coords) => {
//   //       const event = props.viewer.scene.input;

//   //       if (!event.mouseDownLeft) return;
//   //       var pickResult = viewer.scene.pick({
//   //         canvasPos: coords,
//   //         pickSurface: true, // <<------ This causes picking to find the intersection point on the entity
//   //       });

//   //       if (pickResult && pickResult.worldNormal) {
//   //         // Disallow SectionPlanes on point clouds, because points don't have normals

//   //         if (pickResult.entity) {
//   //           if (!pickResult.entity.isObject) {
//   //             return;
//   //           }
//   //         }
//   //         pickResult.entity.selected = true;
//   //         if (prevElement.id && !isCtrlPressed) {
//   //           console.log(viewer.metaScene.metaObjects[prevElement.id]);
//   //           viewer.scene.objects[prevElement.id].selected = false;
//   //         }
//   //         prevElement.id = pickResult.entity.id;
//   //       }
//   //     });
//   //     setMouseClickEvent(mouseInput);
//   //     setKeyDownEvent(onKeyDown);
//   //     setKeyUpEvent(onKeyUp);
//   //   } else if (props.setSelectEntityStatus === false) {
//   //     props.viewer.scene.input.off(mouseClickEvent);
//   //     props.viewer.scene.input.off(keyDownEvent);
//   //     props.viewer.scene.input.off(keyUpEvent);
//   //   }
//   // }, [props.setSelectEntityStatus]);
// };

// const mapStateToProps = (state) => {
//   return {
//     viewer: state.xeokitReducer.viewer,
//     bcfViewPoint: state.xeokitReducer.bcfViewPoint,
//     setSelectEntityStatus: state.xeokitReducer.setSelectEntityStatus,
//     actionBuffer: state.ActionBufferReducer.actionBuffer,
//     metaData: state.ProjectReducer.metaData,
//     commonProperty: state.PropertyReducer.commonProperty,
//     properties: state.PropertyReducer.properties,
//   };
// };

// const mapDispatchToProps = {
//   getModelPropertyAction,
//   saveActionBufferAction,
//   setMultiSelectEntityAction,
//   setCommonPropertyDataAction,
//   addToClashDetectionListFromView,
//   addToClashDetectionListNormalClick,
//   setSelectedEntities,
//   setSelectedEntitiesFalse,
//   setSelectedEntityIdAction,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SelectEntity);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./SelectEntity.css";

import getModelPropertyAction, {
  saveActionBufferAction,
  setMultiSelectEntityAction,
  setCommonPropertyDataAction,
  addToClashDetectionListFromView,
  addToClashDetectionListNormalClick,
  setSelectedEntities,
  setSelectedEntitiesFalse,
  setSelectedEntityIdAction,
} from "../../action/xeokitAction";

const SelectEntity = (props) => {
  const {
    viewer,
    setSelectEntityStatus,
    metaData,
    bcfViewPoint,
    actionBuffer,
    setSelectedEntitiesFalse,
    getModelPropertyAction,
    addToClashDetectionListFromView,
    addToClashDetectionListNormalClick,
    setSelectedEntities,
    setCommonPropertyDataAction,
    setSelectedEntityIdAction,
  } = props;

  useEffect(() => {
    // Only set up listeners if viewer exists and selection is enabled
    if (!setSelectEntityStatus || !viewer) return;

    // State variables
    let lastEntity = null;
    let isCtrlPressed = false;
    let isShiftPressed = false;
    let isZPressed = false;
    let isMouseDown = false;
    let isMouseMove = false;
    const localActionBuffer = [];

    // Extract property data from selected entity
    const extractProperties = (nodeId) => {
      const properties = {};
      const idMatch = nodeId.match(/\[([\d-]+)\]/);
      const propertyId = idMatch ? idMatch[1] : null;

      if (propertyId && metaData?.Properties?.[propertyId]) {
        const propertyData = metaData.Properties[propertyId];
        Object.entries(propertyData).forEach(([key, value]) => {
          if (metaData.Legend[key]) {
            const legendKey = metaData.Legend[key].Name;
            properties[legendKey] = value;
          }
        });
      }

      return { properties, propertyId };
    };

    // Handle entity selection
    const handleEntitySelection = (
      pickResult,
      isCtrlKeyPressed,
      isShiftKeyPressed
    ) => {
      if (!pickResult || !pickResult.entity || !pickResult.worldNormal) return;

      const entity = pickResult.entity;

      // Update selected entity ID if owner exists
      if (entity.owner) {
        setSelectedEntityIdAction(entity.owner.id);
      }

      // Handle click with control key pressed (multi-select)
      if (isCtrlKeyPressed) {
        const nodeId = entity.id;
        addToClashDetectionListFromView(nodeId);

        const { properties, propertyId } = extractProperties(nodeId);

        setSelectedEntities({
          keyPath: propertyId,
          isChecked: true,
        });

        getModelPropertyAction({
          properties,
          isCtrlPressed: true,
        });

        entity.selected = true;
      }
      // Handle click with shift key pressed (deselect)
      else if (isShiftKeyPressed) {
        entity.selected = false;
        addToClashDetectionListFromView(entity.id);
      }
      // Handle normal click (single select)
      else {
        if (!entity.isObject) return;

        const nodeId = entity.id;
        addToClashDetectionListNormalClick(nodeId);
        setSelectedEntitiesFalse(false);

        const { properties, propertyId } = extractProperties(nodeId);

        setSelectedEntities({
          keyPath: propertyId,
          isChecked: true,
        });

        getModelPropertyAction({
          properties,
          isCtrlPressed: false,
        });

        setCommonPropertyDataAction({ ...properties });

        // Save camera state to action buffer
        actionBuffer.forEach((element) => {
          if (element.actionName === "camera") {
            element.actionValue.saveCamera(viewer.scene);
          }
        });

        // Clear previous selections
        viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);

        // Toggle selection state
        if (entity.selected) {
          entity.selected = false;
        } else {
          if (lastEntity !== null) {
            lastEntity.selected = false;
          }
          entity.selected = true;
          lastEntity = entity;
        }
      }
    };

    // Key down event handler
    const onKeyDown = viewer.scene.input.on("keydown", (keyCode) => {
      switch (keyCode) {
        case 27: // Escape key
          if (lastEntity !== null) {
            lastEntity.selected = false;
          }
          viewer.scene.setObjectsSelected(
            viewer.scene.selectedObjectIds,
            false
          );
          setSelectedEntitiesFalse(false);
          getModelPropertyAction({
            properties: {},
            isCtrlPressed: false,
          });
          break;
        case 16: // Shift key
          isShiftPressed = true;
          break;
        case 17: // Control key
          isCtrlPressed = true;
          break;
        case 90: // Z key
          isZPressed = true;
          if (isCtrlPressed && localActionBuffer.length > 0) {
            // Undo functionality
            bcfViewPoint.setViewpoint(
              localActionBuffer[localActionBuffer.length - 1]
            );
            localActionBuffer.pop();
          }
          break;
        default:
          break;
      }
    });

    // Key up event handler
    const onKeyUp = viewer.scene.input.on("keyup", (keyCode) => {
      switch (keyCode) {
        case 16: // Shift key
          isShiftPressed = false;
          break;
        case 17: // Control key
          isCtrlPressed = false;
          break;
        case 90: // Z key
          isZPressed = false;
          break;
        default:
          break;
      }
    });

    // Mouse down event handler
    const onMouseDown = viewer.scene.input.on("mousedown", (coords) => {
      const event = viewer.scene.input;

      // Handle left mouse button clicks for entity selection
      if (event.mouseDownLeft) {
        const pickResult = viewer.scene.pick({
          canvasPos: coords,
          pickSurface: true, // Find intersection point on the entity
        });

        handleEntitySelection(pickResult, isCtrlPressed, isShiftPressed);
      }

      isMouseDown = true;
    });

    // Mouse move event handler
    const onMouseMove = viewer.scene.input.on("mousemove", () => {
      isMouseMove = true;
    });

    // Mouse up event handler
    const onMouseUp = viewer.scene.input.on("mouseup", () => {
      if (isMouseDown && isMouseMove) {
        isMouseDown = false;
        isMouseMove = false;

        // Save the current viewpoint to the action buffer
        const viewPoint = bcfViewPoint.getViewpoint({
          snapshot: false,
        });

        // Maintain a fixed buffer size (50)
        if (localActionBuffer.length > 49) {
          localActionBuffer.shift();
        }

        localActionBuffer.push(viewPoint);
      }
    });

    // Cleanup function to remove event listeners
    return () => {
      if (viewer && viewer.scene && viewer.scene.input) {
        viewer.scene.input.off(onKeyDown);
        viewer.scene.input.off(onKeyUp);
        viewer.scene.input.off(onMouseDown);
        viewer.scene.input.off(onMouseMove);
        viewer.scene.input.off(onMouseUp);
      }
    };
  }, [
    viewer,
    setSelectEntityStatus,
    metaData,
    bcfViewPoint,
    actionBuffer,
    setSelectedEntitiesFalse,
    getModelPropertyAction,
    addToClashDetectionListFromView,
    addToClashDetectionListNormalClick,
    setSelectedEntities,
    setCommonPropertyDataAction,
    setSelectedEntityIdAction,
  ]);

  // The component doesn't render anything visible
  return null;
};

const mapStateToProps = (state) => ({
  viewer: state.xeokitReducer.viewer,
  bcfViewPoint: state.xeokitReducer.bcfViewPoint,
  setSelectEntityStatus: state.xeokitReducer.setSelectEntityStatus,
  actionBuffer: state.ActionBufferReducer.actionBuffer,
  metaData: state.ProjectReducer.metaData,
  commonProperty: state.PropertyReducer.commonProperty,
  properties: state.PropertyReducer.properties,
});

const mapDispatchToProps = {
  getModelPropertyAction,
  saveActionBufferAction,
  setMultiSelectEntityAction,
  setCommonPropertyDataAction,
  addToClashDetectionListFromView,
  addToClashDetectionListNormalClick,
  setSelectedEntities,
  setSelectedEntitiesFalse,
  setSelectedEntityIdAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectEntity);
