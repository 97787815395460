// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .button {
  background: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.color {
  border-radius: 20%;
  box-shadow: 0 0 10px black inset;
  outline: 1px solid rgb(255, 255, 255);
}

.button:hover {
  background: cyan;
}

.button[aria-pressed="true"] {
  background: rgba(0, 102, 255, 0.5);
}

.image-editor-menu {
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.imageEditor-container {
  width: 80%;
  height: 80%;
  color: #BFBFBF;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-editor-canvas {
  border: 1px solid #FF00BA;
  cursor: crosshair;
  background-color: lightblue;
  width: 80vw;
  height: 80vh;
}

.image-editor-cancel-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 21;
  font-weight: bold;
  font-size: 30px;
  color: #FF00BA
} */

.button {
  background-color: #303030;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  color: #bfbfbf;
  /* border: 1px solid gray */
}

.color {
  border-radius: 20%;
  box-shadow: 0 0 10px black inset;
  outline: 1px solid rgb(255, 255, 255);
}

.button:hover {
  background-color: #303030;
  outline: 1px solid #ff00ba;
  /* border: 1px solid #FF00BA; */
}

.button[aria-pressed="true"] {
  background: #ff00ba;
}

.image-editor-menu-container {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 10;
  background: #232323;
  border-radius: 10px;
  padding: 20px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.image-editor-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.imageEditor-container {
  width: 100%;
  height: 100vh;
  color: #bfbfbf;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-editor-canvas {
  border: 1px solid #ff00ba;
  cursor: crosshair;
  background-color: lightblue;
  width: 100%;
  height: 100%;
}

.image-editor-cancel-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 21;
  font-weight: bold;
  font-size: 30px;
  color: #ff00ba;
  cursor: pointer;
}

/* Text annotation styles */
.annotation-textbox {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  min-width: 100px;
  min-height: 30px;
  position: absolute;
  cursor: move;
  user-select: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.annotation-textbox.selected {
  border: 2px solid #1976d2;
  background: #e3f2fd;
}

.control-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #1976d2;
  background: white;
  position: absolute;
  cursor: nw-resize;
  pointer-events: all;
}

.control-point.connected {
  background: #1976d2;
}

.arrow-control-point {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid blue;
  background: white;
  position: absolute;
  cursor: move;
  pointer-events: all;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
